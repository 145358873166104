<div class="modal-backdrop fade in modal-fs"
    id='modal-fs'
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="rewardData">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-header height-50">
            <h1>{{'resources.scores.rewards.show.fields.title' | translate}}: <strong>{{rewardData.name}}</strong></h1>
            <div class="pdd-vertical-20-but-bottom">
              <button class="modal-close mrg-right-25" (click)="closeModal()">
                <i class="ti-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tab tab-info" *ngIf="selectedTab">
            <!-- Tabs menu -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a [routerLink]="['details']"
                  [ngClass]="{'active': selectedTab === 'details'}"
                  role="tab"
                  data-toggle="tab"
                  [translate]="'resources.scores.rewards.show.tabs.details'">
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['localized-content']"
                  [ngClass]="{'active': selectedTab === 'localized_content'}"
                  role="tab"
                  data-toggle="tab"
                  [translate]="'resources.scores.rewards.show.tabs.localized_content'">
                </a>
              </li>
              <li class="nav-item" *ngIf="showCodesTab">
                <a [routerLink]="['codes']"
                  [ngClass]="{'active': selectedTab === 'codes'}"
                  role="tab"
                  data-toggle="tab"
                  [translate]="'resources.scores.rewards.show.tabs.codes'">
                </a>
              </li>
              <li class="nav-item" *ngIf="showStockTab">
                <a [routerLink]="['stock']"
                  [ngClass]="{'active': selectedTab === 'stock'}"
                  role="tab"
                  data-toggle="tab"
                  [translate]="'resources.scores.rewards.show.tabs.stock'">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-body height-100 background-gray">
        <!-- Tabs -->
        <div class="tab-info">
          <div class="tab-content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <button class="btn btn-info no-mrg-right"
            [routerLink]="['', { outlets: { modal: ['update', 'rewards', rewardData.id] } }]">
            {{'components.show.buttons.edit' | translate}}</button
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL COUNTRIES -->
<div class="modal fade" id="modal-countries">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="padding-15">
          <div class="row">
            <div class="col-md-12">
              <h5 [translate]="'resources.scores.loyalty_scores.fields.countries'"></h5>

              <div *ngIf="rewardData?.countries?.length">
                <div class="card">
                  <div class="row">
                    <div class="column col-md-6">
                      <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                        <li *ngFor="let item of rewardData.countries.slice(0, getFirstHalfLength())">
                          {{item}}
                        </li>
                      </ul>
                    </div>
                    <div class="column col-md-6">
                      <ul class="list tick bullet-success ul-style mrg-top-15 mrg-left-15">
                        <li *ngFor="let item of rewardData.countries.slice(getFirstHalfLength())">
                          {{item}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
