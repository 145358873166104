import { CheckboxQuestion } from '../../../../shared/models/forms/question-checkbox';
import { FeaturesService } from '../../products/features.service';
import { getCurrencySymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ImageQuestion } from '../../../../shared/models/forms/question-image';
import { Injectable } from '@angular/core';
import { LocationsService } from '../../locations/locations.service';
import { LocationsTaxonomyTermsService } from '../../locations/location-taxonomy-terms.service';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { ProductsService } from '../../products/products.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { RadioQuestion } from '../../../../shared/models/forms/question-radio';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PromotionsService extends ResourcesService {

  inputs: QuestionBase<any>[];
  currencySymbol: any;

  constructor(
    http: HttpClient,
    private features: FeaturesService,
    private locationsService: LocationsService,
    private locationTaxonomyTermsService: LocationsTaxonomyTermsService,
    private products: ProductsService,
    private profileService: ProfileService,
    private translate: TranslateService
  ) {
    super(http);
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
  }

   getAll() {
    const requestOptions = {
      apiEndPoint: '/promotions',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

   getPromotionsById(id: number) {
    return this.getData({ apiEndPoint: `promotions/${id}` });
  }

   fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'promotions',
      numberPerPage: 10,
      pageNumber: 1,
      filtering: {},
      sorting: {},
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

   getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  sendData(promotionData) {
    return this.postResource(promotionData, 'promotions');
  }

   updateData(promotionData: any, _id: number) {
    return this.patchResource(promotionData, `promotions/${_id}`);
  }

   getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
      }),
      new CheckboxQuestion({
        key: 'cumulative',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'feature_location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationTaxonomyTermsService,
      }),
      new RadioQuestion({
        key: 'discount_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.discountOptions(),
        required: true,
        value: formValues?.discount_type ? formValues.discount_type : 'cash'
      }),
      new TextboxQuestion({
        key: 'discount',
        type: 'number',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'min_money',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'min_units',
        type: 'number',
        step: '1',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'max_money',
        type: 'number',
        step: '0.01',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'max_units',
        type: 'number',
        step: '1',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new RadioQuestion({
        key: 'affectation',
        formKey: 'affectation',
        cssClasses: 'radio-inline radio-info',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.affectationOptions(),
        required: true,
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'feature_pks',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.features,
      }),
      new MultiSelectQuestion({
        key: 'product_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.products,
      }),
    ];

    Object.keys(formValues).forEach(
      key => {
        if (key === 'image') {
          inputsArray.find( input => input.key === 'image' ).value = formValues[key];
        } else if (key === 'location_ids') {
          inputsArray.find( input => input.key === 'location_ids' ).selectedIds = formValues[key];
        } else if (key === 'feature_location_ids') {
          inputsArray.find( input => input.key === 'feature_location_ids' ).selectedIds = formValues[key];
        } else if (key === 'product_ids') {
          inputsArray.find( input => input.key === 'product_ids' ).selectedIds = formValues[key];
        } else if (key === 'feature_pks') {
          inputsArray.find( input => input.key === 'feature_pks' ).selectedIds = JSON.parse(formValues[key]);
        }
      }
    );

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).forEach(
      key => {
        const tmpInput = this.inputs.find( input => input.key === key);
        if (tmpInput?.getValue ) {
          const value = tmpInput.getValue(formValues[key]);
          if (value?.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      }
    );
  }

  private discountOptions() {
    return [
      { id: 'cash', name: this.translate.instant('resources.coupons.fields.cash', {currencySymbol: this.currencySymbol})},
      { id: 'percent', name: this.translate.instant('resources.coupons.fields.percent')},
    ];
  }

  private affectationOptions() {
    return [
      { id: 'activity', name: this.translate.instant('resources.coupons.fields.activity')},
      { id: 'attribute', name: this.translate.instant('resources.coupons.fields.attribute')},
      { id: 'product', name: this.translate.instant('resources.coupons.fields.product')}
    ];
  }

}
