import { TextboxQuestion } from './forms/question-textbox';
import { MultiSelectQuestion } from './forms/question-multiselect';
import { CheckboxQuestion } from './forms/question-checkbox';
import { isNullOrUndefined } from '../utils/common.utils';
import { ValidatorFn, Validators } from '@angular/forms';
import { FloatQuestion } from './forms/question-float';
import { Option } from './common/option';
import { InputType } from '../enums/input-type.enums';

export interface ProfileForm {
  key: string;
  placeholder: string;
  type: string;
}

export interface ProfileSchema {
  type: string;
  properties: unknown;
  required: string[];
}

export class ProfileSchemaForm {
  form: ProfileForm[];
  schema: ProfileSchema;
  customFormInputsCfg: CustomInputCfg[];

  constructor(jsonCfg) {
    this.form = jsonCfg.form;
    this.schema = jsonCfg.schema;
    this.customFormInputsCfg = this.parseCustomInputs(jsonCfg);
  }

  parseCustomInputs(jsonCfg): CustomInputCfg[] {
    const rawProfileCfg = this.form.find(el => el.key === 'profiles')['items'];
    const customKeys = Object.keys( jsonCfg.schema.properties.profiles.items.properties)
    .filter( key => key.indexOf('custom') >= 0 || key.indexOf('delete_email') >= 0 || key.indexOf('code') >= 0 );
    return customKeys.map(key => {
      const inputCfg = rawProfileCfg.find(el => el.key.indexOf(key) >= 0);
      const inputSchemaCfg = jsonCfg.schema.properties.profiles.items.properties[key];
      return new CustomInputCfg(key, inputCfg, inputSchemaCfg);
    });
  }

  chkIfRequired(field: string, rootObjectName?: string): boolean {
    if (isNullOrUndefined(rootObjectName)) {
      return this.schema.required.includes(field);
    } else {
      return this.schema.properties[rootObjectName].items.required.includes(field);
    }
  }

  chkForCustomValidators(field: string, rootObjectName?: string): ValidatorFn[] {
    let minLength: number;
    let maxLength: number;
    const customValidators = [];
    if (isNullOrUndefined(rootObjectName)) {
      const fieldObj = this.schema.properties[field];
      if (fieldObj) {
        minLength = this.schema.properties[field]['minLength'];
        maxLength = this.schema.properties[field]['maxLength'];
      }
    } else {
      const fieldObj = this.schema.properties[rootObjectName].items.properties[field];
      if (fieldObj) {
        minLength = this.schema.properties[rootObjectName].items.properties[field]['minLength'];
        maxLength = this.schema.properties[rootObjectName].items.properties[field]['maxLength'];
      }
    }
    if (minLength) { customValidators.push(Validators.minLength(minLength)); }
    if (maxLength) { customValidators.push(Validators.maxLength(maxLength)); }
    return customValidators;
  }
}

export class CustomInputCfg {
  key: string;
  rawKey: string;
  placeholder: string;
  type: string;
  questionClassType: MultiSelectQuestion | CheckboxQuestion | FloatQuestion | TextboxQuestion;
  title: string;
  options: Option[];
  default: unknown;
  value: unknown;
  copyValueTo: string[];
  getValue: Function;
  settings: { singleSelection: boolean, enableSearchFilter: boolean, showCheckbox: boolean } | null;

  constructor(key, jsonConfig, schemaConfig) {
    this.key = key;
    this.type = jsonConfig && jsonConfig['type'] ? jsonConfig['type'] : schemaConfig['type'];

    if (jsonConfig) {
      this.rawKey = jsonConfig['key'];
      this.placeholder = jsonConfig['placeholder'];
      this.copyValueTo = jsonConfig['copyValueTo'];
      this.options = this.parseTitleMap(jsonConfig['titleMap']);

      if (!this.placeholder) {
        this.placeholder = schemaConfig['title'];
      }
    }

    this.title = schemaConfig['title'];
    this.default = schemaConfig['default'];
    this.settings = null;
    this.value = this.default || null;
    this.setPropertiesByType();
  }
  
  private setPropertiesByType(): void {
    switch (this.type) {
      case InputType.Select:
        this.questionClassType = new MultiSelectQuestion();
        this.settings = {
          singleSelection: true,
          enableSearchFilter: false,
          showCheckbox: false
        };
        if (this.default) {
          const selectedOption = this.options.find(
            opt => opt.id === this.default
          );
          this.value = selectedOption ? [selectedOption] : [];
        } else {
          this.value = [];
        }
        this.getValue = (value) => {
          if (value?.length) {
            const selectedOption = this.options.find(opt => opt.id === value);
            return selectedOption ? [selectedOption] : [];
          } else {
            return [];
          }
        };
        break;

      case InputType.Boolean:
        this.questionClassType = new CheckboxQuestion();
        this.getValue = (value) => value ? value : null;
        break;

      case InputType.Number:
        this.questionClassType = new FloatQuestion();
        this.getValue = (value) => value ?? null;
        break;

      default:
        this.questionClassType = new TextboxQuestion();
        this.getValue = (value) => value?.length ? value : null;
        break;
    }
  }

  private parseTitleMap(titleMap): Option[] | null {
    return titleMap?.length ? titleMap.map(el => ({ id: el.value, name: el.name })) : null;
  }
}
