import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { MultiSelectQuestion } from "../../../../../shared/models/forms/question-multiselect";
import { CustomEntity } from "../../../../../resources/data-warehouse/custom-entities/custom-entity";
import { FloatQuestion } from "../../../../../shared/models/forms/question-float";
import { TextboxQuestion } from "../../../../../shared/models/forms/question-textbox";
import { UntypedFormGroup } from "@angular/forms";
import { QuestionControlService } from "../../../../../shared/services/question-control.service";
import { MultiselectDataSource } from "../../../../../shared/components/multiselect/multiselect";

@Component({
    selector: 'app-form-loyalty-scores-custom-entity',
    templateUrl: './form-custom-entity.component.html',
    styleUrls: ['./form-custom-entity.component.scss']
})

export class FormCustomEntityComponent implements OnInit {

    @Input() entity: CustomEntity;
    @Input() definedEntityAttrs: {value: string, condition: string, field: string, custom_entity_id: string}[];

    inputs: QuestionBase<any>[];
    entityAttrsForm: UntypedFormGroup;
    currentSelectedType = 'number';

    constructor(
        private translate: TranslateService,
        private qcs: QuestionControlService,
    ) {}

    ngOnInit(): void {
        this.inputs = this.getInputs();
        this.entityAttrsForm = this.qcs.toFormGroup(this.inputs);
    }

    getEntityPayload(): {field: string, condition: string, value: string, custom_entity_id: string}[] {
        this.definedEntityAttrs = this.definedEntityAttrs.map(attr => {
            delete attr['id'];
            delete attr['motivator_id'];
            return attr;
        })
        return this.definedEntityAttrs;
    }

    entityAttributeChange(selectedValue?: MultiselectDataSource) {
        if(selectedValue) this.currentSelectedType = selectedValue.rawElement.type;
    }

    getInputConfig(inputKey: string): QuestionBase<any> {
        return this.qcs.getInputCfgByKey(this.inputs, inputKey);
    }

    addEntityAttribute() {
        const attrValue = this.entityAttrsForm.value['attribute'];
        const operatorValue = this.entityAttrsForm.value[`${this.currentSelectedType}_operator`];
        const field = attrValue && attrValue[0]?.id;
        const value = this.entityAttrsForm.value[`${this.currentSelectedType}_value`];
        const condition = operatorValue && operatorValue[0]?.id;
        const definedAttr = {value: value, condition: condition, field: field, custom_entity_id: this.entity.id};
        if(this.definedAttributeValid(definedAttr)) {
            this.definedEntityAttrs.push(definedAttr);
            this.entityAttrsForm.reset({
                string_operator: this.entityAttrsForm.get('string_operator').value
            });
        } else {
            console.warn('Attribute invalid... alert message');
        }
    }

    decorateAttrName(rawName: string) {
        rawName = rawName.replace(/\-/g, ' ');
        rawName = rawName.replace(/\_/g, ' ');
        rawName = rawName.charAt(0).toUpperCase() + rawName.slice(1);
        return rawName;
    }

    decorateAttrOperator(operator: string): string {
        return this.getOperators().find(op => op.id === operator).name;
    }

    deleteLine(index: number) {
        this.definedEntityAttrs.splice(index, 1);
    }

    private definedAttributeValid(definedAttr: {value: string, condition: string, field: string, custom_entity_id: string}): boolean {
        return !!definedAttr.value && !!definedAttr.condition && !!definedAttr.field && !!definedAttr.custom_entity_id;
    }

    private getInputs(): QuestionBase<any>[] {
        const inputs = [
            new MultiSelectQuestion({
                cssClasses: 'form-control input-md',
                key: `attribute`,
                options: this.getEntityAttributesAsOptions(),
                settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false }
            }),
            new MultiSelectQuestion({
                cssClasses: 'form-control input-md',
                key: `number_operator`,
                options: this.getOperators(),
                settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false }
            }),
            new FloatQuestion({
                cssClasses: 'form-control input-default',
                key: `number_value`,
                type: 'number'
            }),
            new MultiSelectQuestion({
                cssClasses: 'form-control input-md',
                key: `string_operator`,
                options: [this.getOperators()[0]],
                settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
                value: [this.getOperators()[0]],
                disabled: true
            }),
            new TextboxQuestion({
                cssClasses: 'form-control input-default',
                key: `string_value`,
                type: 'text'
            })
        ];
        return inputs;
    }

    private getEntityAttributesAsOptions(): MultiselectDataSource[] {
        const keys = Object.keys(this.entity.entity_attributes);
        return keys.map(key => (new MultiselectDataSource(key, this.decorateAttrName(key), this.entity.entity_attributes[key])));
    }

    private getOperators(): {id: string, name: string}[] {
        return [
            {id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
            {id: 'lteq', name: this.translate.instant('resources.segment_conditions.operators.lteq') },
            {id: 'lt', name: this.translate.instant('resources.segment_conditions.operators.lt') },
            {id: 'gteq', name: this.translate.instant('resources.segment_conditions.operators.gteq') },
            {id: 'gt', name: this.translate.instant('resources.segment_conditions.operators.gt') }
        ];
    }
}
