export const FLAGS = [

    // PLATFORM
    { name: 'country', key: 'users.country', value: 'All' },
    { name: 'customEntitiesVisibility', key: 'show.custom_entities', value: false },
    { name: 'displayMaintenanceMode', key: 'display.maintenance.mode', value: false },
    { name: 'newLanguageMenuVisibility', key: 'show.new.language.settings.menu', value: false },
    { name: 'onlyGDPRTabVisibility', key: 'show.config.company.only_gdpr_tab', value: false },
    { name: 'showDataUploadOption', key: 'show.bigquery.eurecat.smart_priority', value: false },
    { name: 'omnichannelModuleVisibility', key: 'show.omnichannel', value: false },
    { name: 'audiencesModuleVisibility', key: 'show.audiences', value: false },

    // ANALYTICS
    { name: 'basicAnalyticsScoresLostTab', key: 'show.basic_analytics.loyalty.scores_lost.tab', value: false },
    { name: 'basicAnalyticsScoresRewardTab', key: 'show.basic_analytics.loyalty.scores_reward.tab', value: false },
    { name: 'basicAnalyticsScoresWonTab', key: 'show.basic_analytics.loyalty.scores_won.tab', value: false },
    { name: 'customerContactsTabVisibility', key: 'show.analytics.customers.contacts.tab', value: false },
    { name: 'customersEvolutionVisibility', key: 'show.analytics.customers.customers_evolution.tab', value: false },
    { name: 'customersRetentionVisibility', key: 'show.analytics.customers.retention.tab', value: false },
    { name: 'redeemedVsNonRedeemedTabVisibility', key: 'show.analytics.customers.redemptions.tab', value: false },
    { name: 'saveFiltersVisibility', key: 'show.analytics.save_filters', value: false },

    // CDP
    { name: 'associatedTabVisibility', key: 'show.cdp.profiles.summary.associates', value: false },
    { name: 'associationsTabVisibility', key: 'show.cdp.profiles.summary.associations', value: false },
    { name: 'customerServiceDataReadOnly', key: 'cdp.customer_service_data_read_only', value: false },
    { name: 'couponsAvailableLanguagesVisibility', key: 'show.translate_language_options.form_coupons', value: false },
    { name: 'couponTypeVisibility', key: 'show.coupon_type.fields.coupons.form', value: false },
    { name: 'customerOnlyRead', key: 'cdp.customer_only_read', value: false },
    { name: 'hideCustomerAddress', key: 'cdp.customers.hide_address', value: false },
    { name: 'mockedBlocks', key: 'show.cdp.profiles.redesign.mock.components', value: false },
    { name: 'printFieldsVisibility', key: 'show.print.fields.coupons.form', value: false },
    { name: 'profileAllowDuplicateVisibility', key: 'show.profile.allow_duplicate_checks', value: false },
    { name: 'profileProfilingVisibility', key: 'show.profile.profiling_check', value: false },
    { name: 'profileRedesignVisibility', key: 'show.cdp.profiles.redesign', value: false },
    { name: 'profileTermsVisibility', key: 'show.profile.gdpr_checks', value: false },
    { name: 'showActivitiesAssociateID', key: 'cdp.activities.filter_by_associate_id', value: false },
    { name: 'showActivitiesCode', key: 'cdp.show_activity_code', value: false },
    { name: 'showActivitiesTypeField', key: 'show.cdp.activities.online_offline.field', value: false },
    { name: 'showCouponProductAffectation', key: 'create.coupon.product.affectation', value: false },
    { name: 'showCustomerCode', key: 'show.customers.code', value: false },
    { name: 'showCustomerPersonalData', key: 'show.customers.personal_data', value: false },
    { name: 'showFraudulent', key: 'cdp.show_fraudulent', value: false },
    { name: 'showGdprConsentHistory', key: 'show.profile.gdpr_consent_history', value: false },
    { name: 'showLocationTaxonomyTermIds', key: 'cdp.show_location_taxonomy_term_ids', value: false},
    { name: 'showPropertyAttributeType', key: 'cdp.customer_attributes.show_property_attribute_type', value: false },
    { name: 'showTiersDetails', key: 'show.cdp.profiles.tiers_details', value: false },
    { name: 'tagEvolutionTabVisibility', key: 'show.cdp.profiles.tag_evolution', value: false },
    { name: 'EnableCancelVoucher', key: 'enable.cancel_voucher_option', value: false },

    // LOYALTY
    { name: 'activeChallengesTabVisibility', key: 'show.loyalty.profiles.active_challenges', value: false },
    { name: 'challengesAvailableLanguagesVisibility', key: 'show.translate_language_options.challenges', value: false },
    { name: 'loyaltyAvailableLanguagesVisibility', key: 'show.translate_language_options.loyalty_engine', value: false },
    { name: 'movementsFilterVisibility', key: 'tab-scores-profile-movements-filter-visibility', value: false },
    { name: 'rewardsAvailableLanguagesVisibility', key: 'show.translate_language_options.rewards_section', value: false },
    { name: 'showMaxNumberVictories', key: 'show.max_number_victories', value: false },
    { name: 'showNewLoyaltyForm', key: 'show.new_loyalty_engine_form', value: false },
    { name: 'showNewRewardsForm', key: 'display.new_rewards_form', value: false },
    { name: 'showPointsPercentualField', key: 'show.points_percentual.field', value: false },
    { name: 'walletsSectionVisibility', key: 'show.wallet.section', value: false },

    // OFFERS
    { name: 'displayCampaignSender', key: 'display.campaign.sender.input', value: false },
    { name: 'displayCumulativeFieldCoupons', key: 'display.cumulative.coupons_form', value: false },
    { name: 'displayEditableInputsForecast', key: 'display.editable.inputs.forecast', value: false },
    { name: 'displaySmsUnsubscribeOption', key: 'display.sms_unsubscribe_link.data_protecion_config', value: false },
    { name: 'eurecatCheckboxPredictionVisibility', key: 'show.plans.eurecat.prediction', value: false },
    { name: 'managePlanOrCampaignApprovers', key: 'oe.permissions.checker', value: false },
    { name: 'reminderButtonVisibility', key: 'show.plans.send_reminder.roi_by_plan', value: false },
    { name: 'ShowCouponPoints', key: 'show.coupon_points', value: false },
    { name: 'showCustom1DynamicVariable', key: 'show.content_variables.custom1', value: false },
    { name: 'showGroupedPlansAdjustment', key: 'show.grouped.plans.adjustment.tab', value: false },
    { name: 'showGroupedPlansForecast', key: 'show.grouped.plans.forecast.tab', value: false },
    { name: 'showGroupedPlansROI', key: 'show.grouped.plans.roi.tab', value: false },
    { name: 'showMembershipOption', key: 'show.campaigns.membership_option', value: false },
    { name: 'showPlanCampaignAudienceChannel', key: 'show.plan.campaigns.audience.channel', value: false },
    { name: 'showPlanDetailCreditBalance', key: 'show.plans.credits.balance', value: false },
    { name: 'showPlansCampaignsRecommended', key: 'oe.plans.recommended_campaign', value: false },
    { name: 'showPlanForecastChannelColumns', key: 'show.plan.forecast.channel.columns', value: false },
    { name: 'showPlanROIChannelColumns', key: 'show.plan.roi.channel.columns', value: false },
    { name: 'showPointsOptionDemoSelex', key: 'show.coupons.discount_type_points', value: false },
    { name: 'showRoiByStoresReportOption', key: 'show.reports.roi_by_stores_option', value: false },
    { name: 'showTemplateCampaignsInputReports', key: 'show.template_campaigns.input.reports', value: false },
    { name: 'showUserPreferences', key: 'show.user_preferences.section', value: false },
    { name: 'showVouchersFrozenRedemptions', key: 'show.vouchers.frozen_redemptions', value: false },
    { name: 'useIACouponRecommenderVisibility', key: 'show.plans.campaigns.coupon_recommender.use_ai', value: false },
    { name: 'showExternalAppPushRedirections', key: 'show.external_app.push_redirections', value: true },
    { name: 'showPlansPrePostAnalysis', key: 'show.plans.pre_post_analysis', value: false },
    { name: 'showContactPolicies', key: 'show.omnichannel.contact_policies', value: false },

    // SIDENAV
    /* Audiences */
    { name: 'audiencesConsentVisibility', key: 'show.audiences.consent', value: false },
    /* Analytics */
    { name: 'basicAnalyticsCustomersSectionVisibility', key: 'show.basic_analytics.customers.section', value: false },
    { name: 'basicAnalyticsModuleVisibility', key: 'show.basic_analytics', value: false },
    { name: 'basicAnalyticsSalesSectionVisibility', key: 'show.basic_analytics.sales.section', value: false },
    { name: 'loyaltyAnalyticsModuleVisibility', key: 'show.loyalty_analytics', value: false },
    { name: 'loyaltyAnalyticsProgramPerformanceVisibility', key: 'show.shopper_analytics', value: false },
    { name: 'loyaltyAnalyticsPointsRewardsVisibility', key: 'show.loyalty_analytics.points_and_rewards.subsection', value: false },
    { name: 'loyaltyAnalyticsProgramLedgersVisibility', key: 'show.loyalty_analytics.program_ledgers.subsection', value: false },
    { name: 'retailAnalyticsCustomVisibility', key: 'show.retail_analytics.custom', value: false },
    { name: 'retailAnalyticsModuleVisibility', key: 'show.retail_analytics', value: false },
    { name: 'retailAnalyticsProductsVisibility', key: 'show.retail_analytics.products', value: false },
    { name: 'retailAnalyticsSummaryVisibility', key: 'show.retail_analytics.summary', value: false },
    { name: 'retailGroupAnalyticsModuleVisibility', key: 'show.group_analytics', value: false },
    { name: 'supplierAnalyticsCategoriesVisibility', key: 'show.supplier_analytics.categories', value: false },
    { name: 'supplierAnalyticsLocationsVisibility', key: 'show.supplier_analytics.locations', value: false },
    { name: 'supplierAnalyticsModuleVisibility', key: 'show.supplier_analytics', value: false },
    /* Offers Personalization */
    { name: 'offersPersonalizationModuleVisibility', key: 'show.offers', value: false },
    { name: 'offersPersonalizationAudiencesVisibility', key: 'show.offers_personalization.audiences', value: false },
    { name: 'showCustomerJourneysSection', key: 'show.customer_journeys.section', value: false },
    /* CDP */
    { name: 'connectorsVisibility', key: 'show.connectors', value: false },
    { name: 'consentPurposeVisibility', key: 'show.cdp.gdpr.consent_purpose', value: false },
    { name: 'dashboardSectionVisibility', key: 'show.cdp_analytics_dashboard.subsection', value: false },
    { name: 'dataSubjectRequestsVisibility', key: 'show.cdp.gdpr.data_subject_requests', value: false },
    /* Configuration */
    { name: 'plansConfigVisiblity', key: 'show.plans.configuration', value: false },
    /* Loyalty */
    { name: 'challengesSectionVisibility', key: 'show.loyalty.challenges.section', value: false },
    { name: 'loyaltyAudiencesVisibility', key: 'show.loyalty.audiences', value: false },
    { name: 'milestoneSectionVisibility', key: 'show.loyalty.milestone.section', value: false },
    { name: 'planTypeVisibility', key: 'show.loyalty.plan_type_configuration', value: false },
    { name: 'tiersManagementVisibility', key: 'show.scores.tiers_management', value: false },
    /* System Status */
    { name: 'systemStatusModuleVisibility', key: 'show.system_status', value: false },
    /* LOYALGPT */
    { name: 'loyalGPTVisibility', key: 'loyalgpt.visibility', value: false }
] as const;
