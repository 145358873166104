import { Injectable } from '@angular/core';
import { ClientIdConditionService } from './client-id-condition.service';
import { ClientUsualLocationConditionService } from './client-usual-location-condition.service';
import { ClientBirthdayConditionService } from './client-birthday-condition.service';
import { ClientRegistrationDateConditionService } from './services/client-registration-date-condition/client-registration-date-condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { Condition } from '../../models/segments/condition';
import { CouponConditionService } from './coupon-condition.service';
import { SqlConditionService } from './sql-condition.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupConditionService } from './group-condition.service';
import { TicketsConditionService } from './services/tickets-condition/tickets-condition.service';
import { VoucherCountConditionService } from './services/voucher-count-condition/voucher-count-condition.service';
import { CampaignConditionService } from './campaign-condition.service';
import { TaxonomiesCountConditionService } from './services/taxonomies-count-condition/taxonomies-count-condition.service';
import { MailConditionService } from './mail-condition.service';
import { PostalCodeConditionService } from './postal-code-condition.service';
import { CollectiveConditionService } from './collective-condition.service';
import { MaxSalesConditionService } from './max-sales-condition.service';
import { LastPurchaseConditionService } from './services/last-purchase-condition/last-purchase-condition.service';
import { ClientEmailConditionService } from './client-email-condition.service';
import { NestedSegmentConditionService } from './nested-segment-condition.service';
import { CustomerChannelStatusConditionService } from './customer-channel-status-condition.service';
import { AvailableScoreConditionService } from './services/available-score-condition/available-score-condition.service';
import { AverageActivityConditionService } from './average-activity-condition.service';
import { CustomConditionService } from './custom-condition.service';
import { CalculatedValuesBuyerTypesSegmentConditionService } from './calculated-values-buyer-types-segment-condition.service';
import { ClientCategoriesConditionService } from './client-categories-condition.service';
import { SystemSegmentConditionService } from './system-segment-condition.service';
import { PhonePrefixConditionService } from './phone-prefix-condition.service';
import { CountryConditionService } from './country-condition.service';
import { ValidatorFn } from '@angular/forms';
import { NameConditionService } from './services/name-condition/name-condition.service';
import { CityConditionService } from './services/city-condition/city-condition.service';
import { StateConditionService } from './services/state-condition/state-condition.service';
import { GenderConditionService } from './services/gender-condition/gender-condition.service';
import { CustomerAttributeConditionService } from './services/customer-attribute-condition/customer-attribute-condition.service';
import { CustomEntityConditionService } from './services/custom-entity-condition/custom-entity-condition.service';

export interface SegmentConditionProvider {
  inputs?: QuestionBase<any>[];
  customValidators?: ValidatorFn[];
  getInputs(params?: any): QuestionBase<any>[];
  prepareFormValuesToAPI(params?: any): any;
  prepareFormValuesFromAPI(params?: any): any;
}

@Injectable()
export class ConditionService {

  formValues = {};
  rawConditionValues = {};

  private srvDictionary: { key: string, service: SegmentConditionProvider, name: string }[] = [
    { key: 'group', service: this.group, name: this.translate.instant('resources.segment_conditions.types.group')},
    { key: 'birthday', service: this.clientBirthday, name: this.translate.instant('resources.segment_conditions.types.birthday')},
    { key: 'customer_id', service: this.clientId, name: this.translate.instant('resources.segment_conditions.types.customer_id')},
    { key: 'signup_date', service: this.clientRegistrationDate, name: this.translate.instant('resources.segment_conditions.types.signup_date')},
    { key: 'usual_location', service: this.clientLocation, name: this.translate.instant('resources.segment_conditions.types.usual_location')},
    { key: 'registration_location', service: this.clientLocation, name: this.translate.instant('resources.segment_conditions.types.registration_location')},
    { key: 'sales', service: this.ticketsCondition, name: this.translate.instant('resources.segment_conditions.types.tickets')},
    { key: 'coupon', service: this.coupon, name: this.translate.instant('resources.segment_conditions.types.coupons')},
    { key: 'voucher_count', service: this.voucherCount, name: this.translate.instant('resources.segment_conditions.types.voucher_count')},
    { key: 'sql', service: this.sql, name: this.translate.instant('resources.segment_conditions.types.sql')},
    { key: 'campaign', service: this.campaigns, name: this.translate.instant('resources.segment_conditions.types.campaigns')},
    { key: 'taxonomies_count', service: this.taxonomiesCountService, name: this.translate.instant('resources.segment_conditions.types.family')},
    { key: 'email_actions', service: this.emailCampaignsService, name: this.translate.instant('resources.segment_conditions.types.email_actions')},
    { key: 'postal_code', service: this.postalCode, name: this.translate.instant('resources.segment_conditions.types.postal_code')},
    { key: 'collective', service: this.clientCollective, name: this.translate.instant('resources.segment_conditions.types.collective')},
    { key: 'max_sales', service: this.maxSales, name: this.translate.instant('resources.segment_conditions.types.max_sales')},
    { key: 'last_purchase', service: this.lastPurchase, name: this.translate.instant('resources.segment_conditions.types.last_purchase')},
    { key: 'email', service: this.clientEmail, name: this.translate.instant('resources.segment_conditions.types.email')},
    { key: 'nested', service: this.nestedSegment, name: this.translate.instant('resources.segment_conditions.types.nested_segment')},
    { key: 'customer_channel_status', service: this.customerChannelStatus, name: this.translate.instant('resources.segment_conditions.types.customer_channel_status')},
    { key: 'score_available', service: this.availableScore, name: this.translate.instant('resources.segment_conditions.types.score_available')},
    { key: 'average_activity', service: this.averageActivity, name: this.translate.instant('resources.segment_conditions.types.average_activity')},
    { key: 'custom', service: this.clientCustom, name: this.translate.instant('resources.segment_conditions.types.custom')},
    { key: 'customf', service: this.clientCustom, name: this.translate.instant('resources.segment_conditions.types.custom')},
    { key: 'calculated_values_buyer_types', service: this.calculatedValuesBuyerTypes, name: this.translate.instant('resources.segment_conditions.types.calculated_values_buyer_types')},
    { key: 'buyer_type_segment', service: this.systemSegmentCondition, name: this.translate.instant('resources.segment_conditions.types.buyer_type_segment')},
    { key: 'client_expense_segment', service: this.systemSegmentCondition, name: this.translate.instant('resources.segment_conditions.types.client_expense_segment')},
    { key: 'activity_segment', service: this.systemSegmentCondition, name: this.translate.instant('resources.segment_conditions.types.activity_segment')},
    { key: 'recency_segment', service: this.systemSegmentCondition, name: this.translate.instant('resources.segment_conditions.types.recency_segment')},
    { key: 'customer_category', service: this.clientCategoriesCondition, name: this.translate.instant('resources.segment_conditions.types.customer_category')},
    { key: 'phone_prefix', service: this.phonePrefixCondition, name: this.translate.instant('resources.segment_conditions.types.phone_prefix')},
    { key: 'country', service: this.countryConditionSrv, name: this.translate.instant('resources.segment_conditions.types.countries')},
    { key: 'name', service: this.nameConditionSrv, name: this.translate.instant('resources.segment_conditions.types.name')},
    { key: 'city', service: this.cityConditionSrv, name: this.translate.instant('resources.segment_conditions.types.city')},
    { key: 'state', service: this.stateConditionSrv, name: this.translate.instant('resources.segment_conditions.types.state')},
    { key: 'gender', service: this.genderConditionSrv, name: this.translate.instant('resources.segment_conditions.types.gender')},
    { key: 'customer_attribute', service: this.customerAttributeConditionSrv, name: this.translate.instant('resources.segment_conditions.types.customer_attribute')},
    { key: 'custom_entity', service: this.customEntityConditionSrv, name: this.translate.instant('resources.segment_conditions.types.custom_entity')}
  ];

  constructor(
    private clientId: ClientIdConditionService,
    private clientLocation: ClientUsualLocationConditionService,
    private clientBirthday: ClientBirthdayConditionService,
    private group: GroupConditionService,
    private translate: TranslateService,
    private coupon: CouponConditionService,
    private sql: SqlConditionService,
    private clientRegistrationDate: ClientRegistrationDateConditionService,
    private ticketsCondition: TicketsConditionService,
    private voucherCount: VoucherCountConditionService,
    private taxonomiesCountService: TaxonomiesCountConditionService,
    private campaigns: CampaignConditionService,
    private emailCampaignsService: MailConditionService,
    private postalCode: PostalCodeConditionService,
    private clientCollective: CollectiveConditionService,
    private maxSales: MaxSalesConditionService,
    private lastPurchase: LastPurchaseConditionService,
    private clientEmail: ClientEmailConditionService,
    private nestedSegment: NestedSegmentConditionService,
    private customerChannelStatus: CustomerChannelStatusConditionService,
    private availableScore: AvailableScoreConditionService,
    private averageActivity: AverageActivityConditionService,
    private clientCustom: CustomConditionService,
    private calculatedValuesBuyerTypes: CalculatedValuesBuyerTypesSegmentConditionService,
    private systemSegmentCondition: SystemSegmentConditionService,
    private clientCategoriesCondition: ClientCategoriesConditionService,
    private phonePrefixCondition: PhonePrefixConditionService,
    private countryConditionSrv: CountryConditionService,
    private nameConditionSrv: NameConditionService,
    private cityConditionSrv: CityConditionService,
    private stateConditionSrv: StateConditionService,
    private genderConditionSrv: GenderConditionService,
    private customerAttributeConditionSrv: CustomerAttributeConditionService,
    private customEntityConditionSrv: CustomEntityConditionService
  ) { }

  public getServiceByKey(key: string): SegmentConditionProvider {
    return this.srvDictionary.find( item => item.key === key ).service;
  }

  public getFormValuesByList( list: Condition[], listToDestroy: Condition[] ) {
    return( this.parseList(list, listToDestroy) );
  }

  public hasFormValuesByKey( formKey: string ): boolean {
    const keyValues = Object.keys(this.formValues[formKey])
                            .map( value => this.formValues[formKey][value] )
                            .filter( value => {
                              return value !== undefined &&
                                     value !== null &&
                                     value !== '' &&
                                     value.length !== 0;
                            });
    return keyValues.length > 0;
  }

  public setUniqueFormControlId(item): string {
    const milliseconds = new Date().getTime();
    return `${item.content.key}_${milliseconds}`;
  }

  public getConditionsAsNestableList(segmentData: any): Condition[] {
    const rawConditions = segmentData['conditions'];
    const rootConditions = rawConditions.filter( rawCondition => !rawCondition.group_id )
      .map( rawCondition => {
        const condition = this.toCondition(rawCondition);
        return condition;
      });

    const nestedConditions = rawConditions.filter( rawCondition => rawCondition.group_id )
                                          .map( rawCondition => this.toCondition(rawCondition));

    const nested = this.buildNestedList(rootConditions, nestedConditions);
    return nested;
  }

  private buildNestedList(rootConditions: Condition[], nestedConditions: Condition[]): Condition[] {
    const parentIds = rootConditions.map( condition => condition.content.id );
    let nestedThisIteration = nestedConditions.filter( condition => parentIds.indexOf(condition.group_id) >= 0);
    const nestedNextIterations = nestedConditions.filter( condition => parentIds.indexOf(condition.group_id) < 0);

    if ( nestedNextIterations.length ) {
      nestedThisIteration = this.buildNestedList(nestedThisIteration, nestedNextIterations);
    }

    rootConditions = rootConditions.map( parentCondition => {
      if (parentCondition.content.key === 'group') {
        const children = nestedThisIteration.filter( childCondition => parentCondition.content.id === childCondition.group_id ).map(
          child => {
            child.operator = parentCondition.operator;
            return child;
          }
        );
        parentCondition.children = children;
      }
      return parentCondition;
    });

    return rootConditions;
  }

  /* Parse API data item as condition
  *  Stores condition form data values in service
  */
  private toCondition( obj: any ): Condition {
    const key = obj.key_field;
    const formControlId = `${obj.key_field}_${obj.id}`;

    const toCondition: Condition = {
      content: {
        id: obj.id,
        name: this.srvDictionary.find( item => item.key === key).name,
        key: key,
        formControlId: formControlId
      },
      group_id: obj.group_id,
      handle: true
    };

    if ( key === 'group' ) {
      toCondition.operator = obj.configuration.operator;
      toCondition.children = [];
    } else {
      toCondition.operator = null;
      const service = this.getServiceByKey(key);
      this.formValues[formControlId] = service.prepareFormValuesFromAPI({...obj.configuration});
      this.rawConditionValues[formControlId] = {...obj.configuration};
    }

    return toCondition;
  }

  /* Iterative function to retrieve cleansed data values to POST to API */
  private parseList( list: Condition[], listToDestroy: Condition[] ) {

    const valuesArray = [];

    list.forEach( condition => {
      let valueObj = {};
      if ( condition.content.key === 'group' ) {
        valueObj = {
          id: condition.content.id,
          key_field: condition.content.key,
          configuration: { operator: 'and' }
        };
        if ( condition.children.length ) {
          valueObj = {
            ...valueObj,
            ...{ conditions: this.parseList( condition.children, [] )},
            ...{ configuration: { operator: condition.children[0].operator }}
          };
        }
      } else {
        const service = this.getServiceByKey(condition.content.key);
        const parsedValues = service.prepareFormValuesToAPI( this.formValues[condition.content.formControlId] );
        valueObj = {
          id: condition.content.id,
          key_field: condition.content.key,
          configuration: parsedValues
        };
        if ( this.isCustomFCondition( valueObj ) ) { valueObj['key_field'] = 'customf'; }
        if ( condition.group_id ) { valueObj['group_id'] = condition.group_id; }
      }
      valuesArray.push( valueObj );
    });

    listToDestroy.forEach( condition => {
      const valueObj = { id: condition.content.id, _destroy: 1 };
      valuesArray.push(valueObj);
    });

    return valuesArray;
  }

  private isCustomFCondition(conditionObj): boolean {
    return conditionObj.key_field === 'custom' &&
           conditionObj.configuration.hasOwnProperty('scope') &&
           conditionObj.configuration.scope &&
           conditionObj.configuration.scope.endsWith('f');
  }
}
