import { AnalyticsLegendComponent } from './analytics-legend/analytics-legend.component';
import { AuthGuard } from '../../auth/auth-guard.service';
import { CreateUpdateCampaignsDynamicFormComponent } from '../../shared/components/create-update-campaigns-dynamic-form/create-update-campaigns-dynamic-form.component';
import { CreateUpdateCustomCustomerJourneyComponent } from '../../shared/components/create-update-customer-journeys-dynamic-form/journey-custom/create-update-custom-customer-journey.component';
import { CreateUpdateCustomerJourneysDynamicFormComponent } from '../../shared/components/create-update-customer-journeys-dynamic-form/create-update-customer-journeys-dynamic-form.component';
import { CreateUpdateSegmentsCSVComponent } from '../components/create-update-segments-csv/create-update-segments-csv.component';
import { CreateUpdateSegmentsV2Component } from '../components/create-update-segments-v2/create-update-segments-v2.component';
import { CreateUpdateTemplatesComponent } from '../../shared/components/create-update-templates/create-update-templates.component';
import { CustomerFusionComponent } from '../../resources/data-warehouse/profiles/show/tabs/summary/action-modals/customer-fusion/customer-fusion.component';
import { CustomerJourneyShowComponent } from '../../resources/customer-journeys/customer-journey-show/customer-journey-show.component';
import { EditIncrementalComponent } from '../../resources/analytics/reports/edit-report/incremental/edit-incremental.component';
import { EditReportComponent } from '../../resources/analytics/reports/edit-report/edit-report.component';
import { EditROIByStoreComponent } from '../../resources/analytics/reports/edit-report/roi-by-store/edit-roi-by-store.component';
import { EditSegmentEvoComponent } from '../../resources/analytics/reports/edit-report/segment-evo/edit-segment-evo.component';
import { FormAddStockComponent } from '../../resources/scores/rewards/show-rewards/reward-stock/form-add-stock/form-add-stock.component';
import { FormBigqueryJobsComponent } from '../../resources/config/bigquery-jobs/form/form-bigquery-jobs.component';
import { FormCashbackComponent } from '../../resources/config/loyalty/cashback/form/form-cashback.component';
import { FormCmsComponent } from '../../resources/content-designer/campaign-news-list/form/form-cms.component';
import { FormCouponsComponent } from '../../resources/data-warehouse/data-warehouse-coupons/form-coupons/form-coupons.component';
import { FormLoyaltyScoresComponent } from '../../resources/scores/loyalty_scores/form-loyalty-scores/form-loyalty-scores.component';
import { FormMilestonesComponent } from '../../resources/scores/milestones/form/form-milestones.component';
import { FormProfilesComponent } from '../../resources/data-warehouse/profiles/form/form-profiles.component';
import { FullscreenModalContainerComponent } from '../components/fullscreen-modal-container/fullscreen-modal-container.component';
import { GrantPointsComponent } from '../../resources/data-warehouse/profiles/show/tabs/summary/action-modals/grant-points/grant-points.component';
import { ImportRewardCodesComponent } from '../../resources/scores/rewards/show-rewards/import-reward-codes/import-reward-codes.component';
import { LargeModalContainerComponent } from '../../shared/components/large-modal-container/large-modal-container.component';
import { LoadCsvComponent } from '../../resources/content-designer/campaign-messages/loadCSV/load-csv.component';
import { NewCardComponent } from '../../resources/data-warehouse/profiles/show/tabs/summary/action-modals/new-card/new-card.component';
import { NgModule } from '@angular/core';
import { PlanCampaignCloneComponent } from '../../resources/plans/plan-campaign-clone/plan-campaign-clone.component';
import { PlanCampaignListComponent } from '../../resources/plans/plan-campaign-list/plan-campaign-list.component';
import { PlanCampaignNewComponent } from '../../resources/plans/plan-campaign-new/plan-campaign-new.component';
import { PlanDetailComponent } from '../../resources/plans/plan-detail/plan-detail.component';
import { PlanForecastDetailComponent } from '../../resources/plans/plan-forecast-detail/plan-forecast-detail.component';
import { PlanRoiDetailComponent } from '../../resources/plans/plan-roi-detail/plan-roi-detail.component';
import { PushTemplateEditComponent } from '../../resources/content-designer/campaign-templates/push-template-edit-component/push-template-edit.component';
import { RetroTagComponent } from '../../resources/segments/retro-tag/retro-tag.component';
import { RewardCodesBatchDetailComponent } from '../../resources/scores/rewards/show-rewards/reward-codes-batch-detail/reward-codes-batch-detail.component';
import { RewardCodesComponent } from '../../resources/scores/rewards/show-rewards/reward-codes/reward-codes.component';
import { RewardDetailsComponent } from '../../resources/scores/rewards/show-rewards/reward-details/reward-details.component';
import { RewardLocalizedContentComponent } from '../../resources/scores/rewards/show-rewards/reward-localized-content/reward-localized-content.component';
import { RewardStockDetailsComponent } from '../../resources/scores/rewards/show-rewards/reward-stock/reward-stock-details.component';
import { Routes, RouterModule } from '@angular/router';
import { SchedulePlanFormComponent } from '../../resources/plans/plans-list/schedule-plan-form/schedule-plan-form.component';
import { ShowBigqueryJobsComponent } from '../../resources/config/bigquery-jobs/show/show-bigquery-jobs.component';
import { ShowCampaignsStatisticsComponent } from '../../resources/campaigns/campaign-manual/show-campaigns-statistics/show-campaigns-statistics.component';
import { ShowCouponsComponent } from '../../resources/data-warehouse/data-warehouse-coupons/show/show-coupons.component';
import { ShowLoyaltyScoresComponent } from '../../resources/scores/loyalty_scores/show_loyalty_scores/show-loyalty-scores.component';
import { ShowPeriodsComponent } from '../../resources/config/periods/show-periods/show-periods.component';
import { ShowRewardsComponent } from '../../resources/scores/rewards/show-rewards/show-rewards.component';
import { ShowTicketsComponent } from '../../resources/data-warehouse/tickets/show/show-tickets.component';
import { SmsTemplateEditComponent } from '../../resources/content-designer/campaign-templates/sms-template-edit-component/sms-template-edit.component';
import { SubtractPointsComponent } from '../../resources/data-warehouse/profiles/show/tabs/summary/action-modals/subtract-points/subtract-points.component';
import { TierContainerComponent } from '../../resources/scores/tiers-management/tier-container/tier-container.component';
import { TransferScoreComponent } from '../../resources/data-warehouse/profiles/show/tabs/summary/action-modals/transfer-score/transfer-score.component';
import { UpdateLandingComponent } from '../../shared/components/create-update-landings/show/update-landing.component';
import { PlanAnalysisComponent } from '../../resources/plans/campaigns/plan-analysis/plan-analysis.component';

const routes: Routes = [
    {
      path: 'show/:id/segmentsV2',
      canActivate: [AuthGuard],
      data: {
        mode: 'readonly'
      },
      component: CreateUpdateSegmentsV2Component
    },
    {
      path: 'update/segmentsV2/:id',
      canActivate: [AuthGuard],
      data: {
        mode: 'update'
      },
      component: CreateUpdateSegmentsV2Component
    },
    {
      path: 'segmentsV2/new',
      canActivate: [AuthGuard],
      data: {
        mode: 'create'
      },
      component: CreateUpdateSegmentsV2Component
    },
    {
      path: 'update/segmentsCSV/:id',
      canActivate: [AuthGuard],
      data: {
        mode: 'update'
      },
      component: CreateUpdateSegmentsCSVComponent
    },
    {
      path: 'segmentsCSV/new',
      canActivate: [AuthGuard],
      data: {
        mode: 'create'
      },
      component: CreateUpdateSegmentsCSVComponent
    },
    {
        path: 'new/campaign_templates',
        canActivate: [AuthGuard],
        component: CreateUpdateTemplatesComponent,
        outlet: 'modal'
    },
    {
        path: 'update/campaign_templates/:id',
        canActivate: [AuthGuard],
        component: CreateUpdateTemplatesComponent,
        outlet: 'modal'
    },
    {
        path: 'show/sms_template/:id',
        canActivate: [AuthGuard],
        component: SmsTemplateEditComponent,
        data: {
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/sms_template',
        canActivate: [AuthGuard],
        component: SmsTemplateEditComponent,
        data: {
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'update/sms_template/:id',
        canActivate: [AuthGuard],
        component: SmsTemplateEditComponent,
        data: {
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'show/push_template/:id',
        canActivate: [AuthGuard],
        component: PushTemplateEditComponent,
        data: {
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/push_template',
        canActivate: [AuthGuard],
        component: PushTemplateEditComponent,
        data: {
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'update/push_template/:id',
        canActivate: [AuthGuard],
        component: PushTemplateEditComponent,
        data: {
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'show/customer_journeys/:id/histories/:history_id/statistics',
        canActivate: [AuthGuard],
        component: CustomerJourneyShowComponent,
        outlet: 'modal'
    },
    {
        path: 'show/customer_journeys/:id/histories/:history_id/summary',
        canActivate: [AuthGuard],
        component: CustomerJourneyShowComponent,
        outlet: 'modal'
    },
    {
        path: 'show/customer_journeys/:id/histories/:history_id',
        canActivate: [AuthGuard],
        component: CustomerJourneyShowComponent,
        outlet: 'modal'
    },
    {
        path: 'show/customer_journeys/:id/summary',
        canActivate: [AuthGuard],
        component: CustomerJourneyShowComponent,
        outlet: 'modal'
    },
    {
        path: 'show/customer_journeys/:id',
        canActivate: [AuthGuard],
        component: CustomerJourneyShowComponent,
        outlet: 'modal'
    },
    {
        path: 'update/customer_journeys/:id/histories/:history_id',
        canActivate: [AuthGuard],
        component: CreateUpdateCustomerJourneysDynamicFormComponent,
        outlet: 'modal'
    },
    {
        path: 'update/customer_journeys/:id',
        canActivate: [AuthGuard],
        component: CreateUpdateCustomerJourneysDynamicFormComponent,
        outlet: 'modal'
    },
    {
        path: 'update/customer_journeys/custom/:id',
        canActivate: [AuthGuard],
        component: CreateUpdateCustomCustomerJourneyComponent,
        outlet: 'modal'
    },
    {
        path: 'show/activities/:id',
        canActivate: [AuthGuard],
        component: ShowTicketsComponent,
        outlet: 'modal'
    },
    {
        path: 'update/landings/:id',
        canActivate: [AuthGuard],
        component: UpdateLandingComponent,
        outlet: 'modal'
    },
    {
        path: 'show/coupons/:id',
        canActivate: [AuthGuard],
        component: ShowCouponsComponent,
        outlet: 'modal'
    },
    {
      path: 'update/coupons/:id',
      canActivate: [AuthGuard],
      data: {
          title: 'coupons',
          mode: 'update',
          form: 'update_coupon'
      },
      component: FormCouponsComponent,
      outlet: 'modal'
    },
    {
        path: 'show/coupons/:id/histories/:history_id',
        canActivate: [AuthGuard],
        component: ShowCouponsComponent,
        outlet: 'modal'
    },
    {
        path: 'update/coupons/:id/histories/:history_id',
        canActivate: [AuthGuard],
        component: FormCouponsComponent,
        data: {
            title: 'coupons',
            mode: 'update',
            form: 'update_history_id'
        },
        outlet: 'modal'
    },
    {
        path: 'show/coupons/:id/details',
        canActivate: [AuthGuard],
        component: ShowCouponsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/coupons/:id/statistics',
        canActivate: [AuthGuard],
        component: ShowCouponsComponent,
        outlet: 'modal'
    },
    {
        path: 'import/coupons',
        canActivate: [AuthGuard],
        data: {
          title: 'import_coupons',
          mode: 'import'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'import/images',
        canActivate: [AuthGuard],
        data: {
          title: 'upload_images',
          mode: 'import'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'import/product_categories',
        canActivate: [AuthGuard],
        data: {
          title: 'import_product_categories',
          mode: 'import'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'import/product_internal_categories',
        canActivate: [AuthGuard],
        data: {
          title: 'import_product_internal_categories',
          mode: 'import'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/vouchers/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        data: {
            title: 'vouchers',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/periods/:id',
        canActivate: [AuthGuard],
        component: ShowPeriodsComponent,
        outlet: 'modal'
    },
    {
        path: 'new/periods',
        canActivate: [AuthGuard],
        component: RetroTagComponent,
        outlet: 'modal',
        data: {
            mode: 'periods_retro_tag',
            title: 'retro_tag',
        }
    },
    {
        path: 'show/periods/:id/segments',
        canActivate: [AuthGuard],
        component: ShowPeriodsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/periods/:id/report-segments',
        canActivate: [AuthGuard],
        component: ShowPeriodsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/periods/:id/report-attributes',
        canActivate: [AuthGuard],
        component: ShowPeriodsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/scores/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'scores',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/rewards/:id',
        canActivate: [AuthGuard],
        component: ShowRewardsComponent,
        data: {
            title: 'rewards',
            mode: 'show'
        },
        outlet: 'modal',
        children: [
            {
                path: 'stock',
                component: RewardStockDetailsComponent,
                data: {
                    slug: 'stock'
                },
                children: [
                    {
                        path: 'add',
                        component: FormAddStockComponent
                    }
                ]
            },
            {
                path: 'details',
                component: RewardDetailsComponent,
                data: {
                    slug: 'details'
                }
            },
            {
                path: 'localized-content',
                component: RewardLocalizedContentComponent,
                data: {
                    slug: 'localized_content'
                }
            },
            {
                path: 'codes',
                component: RewardCodesComponent,
                data: {
                    slug: 'codes'
                },
                children: [
                    {
                        path: 'import',
                        component: ImportRewardCodesComponent
                    },
                    {
                        path: 'batch/:id',
                        component: RewardCodesBatchDetailComponent
                    }
                ]
            },
            {
                path: '**', redirectTo: 'details', pathMatch: 'full'
            }
        ]
    },
    {
        path: 'show/rewards_inactive/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'rewards',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/redemptions/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'redemptions',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
      path: 'show/wallets/:id',
      canActivate: [AuthGuard],
      component: LargeModalContainerComponent,
      data: {
        title: 'wallets',
        mode: 'show'
      },
      outlet: 'modal'
    },
    {
        path: 'show/segments/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        outlet: 'modal',
        data: {
            mode: 'show',
            title: 'segments',
            tab: 'details'
        },
    },
    {
        path: 'show/segments/:id/details',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        outlet: 'modal',
        data: {
            mode: 'show',
            title: 'segments',
            tab: 'details'
        }
    },
    {
        path: 'show/segments/:id/retro_tag',
        canActivate: [AuthGuard],
        component: RetroTagComponent,
        outlet: 'modal',
        data: {
            mode: 'segment_retro_tag',
            title: 'retro_tag',
        }
    },
    {
        path: 'show/segments/:id/customers',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        outlet: 'modal',
        data: {
            mode: 'show',
            title: 'segments',
            tab: 'customers'
        }
    },
    {
        path: 'show/segments_archived/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        outlet: 'modal',
        data: {
            mode: 'show',
            title: 'segments',
            tab: 'details'
        },
    },
    {
        path: 'show/campaigns/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'campaigns',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/campaigns/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'campaigns'
        },
        component: CreateUpdateCampaignsDynamicFormComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaigns_content/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'campaigns_content',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/campaigns_content/:id',
        canActivate: [AuthGuard],
        component: CreateUpdateCampaignsDynamicFormComponent,
        data: {
            title: 'campaigns',
        },
        outlet: 'modal'
    },
    {
        path: 'show/campaigns_content/:id/histories/:history_id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'campaigns_histories_content',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'campaigns/:id/histories/:history_id/segment',
        canActivate: [AuthGuard],
        data: {
          mode: 'readonly',
          resource: 'campaign_history'
        },
        component: CreateUpdateSegmentsV2Component
    },
    {
        path: 'show/campaigns/:campaign_id/histories/:campaign_history_id/:show_type',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'campaigns_histories',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/campaigns/:id/statistics/customers',
        canActivate: [AuthGuard],
        component: ShowCampaignsStatisticsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaigns/histories/:id/statistics/customers',
        canActivate: [AuthGuard],
        component: ShowCampaignsStatisticsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaigns/:id/statistics/coupons',
        canActivate: [AuthGuard],
        component: ShowCampaignsStatisticsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaigns/histories/:id/statistics/coupons',
        canActivate: [AuthGuard],
        component: ShowCampaignsStatisticsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaigns/:id/statistics/messages',
        canActivate: [AuthGuard],
        component: ShowCampaignsStatisticsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaigns/histories/:id/statistics/messages',
        canActivate: [AuthGuard],
        component: ShowCampaignsStatisticsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/loyalty_scores/:id',
        canActivate: [AuthGuard],
        component: ShowLoyaltyScoresComponent,
        outlet: 'modal',
        data: {
            mode: 'show',
            title: 'loyalty_scores',
        }
    },
    {
        path: 'new/loyalty_scores',
        canActivate: [AuthGuard],
        data: {
            title: 'loyalty_scores',
            mode: 'create',
        },
        component: FormLoyaltyScoresComponent,
        outlet: 'modal'
    },
    {
        path: 'update/loyalty_scores/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'loyalty_scores',
            mode: 'update',
        },
        component: FormLoyaltyScoresComponent,
        outlet: 'modal'
    },
    // DEMO APPAREL
    {
        path: 'new/milestones',
        canActivate: [AuthGuard],
        data: {
            title: 'milestones'
        },
        component: FormMilestonesComponent,
        outlet: 'modal'
    },
    {
        path: 'update/milestones/1',
        canActivate: [AuthGuard],
        data: {
            title: 'milestones',
            mode: 'edit'
        },
        component: FormMilestonesComponent,
        outlet: 'modal'
    },
    {
        path: 'show/recipes/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'recipes',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/recipes',
        canActivate: [AuthGuard],
        data: {
            title: 'recipes'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/recipes/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'recipes',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/feature_internals/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'feature_internals',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/feature_internals',
        canActivate: [AuthGuard],
        data: {
            title: 'feature_internals'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/feature_internals/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'feature_internals',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/coupon',
        canActivate: [AuthGuard],
        data: {
            title: 'coupons',
            mode: 'create'
        },
        component: FormCouponsComponent,
        outlet: 'modal'
    },
    {
        path: 'show/messages/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'messages',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/messages/:id/histories/:history_id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'message_histories',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/messages',
        canActivate: [AuthGuard],
        data: {
            title: 'messages'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/messages/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'messages',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/messages/:id/histories/:history_id',
        canActivate: [AuthGuard],
        data: {
            title: 'message_histories',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/message_categories/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        data: {
            title: 'message_categories',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/message_categories/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'message_categories',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/message_categories',
        canActivate: [AuthGuard],
        data: {
            title: 'message_categories'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/message_vouchers/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        data: {
            title: 'vouchers',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'create/messages/csv',
        canActivate: [AuthGuard],
        component: LoadCsvComponent,
        data: {
            title: 'messages',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'new/segment_categories',
        canActivate: [AuthGuard],
        data: {
            title: 'segment_categories'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/segment_categories/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'segment_categories',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/segment_categories/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'segment_categories',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/products/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'products',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/products',
        canActivate: [AuthGuard],
        data: {
            title: 'products',
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/products/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'products',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/loyalty_score_moments/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'loyalty_score_moments',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/loyalty_score_moments',
        canActivate: [AuthGuard],
        data: {
            title: 'loyalty_score_moments'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/loyalty_score_moments/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'loyalty_score_moments',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/score_exchanges',
        canActivate: [AuthGuard],
        data: {
            title: 'score_exchanges'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/score_exchanges/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'score_exchanges',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/score_exchanges/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'score_exchanges',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/locations/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'locations',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/locations',
        canActivate: [AuthGuard],
        data: {
            title: 'locations'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/locations/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'locations',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/location_taxonomy_terms/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'location_taxonomy_terms',
            mode: 'show'
        },
        component: FullscreenModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/location_taxonomy_terms',
        canActivate: [AuthGuard],
        data: {
            title: 'location_taxonomy_terms'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/location_taxonomy_terms/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'location_taxonomy_terms',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
      path: 'import/location_taxonomy_terms',
      canActivate: [AuthGuard],
      data: {
        title: 'import_location_taxonomy_terms',
        mode: 'import'
      },
      component: LargeModalContainerComponent,
      outlet: 'modal'
    },
    {
        path: 'show/location_taxonomies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'location_taxonomies',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/location_taxonomies',
        canActivate: [AuthGuard],
        data: {
            title: 'location_taxonomies'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/location_taxonomies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'location_taxonomies',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/promotions/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'promotions',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/promotional_discounts/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'promotional_discounts',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/promotional_discounts',
        canActivate: [AuthGuard],
        data: {
            title: 'promotional_discounts'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/promotional_discounts/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'promotional_discounts',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/feature_taxonomies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'feature_taxonomies',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/feature_taxonomies',
        canActivate: [AuthGuard],
        data: {
            title: 'feature_taxonomies'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/feature_taxonomies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'feature_taxonomies',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/features/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'features',
            mode: 'show'
        },
        component: FullscreenModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/features/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'features',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/features',
        canActivate: [AuthGuard],
        data: {
            title: 'features'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/campaigns',
        canActivate: [AuthGuard],
        data: {
            title: 'campaigns'
        },
        component: CreateUpdateCampaignsDynamicFormComponent,
        outlet: 'modal'
    },
    {
        path: 'new/campaign_categories',
        canActivate: [AuthGuard],
        data: {
            title: 'campaign_categories'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/campaign_categories/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'campaign_categories',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/campaign_categories/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'campaign_categories',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/credit_transactions/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'credit_transactions',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/credit_transactions',
        canActivate: [AuthGuard],
        data: {
            title: 'credit_transactions'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/credit_transactions/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'credit_transactions',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/bigquery_jobs',
        canActivate: [AuthGuard],
        data: {
            title: 'bigquery_jobs',
        },
        component: FormBigqueryJobsComponent,
        outlet: 'modal'
    },
    {
      path: 'update/bigquery_jobs/:id',
      canActivate: [AuthGuard],
      data: {
          title: 'bigquery_jobs',
          mode: 'update'
      },
      component: FormBigqueryJobsComponent,
      outlet: 'modal'
    },
    {
        path: 'new/rewards',
        canActivate: [AuthGuard],
        data: {
            title: 'rewards',
            mode: 'create'
        },
        component: FullscreenModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/rewards/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'rewards',
            mode: 'update'
        },
        component: FullscreenModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/reward_categories',
        canActivate: [AuthGuard],
        data: {
            title: 'reward_categories'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/reward_categories/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'reward_categories',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/reward_categories/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'reward_categories',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/wallets',
        canActivate: [AuthGuard],
        data: {
          title: 'wallets'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/news/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/news/:id',
        canActivate: [AuthGuard],
        component: FormCmsComponent,
        data: {
            title: 'news',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'new/news',
        canActivate: [AuthGuard],
        component: FormCmsComponent,
        data: {
            title: 'news',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'show/news_taxonomy_terms/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news_taxonomy_terms',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/news_taxonomy_terms',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news_taxonomy_terms',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'update/news_taxonomy_terms/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news_taxonomy_terms',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'show/news_taxonomies/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news_taxonomies',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/news_taxonomies',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news_taxonomies',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'update/news_taxonomies/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'news_taxonomies',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'new/profile',
        canActivate: [AuthGuard],
        data: {
            title: 'profiles'
        },
        component: FormProfilesComponent,
        outlet: 'modal'
    },
    {
        path: 'update/profile/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'profiles',
            mode: 'update'
        },
        component: FormProfilesComponent,
        outlet: 'modal'
    },
    {
        path: 'new/user',
        canActivate: [AuthGuard],
        data: {
            title: 'user',
            mode: 'create'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/user/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'user',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/user/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'user',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'grant_points/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'grant_points',
            mode: 'action_modal'
        },
        component: GrantPointsComponent,
        outlet: 'modal'
    },
    {
        path: 'transfer_score/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'transfer_score',
            mode: 'action_modal'
        },
        component: TransferScoreComponent,
        outlet: 'modal'
    },
    {
      path: 'subtract_score/:id',
      canActivate: [AuthGuard],
      data: {
        title: 'subtract_score',
        mode: 'action_modal'
      },
      component: SubtractPointsComponent,
      outlet: 'modal'
    },
    {
        path: 'new_card/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'new_card',
            mode: 'action_modal'
        },
        component: NewCardComponent,
        outlet: 'modal'
    },
    {
        path: 'customer_fusion/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'customer_fusion',
            mode: 'action_modal'
        },
        component: CustomerFusionComponent,
        outlet: 'modal'
    },
    {
        path: 'show/reports/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        data: {
            title: 'reports',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/reports/:id',
        canActivate: [AuthGuard],
        component: EditReportComponent,
        data: {
            title: 'reports',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'new/reports',
        canActivate: [AuthGuard],
        component: EditReportComponent,
        data: {
            title: 'reports',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'reports/:id/report_incremental/new',
        canActivate: [AuthGuard],
        component: EditIncrementalComponent,
        data: {
            title: 'report_incremental',
        },
        outlet: 'modal'
    },
    {
        path: 'reports/:id/report_incremental/:history_id/edit',
        canActivate: [AuthGuard],
        component: EditIncrementalComponent,
        data: {
            title: 'report_incremental',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'reports/:id/report_segment/new',
        canActivate: [AuthGuard],
        component: EditSegmentEvoComponent,
        data: {
            title: 'report_segment',
        },
        outlet: 'modal'
    },
    {
        path: 'reports/:id/report_segment/:history_id/edit',
        canActivate: [AuthGuard],
        component: EditSegmentEvoComponent,
        data: {
            title: 'report_segment',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'reports/:id/report_roi_by_store/new',
        canActivate: [AuthGuard],
        component: EditROIByStoreComponent,
        data: {
            title: 'roi_by_store',
        },
        outlet: 'modal'
    },
    {
        path: 'reports/:id/report_roi_by_store/:history_id/edit',
        canActivate: [AuthGuard],
        component: EditROIByStoreComponent,
        data: {
            title: 'roi_by_store',
            mode: 'update'
        },
        outlet: 'modal'
    },
    {
        path: 'show/bigquery_jobs/:id',
        canActivate: [AuthGuard],
        component: ShowBigqueryJobsComponent,
        data: {
            title: 'bigquery_jobs',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/bigquery_jobs/:id/execute_date',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'execute_date',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'show/bigquery_jobs/:id/steps/:step_id/execute_date',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'execute_date',
            mode: 'create'
        },
        outlet: 'modal'
    },
    {
        path: 'new/payment_method',
        canActivate: [AuthGuard],
        data: {
            title: 'payment_methods',
            mode: 'create'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/legend',
        canActivate: [AuthGuard],
        component: AnalyticsLegendComponent,
        data: {
            title: 'legend',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/versions/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'versions',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/synchros/:id',
        canActivate: [AuthGuard],
        component: FullscreenModalContainerComponent,
        data: {
            title: 'synchros',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/logs/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'logs',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'show/plans/:id',
        canActivate: [AuthGuard],
        component: PlanDetailComponent,
        data: {
            title: 'plans',
            mode: 'show'
        },
        outlet: 'modal',
        children: [
            {
                path: 'campaigns',
                component: PlanCampaignListComponent,
                data: { title: 'plan-campaigns', slug: 'campaigns' },
                children: [
                    {
                        path: 'new/step_1',
                        component: PlanCampaignNewComponent,
                        data: { title: 'plan-campaign-new', mode: 'create', slug: 'step_1', step_num: 1 },
                    },
                    {
                        path: 'new/step_2',
                        component: PlanCampaignNewComponent,
                        data: { title: 'plan-campaign-new', mode: 'create', slug: 'step_2', step_num: 2 },
                    },
                    {
                        path: ':id/step_1',
                        component: PlanCampaignNewComponent,
                        data: { title: 'plan-campaign-detail', mode: 'update', slug: 'step_1', step_num: 1 }
                    },
                    {
                        path: ':id/step_2',
                        component: PlanCampaignNewComponent,
                        data: { title: 'plan-campaign-detail', mode: 'update', slug: 'step_2', step_num: 2 }
                    },
                    {
                        path: ':id/step_3',
                        component: PlanCampaignNewComponent,
                        data: { title: 'plan-campaign-detail', mode: 'update', slug: 'step_3', step_num: 3 }
                    },
                    {
                        path: ':id/step_4',
                        component: PlanCampaignNewComponent,
                        data: { title: 'plan-campaign-detail', mode: 'update', slug: 'step_4', step_num: 4 }
                    },
                    {
                        path: ':id/clone',
                        component: PlanCampaignCloneComponent,
                        data: { title: 'plan-campaign-clone', mode: 'create' }
                    },
                ]
            },
            {
                path: 'forecast',
                component: PlanForecastDetailComponent,
                data: { title: 'plan-forecast', slug: 'forecast' }
            },
            {
                path: 'roi',
                component: PlanRoiDetailComponent,
                data: { title: 'plan-roi', slug: 'roi' }
            },
            {
                path: 'analysis',
                component: PlanAnalysisComponent,
                data: { title: 'plan-analysis', slug: 'analysis' }
            },
            {
                path: '**', redirectTo: 'campaigns', pathMatch: 'full'
            }
        ]
    },
    {
        path: 'new/plan',
        canActivate: [AuthGuard],
        data: {
            title: 'plan',
            mode: 'create'
        },
        component: PlanDetailComponent,
        outlet: 'modal'
    },
    {
        path: 'schedule_plan/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'schedule_plan',
        },
        component: SchedulePlanFormComponent,
        outlet: 'modal'
    },
    {
        path: 'new/currency_conversions',
        canActivate: [AuthGuard],
        data: {
            title: 'currency_conversions'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/currency_conversions/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'currency_conversions',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/reserved_ids',
        canActivate: [AuthGuard],
        data: {
            title: 'reserved_ids',
            mode: 'action_modal'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/streaming_api/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'streaming_api',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'new/audience',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'audience_selector',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
      path: 'new/custom-entities',
      canActivate: [AuthGuard],
      data: {
          title: 'custom_entities'
      },
      component: LargeModalContainerComponent,
      outlet: 'modal'
    },
    {
      path: 'update/custom-entities/:id',
      canActivate: [AuthGuard],
      data: {
          title: 'custom_entities',
          mode: 'update'
      },
      component: LargeModalContainerComponent,
      outlet: 'modal'
    },
    {
      path: 'show/custom-entities/:id',
      canActivate: [AuthGuard],
      data: {
        title: 'custom_entities',
        mode: 'show'
      },
      component: FullscreenModalContainerComponent,
      outlet: 'modal'
    },
    {
        path: 'show/customer-attribute/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'customer-attribute',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
      path: 'new/customer-attribute',
      canActivate: [AuthGuard],
      data: {
          title: 'customer-attribute'
      },
      component: LargeModalContainerComponent,
      outlet: 'modal'
    },
    {
      path: 'update/customer-attribute/:id',
      canActivate: [AuthGuard],
      data: {
          title: 'customer-attribute',
          mode: 'update'
      },
      component: LargeModalContainerComponent,
      outlet: 'modal'
    },
    {
        path: 'show/tier-definition/:id',
        canActivate: [AuthGuard],
        component: TierContainerComponent,
        data: {
            title: 'tier-definition',
            mode: 'show',
            type: 'tier'
        },
        outlet: 'modal'
    },
    {
      path: 'new/tier-definition',
      canActivate: [AuthGuard],
      data: {
          title: 'tier-definition',
          mode: 'create',
          type: 'tier'
      },
      component: TierContainerComponent,
      outlet: 'modal'
    },
    {
      path: 'update/tier-definition/:id',
      canActivate: [AuthGuard],
      data: {
          title: 'tier-definition',
          mode: 'update',
          type: 'tier'
      },
      component: TierContainerComponent,
      outlet: 'modal'
    },
    {
      path: 'new/challenge',
      canActivate: [AuthGuard],
      data: {
          title: 'challenge',
          mode: 'create',
          type: 'challenge'
      },
      component: TierContainerComponent,
      outlet: 'modal'
    },
    {
      path: 'update/challenge/:id',
      canActivate: [AuthGuard],
      data: {
          title: 'challenge',
          mode: 'update',
          type: 'challenge'
      },
      component: TierContainerComponent,
      outlet: 'modal'
    },
    {
        path: 'show/challenge/:id',
        canActivate: [AuthGuard],
        component: TierContainerComponent,
        data: {
            title: 'challenge',
            mode: 'show',
            type: 'challenge'
        },
        outlet: 'modal'
    },
    {
        path: 'new/cashback',
        canActivate: [AuthGuard],
        data: {
            title: 'cashback',
        },
        component: FormCashbackComponent,
        outlet: 'modal'
    },
    {
        path: 'update/cashback/:country',
        canActivate: [AuthGuard],
        data: {
            title: 'cashback',
            mode: 'update'
        },
        component: FormCashbackComponent,
        outlet: 'modal'
    },
    {
        path: 'show/partners/:id',
        canActivate: [AuthGuard],
        component: LargeModalContainerComponent,
        data: {
            title: 'partner',
            mode: 'show'
        },
        outlet: 'modal'
    },
    {
        path: 'update/partners/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'partner',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/partners',
        canActivate: [AuthGuard],
        data: {
            title: 'partner'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/api-key',
        canActivate: [AuthGuard],
        data: {
            title: 'api_key'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/api-key/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'api_key',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/api-key/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'api_key',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/reward-taxonomies',
        canActivate: [AuthGuard],
        data: {
            title: 'reward_taxonomies'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/reward-taxonomies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'reward_taxonomies',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'show/reward-taxonomies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'reward_taxonomies',
            mode: 'show'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'new/contact-policies',
        canActivate: [AuthGuard],
        data: {
            title: 'contact_policies',
            mode: 'create'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    },
    {
        path: 'update/contact-policies/:id',
        canActivate: [AuthGuard],
        data: {
            title: 'contact_policies',
            mode: 'update'
        },
        component: LargeModalContainerComponent,
        outlet: 'modal'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ModalsRoutingModule { }
