import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { registerLocaleData } from '@angular/common';
import { Reward } from '../../../../shared/models/reward/reward';
import { RewardsService } from '../rewards.service';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-rewards',
  templateUrl: './show-rewards.component.html',
  styleUrls: ['./show-rewards.component.scss']
})

export class ShowRewardsComponent implements OnInit {

  filteredAvailableLanguages: string[];
  flags = this.featureFlags.flags;
  hasImage: boolean;
  mode: string;
  rewardData: Reward;
  rewardId = this.route.snapshot.params.id;
  selectedTab: string;
  showCodesTab: boolean;
  showRewardsForm: UntypedFormGroup;
  showRewardsInputs: QuestionBase<any>[];
  showStockTab: boolean;
  subs$: Subscription[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private rewardsService: RewardsService,
    private featureFlags: FeatureFlagsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.selectedTab = this.route.firstChild.snapshot.data.slug;
    this.getParams();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  refreshUrl(tabId) {
    this.selectedTab = tabId;
    this.changeDetector.detectChanges();
  }

  goToCategory() {
    window.open(`#/loyalty/rewards/rewards-categories(modal:show/reward_categories/${this.rewardData['category'].id})`);
  }

  goToLocation(id) {
    window.open(`#/customer-data-platform/warehouse/locations(modal:show/locations/${id})`);
  }

  goToCoupon() {
    window.open(`#/customer-data-platform/warehouse/coupons(modal:show/coupons/${this.rewardData.coupon_id})`);
  }

  getFirstHalfLength(): number {
    return this.rewardData?.countries
      ? Math.ceil(this.rewardData.countries.length / 2)
      : 0;
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        if (this.router.url.indexOf(`(modal:`) >= 0) {
          this.mode = 'show';
        }
        this.getRewardById();
      }
    });
  }

  private getRewardById() {
    this.rewardsService.getRewardsById(this.rewardId).subscribe((resp: Reward) => {
      this.rewardData = resp;
      this.showCodesTab = ['code', 'digital'].includes(this.rewardData.type);
      this.showStockTab = !['code', 'digital'].includes(this.rewardData.type);
    });
  }
}
