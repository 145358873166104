import { setProfileCustomsList } from "../../../resources/data-warehouse/profiles/utils/profiles.utils";

export class Customer {

  id: number;
  name: string;
  surname1: string;
  surname2: string;
  avatarInitials: string;
  fullName: string;
  status: string;
  digitized: boolean;
  fraudulent: boolean;
  registrationDate: string;
  registrationStore: string;
  usualStoreId: number | string;
  usualStore: string;
  appStatus: 'installed' | 'not_installed';
  dni: string;
  gender: 1 | 2; // 2: Female / 1: Male
  birth: string;
  address: string;
  state: string;
  city: string;
  postcode: string;
  email: string;
  phone: string;
  phonePrefix: string;
  preferredLanguageName: string;
  paperFree: boolean;
  customValues: {name: string, value: any}[];
  overallExpenditure: number;
  quarterlyExpenditure: number;
  totalScores: number;
  availableScores: number;
  activitiesCount: number;
  kind: string;
  mergedStatus: boolean;
  mergedTo: number;
  code: string;
  terms: boolean;
  profiling: boolean;
  communications: boolean;
  emailUnsubscribe: boolean;
  pushUnsubscribe: boolean;
  smsUnsubscribe: boolean;

  constructor(apiObj: object) {
    this.id = apiObj['id'];
    this.name = apiObj['name'];
    this.surname1 = apiObj['surname_1'];
    this.surname2 = apiObj['surname_2'];
    this.avatarInitials = this.getInitials();
    this.fullName = this.getFullName();
    this.status = apiObj['status'];
    this.digitized = apiObj['digitized'] ?? false;
    this.fraudulent = apiObj['fraudulent'] ?? false;
    this.registrationDate = apiObj['profile']['created_at'];
    this.registrationStore = this.getRegistrationStore(apiObj['profile']);
    this.usualStoreId = apiObj['usual_location_id'];
    this.appStatus = this.getAppStatus(apiObj['profile']);
    this.dni = apiObj['profile']['dni'] ? apiObj['profile']['dni'] : '-';
    this.gender = apiObj['profile']['gender'];
    this.birth = apiObj['profile']['birth'];
    this.address = apiObj['profile']['address'];
    this.state = apiObj['profile']['state'];
    this.city = apiObj['profile']['city'];
    this.postcode = apiObj['profile']['postal_code'];
    this.email = apiObj['email'];
    this.phonePrefix = apiObj['phone_prefix'];
    this.phone = this.getPhoneNumber(apiObj);
    this.preferredLanguageName = apiObj['preferred_language_name'];
    this.paperFree = apiObj['paper_free'] ?? false;
    this.overallExpenditure = apiObj['profile']['total'] ? parseFloat(apiObj['profile']['total']) : 0;
    this.quarterlyExpenditure = apiObj['trimestral_expend'] ? parseFloat(apiObj['trimestral_expend']) : 0;
    this.totalScores = apiObj['profile']['score'] ? parseFloat(apiObj['profile']['score']) : 0;
    this.availableScores = apiObj['profile']['score_available'] ? parseFloat(apiObj['profile']['score_available']) : 0;
    this.activitiesCount = apiObj['profile']['activities_count'] ? parseFloat(apiObj['profile']['activities_count']) : 0;
    this.kind = apiObj['kind'];
    this.mergedStatus = apiObj['status'] ? apiObj['status'] === 'merged' : false;
    this.mergedTo = apiObj['merged_to'];
    this.code = apiObj['code'] ? apiObj['code'] : '-';
    this.terms = !!apiObj['terms'];
    this.profiling = !!apiObj['profiling'];
    this.communications = !!apiObj['communications'];
    this.emailUnsubscribe = !!apiObj['email_unsubscribe'];
    this.pushUnsubscribe = !!apiObj['push_unsubscribe'];
    this.smsUnsubscribe = !!apiObj['sms_unsubscribe'];
  }

  getInitials(): string {
    const initialA = this.name ? this.name.charAt(0).toUpperCase() : '';
    const initialB = this.surname1 ? this.surname1.charAt(0).toUpperCase() : '';
    return `${initialA}${initialB}`;
  }

  getFullName(): string {
    let fullName = this.name;
    if (this.surname1) { fullName += ` ${this.surname1}`; }
    return fullName;
  }

  getPhoneNumber(apiObj: object): string {
    if (!apiObj['phone']) { return '-'; }
    return apiObj['phone_prefix'] ? `(+${apiObj['phone_prefix']}) ${apiObj['phone']}` : apiObj['phone'];
  }

  // TODO: Ask PO about location templating, how we should render this info? meanwhile use location name alone.
  getRegistrationStore(profileObj: object): string {
    if (profileObj['_embedded'] && profileObj['_embedded']['location']) {
      return profileObj['_embedded']['location']['name'];
    }
    return '-';
  }

  // Requested from single customer cmp. as the usual store is only represented with the id
  setUsualStore(storeObj: object) {
    this.usualStore = storeObj['name'] || '-';
  }

  getAppStatus(profileObj: object) {
    if (profileObj['push_token'] && profileObj['push_token'] !== null && profileObj['push_token'] !== undefined) {
      return 'installed';
    }
    return 'not_installed';
  }

  setProfileCustomValues(rawCurrentUser: object, rawCustomerProfile: object) {
    this.customValues = setProfileCustomsList(rawCurrentUser, rawCustomerProfile)
  }

}
