<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.loyalty_policy'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.validity_points_policy' | translate}}</span>
    </div>
    <div class="row mrg-top-15">
      <div class="col">
        <div class="form-group">
          <label class="text-bold" for="available_from"
            [translate]="'resources.scores.loyalty_scores.fields.available_from'"></label>
          <app-question [question]="getInputConfig('available_from')" [form]="loyaltyScoresForm"></app-question>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="text-bold" for="available_to"
            [translate]="'resources.scores.loyalty_scores.fields.available_to'"></label>
          <app-question [question]="getInputConfig('available_to')" [form]="loyaltyScoresForm"></app-question>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="text-bold" for="active">
            <span>{{'resources.scores.loyalty_scores.fields.status' | translate}}</span>
            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                tooltipPosition="top"
                pTooltip="{{'resources.scores.common_messages.status_tooltip' | translate}}">
            </em>
          </label>
          <app-question [question]="getInputConfig('active')" [form]="loyaltyScoresForm"></app-question>
        </div>
      </div>
    </div>

  </div>
</div>