import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileService } from '../../profiles/profile.service';
import { ModuleSlugs } from '../enums/modules.enums';

@Injectable()
export class NotificationsService extends ResourcesService  {

  purchasedModules: string[] = this.profileService.getStoredUser().company?.purchased_modules || [];

  constructor(
    http: HttpClient,
    private profileService: ProfileService
  ) {
    super(http);
  }

  getNotifications() {
    return this.getData({ apiEndPoint: `notifications` });
  }

  getGCSfile( fileCode: string ): Observable<any> {
    return this.getData({ apiEndPoint: `gcs_files?file=${fileCode}` });
  }

  getS3file( fileCode: string, bucket: string, region: string ): Observable<any> {
    return this.getData({
      apiEndPoint: `s3_files`,
      filtering: {
        file: fileCode,
        bucket: bucket,
        region: region
      }
    });
  }

  postS3files( files: any, bucket: string, region: string ): Observable<any> {
    return this.postResource({ files: files, bucket: bucket, region: region }, `s3_files/multiple` );
  }

  processNotificationText(notificationText: string): string {
    // Remove breaklines / parse links
    let replacedText = notificationText.replace(/<br>/g, '');
        replacedText = replacedText.replace(/&lt;br&gt;/g, '');
        replacedText = replacedText.replace(/ ?<br \/> ?/g, '');
        replacedText = replacedText.replace('/app/#/admin/s3_files?', '/#/notifications/s3_files?');
        replacedText = replacedText.replace('/#/admin/s3_files?', '/#/notifications/s3_files?');
        replacedText = replacedText.replace('/admin/campaigns/', '/omnichannel-manager/campaigns(modal:show/campaigns/');
        replacedText = replacedText.replace('/admin/segments/', this.replaceSegmentsTextByModule());
        replacedText = replacedText.replace(/\/admin\/campaign_blocks\/([0-9]+)\?historyId=([0-9]+)/, '/customer-journeys(modal:show/customer_journeys/$1/histories/$2/summary');
        replacedText = replacedText.replace(/\/admin\/reports\/([0-9]+)\/report/, '/offers-personalization/dashboard/reports(modal:show/reports/$1)');
        replacedText = replacedText.replace(/\/app\/#\/admin\/synchros\/([0-9]+)/, '/#/customer-data-platform/collect/files-management/synchro-history(modal:show/synchros/$1)');
        replacedText = replacedText.replace(/\/admin\/synchros\/([0-9]+)/, '/customer-data-platform/collect/files-management/synchro-history(modal:show/synchros/$1)');
        replacedText = replacedText.replace(/\/admin\/plans\/([0-9]+)\/campaigns/, '/offers-personalization/plans(modal:show/plans/$1/campaigns)');
    return replacedText;
  }

  private replaceSegmentsTextByModule(): string {
    // Default text to replace if all modules are purchased or audiences is set in company's purchased_modules.
    let replacedText = '/segments/segments(modal:show/segments/';

    const isAudiencesPurchased = this.isModuleSetToPurchased(ModuleSlugs.Audiences);
    const isLoyaltyPurchased = this.isModuleSetToPurchased(ModuleSlugs.Loyalty);
    const isOffersPurchased = this.isModuleSetToPurchased(ModuleSlugs.OffersPersonalization);

    if (!isAudiencesPurchased && (isLoyaltyPurchased || isOffersPurchased)) {
      replacedText = isLoyaltyPurchased ? '/loyalty/audiences(modal:show/segments/' : '/offers-personalization/audiences(modal:show/segments/';
    }

    return replacedText;
  }

  private isModuleSetToPurchased(moduleSlug: string): boolean {
    return !!this.purchasedModules?.includes(moduleSlug);
  }

}
