export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

export function isEmptyObjectValues(object: object): boolean {
  return Object.values(object).every(val => val === undefined || val === null || val === '' || !val.length);
}

export function getIdValueFromArrayObject(arrayObject: any[], asSingleElementArray = false) {
  if(arrayObject?.length > 0 && arrayObject[0].id) {
    return asSingleElementArray ? [arrayObject[0].id] : arrayObject[0].id;
  }
  return null;
}

export function isIntegerValue1GreaterThanValue2(value1: string, value2: string, base: number = 10): boolean {
  return parseInt(value1, base) > parseInt(value2, base);
}

export function getRandomNumber() {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
}

export function getDataTableTextPropValue(prop: string): unknown {
  return (element) => element?.[prop] ? element[prop] : null;
}

export function getDataTableIconPropValue(prop: string): unknown {
  return (element) => element?.[prop] ? element[prop] : 'none';
}

export function getResourceProp(apiObject: object, resource: string, prop: string, defaultValue: any = null): any {
  if (!apiObject.hasOwnProperty(resource)) { return defaultValue; }
  if (apiObject[resource] && !apiObject[resource].hasOwnProperty(prop)) { return defaultValue; }
  if (!apiObject[resource][prop]){ return defaultValue; }
  return apiObject[resource][prop];
}
