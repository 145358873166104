<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="campaign">
      <div class="modal-body height-100 background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-block no-pdd no-mrg pdd-horizon-15 pdd-top-15">
                <h2 class="card-title no-mrg-btm">
                  {{campaign.name}}
                </h2>
                <small class="label" [ngClass]="{ 'label-success': campaign.status === 'delivered',
                                                  'label-primary': campaign.status === 'sending',
                                                  'label-info': campaign.status === 'enqueued',
                                                  'label-danger': campaign.status === 'error',
                                                  'label-info': campaign.status === 'draft',
                                                  'label-primary': campaign.status === 'scheduled',
                                                  'label-warning': campaign.status === 'checking' }">
                  {{ 'resources.campaigns.status.' + campaign.status | translate }}
                </small>
                <small class="mrg-left-10">{{'resources.campaigns.campaign_id' | translate}}: {{campaign.id}}</small>
                <!-- Histories selector -->
                <div class="row pdd-vertical-10" *ngIf="inputs">
                  <div class="col-md-1 center-vertical">
                    <label for="campaign_history_id">
                      <b>{{'resources.campaigns.filters.history' | translate}}: </b>
                    </label>
                  </div>
                  <div class="col-md-2">
                    <app-question [question]="inputs[0]" [form]="form" (multiselectChanged)="filterByHistory($event)"></app-question>
                  </div>
                </div>
                <!-- -->
              </div>
              <ul class="nav nav-tabs" role="tablist">
                <li *ngIf="campaign.has_been_sent && currentHistory" class="nav-item">
                  <a (click)="refreshUrl('customers')" href="#tab-campaigns-statistics-1" class="nav-link"
                    [ngClass]="{'active' : statisticsUrlContains('customers')}" role="tab" data-toggle="tab"
                    [translate]="'resources.campaigns.tabs.statistics'"></a>
                </li>
                <li *ngIf="voucheableType === 'coupon' && !campaign.reminder" class="nav-item">
                  <a (click)="refreshUrl('coupons')" href="#tab-campaigns-statistics-2" class="nav-link"
                    [ngClass]="{'active' : statisticsUrlContains('coupons')}" role="tab" data-toggle="tab"
                    [translate]="'resources.campaigns.tabs.coupons'"></a>
                </li>
                <li *ngIf="voucheableType === 'message'" class="nav-item">
                  <a (click)="refreshUrl('messages')" href="#tab-campaigns-statistics-3" class="nav-link"
                    [ngClass]="{'active' : statisticsUrlContains('messages')}" role="tab" data-toggle="tab"
                    [translate]="'resources.campaigns.tabs.messages'"></a>
                </li>
              </ul>
            </div>
            <!-- Tabs -->
            <div class="tab-info">
              <!-- Tabs content -->
              <div class="tab-content">
                <!-- Customers -->
                <div *ngIf="campaign.has_been_sent && currentHistory" role="tabpanel" class="tab-pane fade" [ngClass]="{'in active' : statisticsUrlContains('customers')}" id="tab-campaigns-statistics-1">

                  <div class="row" *ngIf="!campaignStats">
                    <app-loader styleClass="col-md-12 pdd-vertical-10" noWrapper />
                  </div>

                  <!-- Boxes only visible after campaignStats are loaded -->
                  <div class="row" *ngIf="campaignStats">

                    <!-- Impacted Box - for all campaign channels -->
                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="loadTag('impacted')"
                          [ngClass]=" {'bg-primary' : isTagSelected('impacted'),
                                      'bg-info': !isTagSelected('impacted')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase font-size-13 text-white text-center">
                                <b [translate]="'resources.campaigns.statistics.impacts'"></b>
                              </p>
                              <h1 class="no-mrg-top font-size-30 text-white text-center">
                                {{campaignStats.impacted}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="hasControlGroup">
                      <div class="card pointer"
                          (click)="loadTag('not_impacted')"
                          [ngClass]=" {'bg-primary' : isTagSelected('not_impacted'),
                                      'bg-info': !isTagSelected('not_impacted')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase font-size-13 text-white text-center">
                                <b [translate]="'resources.campaigns.statistics.not_impacted'"></b>
                              </p>
                              <h1 class="no-mrg-top font-size-30 text-white text-center">
                                {{controlGroupStats}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Email campaign - statistics boxes -->
                    <div class="col" *ngIf="campaign.via === 'email'">
                      <div class="card height-90 pointer"
                          (click)="loadTag('email_open')"
                          [ngClass]="{'bg-primary' : isTagSelected('email_open'),
                                      'bg-info': !isTagSelected('email_open')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white font-size-13">
                                <b [translate]="'resources.campaigns.statistics.opened'"></b>
                              </p>
                              <div class="row">
                                <div class="col-md-6">
                                  <h1 class="no-mrg-top  font-size-30">
                                    {{campaignStats.email_opens}}
                                  </h1>
                                  <span class="text-white">
                                    {{'common.total' | translate}}
                                    ({{(campaignStats.email_opens / campaignStats.impacted * 100) | number : '1.2-2'}}%)
                                  </span>
                                </div>
                                <div class="col-md-6">
                                  <h1 class="no-mrg-top font-size-30">
                                    {{campaignStats.email_opens_unique}}
                                  </h1>
                                  <span class="text-white">
                                    {{'common.uniques' | translate}}
                                    ({{(campaignStats.email_opens_unique / campaignStats.impacted * 100) | number : '1.2-2'}}%)
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="campaign.via === 'email'">
                      <div class="card height-90 pointer"
                          (click)="loadTag('email_click')"
                          [ngClass]="{'bg-primary' : isTagSelected('email_click'),
                                      'bg-info': !isTagSelected('email_click')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clicks'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignStats.email_clicks}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="campaign.via === 'email'">
                      <div class="card height-90 pointer"
                          (click)="loadTag('email_error')"
                          [ngClass]="{'bg-primary' : isTagSelected('email_error'),
                                      'bg-info': !isTagSelected('email_error')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.errors'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignStats.email_errors}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="campaign.via === 'email'">
                      <div class="card height-90 pointer"
                          (click)="loadTag('email_unsubscribe')"
                          [ngClass]="{'bg-primary' : isTagSelected('email_unsubscribe'),
                                      'bg-info': !isTagSelected('email_unsubscribe')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.unsubscribes'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignStats.email_unsubscribed}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="campaign.via === 'email' && campaignStats?.excluded_by_contact_policies">
                      <div class="card height-90 pointer"
                          (click)="loadTag('contact_policy')"
                          [ngClass]="{'bg-primary' : isTagSelected('contact_policy'),
                                      'bg-info': !isTagSelected('contact_policy')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.excluded_by_contact_policies'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignStats.excluded_by_contact_policies}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- SMS campaign - statistics boxes -->
                    <div class="col" *ngIf="campaign.via === 'sms'">
                      <div class="card height-90 pointer"
                          (click)="loadTag('sms_error')"
                          [ngClass]="{'bg-primary' : isTagSelected('sms_error'),
                                      'bg-info': !isTagSelected('sms_error')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.errors'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignStats.sms_errors}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="campaign.via === 'sms'">
                      <div class="card height-90 pointer"
                      (click)="loadTag('sms_unsubscribe')"
                          [ngClass]="{'bg-primary' : isTagSelected('sms_unsubscribe'),
                                      'bg-info': !isTagSelected('sms_unsubscribe')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.unsubscribes'"></b>
                              </p>
                              <h1 class="no-mrg-top font-size-30 text-center">
                                {{campaignStats.sms_unsubscribed}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col" *ngIf="campaign.via === 'sms' && campaignStats?.excluded_by_contact_policies">
                      <div class="card height-90 pointer"
                          (click)="loadTag('contact_policy')"
                          [ngClass]="{'bg-primary' : isTagSelected('contact_policy'),
                                      'bg-info': !isTagSelected('contact_policy')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.excluded_by_contact_policies'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignStats.excluded_by_contact_policies}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Customers boxes stats end -->

                  <!-- Stastics customers filter -->
                  <app-data-table-filter *ngIf="customersFilterConfig.filters" [slug]="customersFilterConfig.filterSlug"
                    [questions]="customersFilterConfig.filters" (onSubmitEmitter)="onFilterHandler($event)">
                  </app-data-table-filter>
                  <!-- Stastics customers data table -->
                  <div class="card" *ngIf="currentHistory">
                    <div class="card-block padding-15">
                      <h4 class="card-title font-size-14">{{'resources.campaigns.fields.impacted_customers' | translate}} ({{currentHistory.name}})</h4>
                      <app-data-table #customersDataTable *ngIf="customersTableConfig" [config]="customersTableConfig"></app-data-table>
                    </div>
                  </div>

                  <!-- Control Group customers data table -->
                  <div class="card" id="controlGroupTable" *ngIf="currentHistory && hasControlGroup">
                    <div class="card-block padding-15">
                      <h4 class="card-title font-size-14">{{'resources.campaigns.fields.not_impacted_customers' | translate}} ({{currentHistory.name}})</h4>
                      <app-data-table #controlGroupDataTable *ngIf="controlGroupTableConfig" [config]="controlGroupTableConfig"></app-data-table>
                    </div>
                  </div>
                </div>
                <!-- Coupons tab content -->
                <div *ngIf="voucheableType === 'coupon'" role="tabpanel" class="tab-pane fade"
                  [ngClass]="{'in active' : statisticsUrlContains('coupons')}" id="tab-campaigns-statistics-2">

                  <div class="row" *ngIf="!campaignCouponStats">
                    <app-loader styleClass="col-md-12 pdd-vertical-10" noWrapper />
                  </div>

                  <!-- Boxes only visible after campaignCouponStats are loaded -->
                  <div class="row" *ngIf="campaignCouponStats">

                    <!-- Impacted Box - for all campaign channels -->
                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="getVouchers(null, 'clients_assigned')"
                          [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_assigned'),
                                      'bg-info': !isCouponsTagSelected('clients_assigned')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.total'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{campaignCouponStats.total | number: '1.0-0': 'es'}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="getVouchers('have_been_printed', 'clients_printed')"
                          [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_printed'),
                                      'bg-info': !isCouponsTagSelected('clients_printed')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_printed'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{campaignCouponStats.printed | number: '1.0-0': 'es'}}
                              </h1>
                              <span class="pull-right text-white">
                                {{campaignCouponStats.printed/(campaignCouponStats.total) * 100 | number:  '1.2-2'}} %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="getVouchers('pending', 'clients_pending')"
                          [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_pending'),
                                      'bg-info': !isCouponsTagSelected('clients_pending')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_pending'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{campaignCouponStats.pending | number: '1.0-0': 'es'}}
                              </h1>
                              <span class="pull-right text-white">
                                {{campaignCouponStats.pending/(campaignCouponStats.total) * 100 | number:  '1.2-2'}} %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="getVouchers('redeemed', 'clients_redeemed', true)"
                          [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_redeemed'),
                                      'bg-info': !isCouponsTagSelected('clients_redeemed')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_redeemed'"></b>
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{campaignCouponStats.redeemed | number: '1.0-0': 'es'}}
                              </h1>
                            </div>
                            <div class="col-md-6">
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{campaignCouponStats.unique_redeemed | number: '1.0-0': 'es'}}
                              </h1>
                              <p class="text-uppercase text-white text-center font-size-12 no-mrg-btm">
                                <b [translate]="'resources.campaigns.statistics.clients_unique_redeemed'"></b>
                              </p>
                              <p class="text-center text-white font-size-12">
                                ({{campaignCouponStats.unique_redeemed/(campaignCouponStats.total) * 100 | number:  '1.2-2'}} %)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="getVouchers('sold_out', 'clients_sold_out')"
                          [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_sold_out'),
                                      'bg-info': !isCouponsTagSelected('clients_sold_out')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_sold_out'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{campaignCouponStats.sold_out | number: '1.0-0': 'es'}}
                              </h1>
                              <span class="pull-right text-white">
                                {{campaignCouponStats.sold_out/(campaignCouponStats.total) * 100 | number:  '1.2-2'}} %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90 pointer"
                          (click)="getVouchers('unavailable', 'clients_cancelled')"
                          [ngClass]="{'bg-primary' : isCouponsTagSelected('clients_cancelled'),
                                      'bg-info': !isCouponsTagSelected('clients_cancelled')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_cancelled'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30 pointer">
                                {{ campaignCouponStats.canceled + campaignCouponStats.expired  | number: '1.0-0': 'es'}}
                              </h1>
                              <span class="pull-right text-white">
                                {{campaignCouponStats.expired/(campaignCouponStats.total) * 100 | number: '1.2-2'}} %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end boxes -->
                  </div>

                  <!-- Coupons chart -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-block">
                          <div class="tab-info">
                            <div #vouchersChart></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Coupons table title -->
                  <div class="card" *ngIf="currentHistory && couponsTableTitle">
                    <div class="card-block">
                      <p class="font-size-16 text-centered">
                        <b>{{couponsTableTitle}}</b>
                      </p>
                      <!-- Statistics coupons data table -->
                      <app-data-table #couponsDataTable *ngIf="couponsTableConfig" [config]="couponsTableConfig">
                      </app-data-table>
                    </div>
                  </div>
                </div>

                <!-- Messages tab content -->
                <div *ngIf="voucheableType === 'message'" role="tabpanel" class="tab-pane fade"
                  [ngClass]="{'in active' : statisticsUrlContains('messages')}" id="tab-campaigns-statistics-3">

                  <div class="row" *ngIf="!campaignMessageStats">
                    <app-loader styleClass="col-md-12 pdd-vertical-10" noWrapper />
                  </div>

                  <!-- Boxes only visible after campaignMessageStats are loaded -->
                  <div class="row" *ngIf="campaignMessageStats">

                    <!-- First campaign messages statistics box -->
                    <div class="col">
                      <div class="card height-90"
                          (click)="getMessages(null, 'clients_assigned')"
                          [ngClass]="{'bg-primary': isMessagesTagSelected('clients_assigned'),
                                      'bg-info': !isMessagesTagSelected('clients_assigned')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.total'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{campaignMessageStats.total}}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90"
                          (click)="getMessages('have_been_printed', 'clients_printed')"
                          [ngClass]="{'bg-primary': isMessagesTagSelected('clients_printed'),
                                      'bg-info': !isMessagesTagSelected('clients_printed')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase text-white text-center font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_printed'"></b>
                              </p>
                              <h1 class="no-mrg-top text-white text-center font-size-30">
                                {{ campaignMessageStats.printed }}
                              </h1>
                              <span class="pull-right text-white">
                                {{ campaignMessageStats.printed / (campaignMessageStats.total) * 100 | number: '1.2-2' }}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card height-90"
                          (click)="getMessages('pending', 'clients_pending')"
                          [ngClass]="{'bg-primary': isMessagesTagSelected('clients_pending'),
                                      'bg-info': !isMessagesTagSelected('clients_pending')}">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-md-12">
                              <p class="text-uppercase font-size-13">
                                <b [translate]="'resources.campaigns.statistics.clients_pending'"></b>
                              </p>
                              <h1 class="no-mrg-top font-size-30">
                                {{ campaignMessageStats.pending }}
                              </h1>
                              <span class="pull-right text-white">
                                {{ campaignMessageStats.pending / (campaignMessageStats.total) * 100 | number: '1.2-2' }} %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end messages statistics boxes -->
                  </div>

                  <!-- Messages chart -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-block">
                          <div class="tab-info">
                            <div #vouchersChart></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Messages table title -->
                  <div class="card" *ngIf="currentHistory && messagesTableTitle">
                    <div class="card-block">
                      <p class="font-size-16 text-centered">
                        <b>{{messagesTableTitle}}></b>
                      </p>
                      <!-- Statistics messages data table -->
                      <app-data-table #messagesDataTable *ngIf="messagesTableConfig" [config]="messagesTableConfig">
                      </app-data-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Close fullscreen modal btn -->
        <a class="modal-close margin-10" data-dismiss="modal" (click)="handleCloseModal()">
          <i class="ti-close"></i>
        </a>
      </div>
    </div>
  </div>
</div>
