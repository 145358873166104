import { CheckboxQuestion } from "../models/forms/question-checkbox";
import { RadioQuestion } from "../models/forms/question-radio";
import { TextboxQuestion } from "../models/forms/question-textbox";

export class QuestionFactory {
  constructor() {}

  createCheckboxQuestion(key: string, label: string, value: any, required?: boolean): CheckboxQuestion {
    return new CheckboxQuestion({
      key: key,
      type: 'checkbox',
      cssClasses: 'form-control input-default',
      label: label,
      value: value,
      required: required ?? false
    });
  }

  createRadioQuestion(key: string, options: any[], value: any, required?: boolean): RadioQuestion {
    return new RadioQuestion({
      cssClasses: 'radio-inline radio-info',
      key: key,
      type: 'radio',
      options: options,
      value: value,
      required: required ?? false
    });
  }

  createTextboxQuestion(key: string, type: string, required: boolean, value: any, min?: number, disabled?: boolean): TextboxQuestion {
    return new TextboxQuestion({
      cssClasses: 'form-control input-md',
      key: key,
      type: type,
      required: required ?? false,
      value: value,
      min: min,
      disabled: disabled
    });
  }
}