<div class="pdd-horizon-15" *ngIf="showAlert">
  <div class="alert alert-info alert-dismissible panel fade show no-mrg-btm d-flex"
    role="alert">
    <div class="panel__image d-flex align-items-center pdd-left-15">
      <img [src]="imageUrl" [alt]="imageUrl">
    </div>
    <div class="panel__texts pdd-left-15">
      <h1 class="pdd-right-30">{{titleKey | translate}}</h1>
      <h2 class="pdd-right-30">{{subtitleKey | translate}}</h2>
    </div>
    <div class="panel__learn-more">
      <a target="_blank"
        [href]="learnMoreLink">
        {{'components.info_panel.buttons.learn_more' | translate}}
      </a>
    </div>
    <button type="button" class="close" (click)="closeAlert()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>