<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 *ngIf="!id" [translate]="'resources.scores.loyalty_scores.form.title'"></h1>
            <h1 *ngIf="id" [translate]="'resources.scores.loyalty_scores.form.edit_title'"></h1>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div *ngIf="loyaltyScoresForm && loyaltyScoresForm.errors">
              <div class="alert alert-danger alert-dismissible fade show mrg-top-5"
                *ngFor="let error of getErrors()" role="alert">
                <b>{{error.field}}:</b> {{error.message}}.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                  (click)="removeError(error)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="pdd-vertical-20-but-bottom">
          <button class="modal-close" (click)="returnToList()">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="card-block no-pdd">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="loyaltyScoresForm" *ngIf="loyaltyScoresForm">

                <!-- DETAILS -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.details'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.details' | translate}}</span>
                    </div>
                    <div *ngIf="flags.loyaltyAvailableLanguagesVisibility">
                      <div class="form-group">
                        <app-language-accordions [inputs]="inputs"
                                                 [form]="loyaltyScoresForm"
                                                 [mode]="mode"
                                                 [filteredAvailableLanguages]="filteredAvailableLanguages">
                        </app-language-accordions>
                      </div>
                    </div>

                    <ng-container *ngIf="!flags.loyaltyAvailableLanguagesVisibility">
                      <div class="row mrg-top-15">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="text-bold" for="name" [translate]="'resources.scores.loyalty_scores.fields.name'"></label>
                            <span class="text-danger">*</span>
                            <app-question [question]="getInputConfig('name')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="description" class="text-bold"
                              [translate]="'resources.scores.loyalty_scores.fields.description'"></label>
                            <app-question [question]="getInputConfig('description')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!--  -->

                <!-- POINTS VALUE -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.points_value'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.points_value' | translate}}</span>
                    </div>
                    <div class="row mrg-top-15">
                      <div class="col-md-12 no-pdd-btm">
                        <div class="form-group">
                          <label class="text-bold" for="loyalty_score_moment">
                            {{'resources.scores.loyalty_scores.fields.when' | translate}}
                            <span class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('loyalty_score_moment')"
                                        [form]="loyaltyScoresForm"
                                        [disabled]="isUsed"
                                        (multiselectChanged)="momentChanged($event)">
                          </app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="accumulation_type">
                            {{'resources.scores.loyalty_scores.fields.accumulation_type' | translate}}
                            <span class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('strategy_type')"
                                        [form]="loyaltyScoresForm"
                                        (multiselectChanged)="strategySelectorChange($event)">
                          </app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row flex align-items-center">
                      <div class="col-md-4" *ngIf="strategyFlags.showPoints">
                        <div class="form-group">
                          <label *ngIf="strategyFlags.showLabelPoints" for="points" class="text-bold">
                            {{'resources.scores.loyalty_scores.fields.amount_to_spend' | translate}}
                            <span class="text-danger">*</span>
                          </label>
                          <label *ngIf="strategyFlags.showLabelPriceMultiplicator" for="points_price_multiplicator" class="text-bold">
                            {{'resources.scores.loyalty_scores.fields.points_amount' | translate}}
                            <span class="text-danger">*</span>
                          </label>
                          <label *ngIf="hasFormKeyWithValue('strategy_type', 'percentage')" for="points_percentage" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.points_percentage'"></label>
                          <app-question [question]="getInputConfig('points')" [form]="loyaltyScoresForm" [disabled]="strategyFlags.disablePoints"></app-question>
                          <app-question *ngIf="hasFormKeyWithValue('strategy_type', 'percentage')" [question]="getInputConfig('points_percentage')" [form]="loyaltyScoresForm" [disabled]="isUsed"></app-question>
                        </div>
                      </div>
                      <div class="col-md-4" *ngIf="hasFormKeyWithValue('strategy_type', 'percentage')">
                        <div class="form-group">
                          <label for="points_percentage" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.points_percentage'"></label>
                          <app-question [question]="getInputConfig('points_percentage')" [form]="loyaltyScoresForm" [disabled]="isUsed"></app-question>
                        </div>
                      </div>
                      <div class="col-lg-1" *ngIf="strategyFlags.symbol">
                        <span class="grant-score-type flex justify-content-center align-items-center font-size-16">
                          {{ strategyFlags.symbol }}
                        </span>
                      </div>
                      <div class="col-md-4" *ngIf="strategyFlags.showPointsPriceMultiplicator">
                        <div class="form-group">
                          <label class="text-bold" *ngIf="!strategyFlags.showLabelPointsMultiplicator" for="points_price_multiplicator"
                                 [translate]="'resources.scores.loyalty_scores.fields.points_amount'">
                          </label>
                          <label *ngIf="strategyFlags.showLabelPointsMultiplicator" for="points_price_multiplicator"
                                 [translate]="'resources.scores.loyalty_scores.fields.points_multiplicator'">
                          </label>
                          <app-question [question]="getInputConfig('points_price_multiplicator')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3 paddtop2em" *ngIf="strategyFlags.showTransferablePoints">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('transferable')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->

                <!-- VICTORY CONDITIONS -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.victory'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.victory_conditions' | translate}}</span>
                    </div>

                    <!-- CUSTOM ENTITY DEFINITION FORM -->
                    <ng-container *ngIf="customEntity" #customEntityForm>
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.custom_entity_title' | translate}}</h5>
                      <app-form-loyalty-scores-custom-entity [entity]="customEntity"
                                                             [definedEntityAttrs]="loyaltyScoresData ? loyaltyScoresData.customEntityAttributes : []">
                      </app-form-loyalty-scores-custom-entity>
                    </ng-container>
                    <!-- END - CUSTOM ENTITY DEFINITION FORM -->

                    <!-- CHALLENGES -->
                    <div class="row" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.challenges' | translate}}
                            <span class="text-danger">&nbsp;*</span>
                          </h5>
                          <label class="text-bold" for="achievement_definition_challenge_ids">
                            {{'resources.scores.loyalty_scores.fields.achievement_definition_challenge_ids_input' | translate}}
                          </label>
                          <app-question [question]="getInputConfig('achievement_definition_challenge_ids')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- COUNTRIES -->
                    <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.country_title' | translate}}</h5>
                    <!-- WARNING MESSAGE -->
                    <div class="alert alert-danger" role="alert" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.warnings.registration_countries' | translate}}</span>
                    </div>
                    <!--  -->
                    <div class="row">
                      <div class="col-md-6" [hidden]="hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge') || isCustomEntity">
                        <div class="form-group">
                          <label class="text-bold" for="countries">
                            {{'resources.scores.loyalty_scores.fields.countries' | translate}}
                            <span class="text-danger">*</span>
                            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                                tooltipPosition="top"
                                pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.countries_tooltip' | translate}}">
                            </em>
                          </label>
                          <app-question [question]="getInputConfig('countries')"
                                        [form]="loyaltyScoresForm"
                                        (multiselectChanged)="countrySelector()">
                          </app-question>
                        </div>
                      </div>

                      <div class="col-md-6" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity') ||
                                                   hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge') ||
                                                   isCustomEntity">
                        <div class="form-group">
                          <label class="text-bold" for="customer_registration_countries">
                            {{'resources.scores.loyalty_scores.fields.customer_registration_countries' | translate}}
                            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                                tooltipPosition="top"
                                pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.customer_registration_tooltip' | translate}}">
                            </em>
                          </label>
                          <app-question [question]="getInputConfig('customer_registration_countries')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                    </div>

                    <!-- TIERS -->
                    <div class="row" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity') ||
                                            hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.tiers' | translate}}</h5>
                          <label class="text-bold" for="achievement_definition_ids">
                            {{'resources.scores.loyalty_scores.fields.achievement_definition_ids_input' | translate}}
                          </label>
                          <app-question [question]="getInputConfig('achievement_definition_ids')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- LOCATIONS -->
                    <div *ngIf="!hasFormKeyWithValue('loyalty_score_moment', 'birthday') &&
                                !hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge') &&
                                !isCustomEntity">
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.locations_title' | translate}}</h5>

                      <div class="row">
                        <div class="col-md-6">
                          <label for="locations" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.locations'"></label>
                          <div class="form-group">
                            <app-question [question]="getInputConfig('locationsOptions')" [form]="loyaltyScoresForm" (multiselectChanged)="resetOptionsValues('location_ids')"></app-question>
                          </div>
                        </div>
                        <div class="col-md-6 top-28" *ngIf="hasFormKeyWithValue('locationsOptions', 'include') || hasFormKeyWithValue('locationsOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('location_ids')"
                                          [form]="loyaltyScoresForm"
                                          (blur)="handleLocationChange($event)">
                            </app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- LOCATIONS FEATURES -->
                    <ng-container *ngIf="!hasFormKeyWithValue('loyalty_score_moment', 'birthday') && !isCustomEntity && !hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <div class="row">
                        <label for="feature_location_ids" class="mrg-left-15 text-bold" [translate]="'resources.scores.loyalty_scores.fields.location_features'"></label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('locationFeaturesOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('feature_location_ids')">
                            </app-question>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="hasFormKeyWithValue('locationFeaturesOptions', 'include') ||
                                                     hasFormKeyWithValue('locationFeaturesOptions', 'include_all') ||
                                                     hasFormKeyWithValue('locationFeaturesOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('feature_location_ids')"
                                          [form]="loyaltyScoresForm">
                            </app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!--  -->

                    <!-- PRODUCTS -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.products_title' | translate}}</h5>

                      <div class="row">
                        <div class="col-md-6">
                          <label for="features" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.products'"></label>
                          <div class="form-group">
                            <app-question [question]="getInputConfig('productsOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('product_ids')">
                            </app-question>
                          </div>
                        </div>

                        <!-- SEARCH BY FIELD -->
                        <div class="col-md-6" *ngIf="hasFormKeyWithValue('productsOptions', 'include') || hasFormKeyWithValue('productsOptions', 'exclude')">
                          <label for="search_by" class="text-bold"
                                  [translate]="'resources.scores.loyalty_scores.fields.search_by'">
                          </label>
                          <div class="form-group">
                            <app-question
                              [question]="getInputConfig('search_by')"
                              [form]="loyaltyScoresForm"
                              (multiselectChanged)="searchByResource($event)">
                          </app-question>
                          </div>
                        </div>
                        <!--  -->

                        <div class="col-md-12" *ngIf="hasFormKeyWithValue('productsOptions', 'include') ||
                                                      hasFormKeyWithValue('productsOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('product_ids')"
                                          [form]="loyaltyScoresForm"
                                          [useToken]="true">
                            </app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- PRODUCTS FEATURES -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <div class="row">
                        <label for="features" class="text-bold mrg-left-15"
                               [translate]="'resources.scores.loyalty_scores.fields.product_features'">
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('productsFeaturesOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('feature_ids')">
                            </app-question>
                          </div>
                        </div>
                        <div class="col-md-6"
                          *ngIf="hasFormKeyWithValue('productsFeaturesOptions', 'include') || hasFormKeyWithValue('productsFeaturesOptions', 'include_all') || hasFormKeyWithValue('productsFeaturesOptions', 'exclude') ">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('feature_ids')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- PAYMENTS RESTRICTIONS -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <h5 class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.payment_title'"></h5>
                      <div class="row">
                        <label for="payment_method_ids" class="text-bold mrg-left-15"
                                   [translate]="'resources.scores.loyalty_scores.fields.payment_options'">
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('paymentsOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('payment_method_ids')"></app-question>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="hasFormKeyWithValue('paymentsOptions', 'include') ||
                                                     hasFormKeyWithValue('paymentsOptions', 'include_all') ||
                                                     hasFormKeyWithValue('paymentsOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('payment_method_ids')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- LINE PROPERTIES -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.custom_properties' | translate}}</h5>
                      <div class="row">
                        <label for="lineConditions" class="text-bold mrg-left-15"
                               [translate]="'resources.scores.loyalty_scores.fields.line_conditions'">
                        </label>
                      </div>
                      <table class="table no-mrg-bottom" aria-hidden="true">
                        <tr *ngFor="let item of motivator_line_attributes; let i = index" [attr.hidden]="item._destroy">
                          <td class="col-xs-2">{{item.field_name}}</td>
                          <td class="col-xs-1 text-center">{{item.condition_name}}</td>
                          <td class="col-xs-8 text-right">
                            <span class="label">{{item.value}}</span>
                          </td>
                          <td class="col-xs-1 text-right">
                            <em class="fa fa-times pointer" (click)="deleteMotivatorLine(i)">
                            </em>
                          </td>
                        </tr>
                      </table>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('lineConditions')" (multiselectChanged)="customerLineConditionChange()"
                              [form]="loyaltyScoresForm">
                            </app-question>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('lineOptions')" (multiselectChanged)="lineOptionsChange($event)" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('lineInput')" [disabled]="lineInputDisabled" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-success btn-icon no-margin-right pull-right"
                            (click)="addMotivatorLine()"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- CUSTOMER CONDITIONS TO WIN -->
                    <div *ngIf="!hasFormKeyWithValue('loyalty_score_moment', 'manual')
                                && !hasFormKeyWithValue('loyalty_score_moment', 'birthday')
                                && !hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')
                                && !isCustomEntity">
                    <h5 class="text-bold card-title"></h5>
                      <div class="row">
                        <label for="customerConditions" class=" text-bold mrg-left-15"
                               [translate]="'resources.scores.loyalty_scores.fields.customer_conditions'">
                        </label>
                      </div>
                      <table class="table no-mrg-bottom" aria-hidden="true">
                        <tr *ngFor="let item of customer_attributes; let i = index" [attr.hidden]="item._destroy">
                          <td class="col-xs-2">{{item.field_name}}</td>
                          <td class="col-xs-1 text-center">
                            {{ 'resources.scores.loyalty_scores.conditions.' + item.condition | translate }}
                          </td>
                          <td class="col-xs-8 text-right">
                            <span class="label">{{item.value_content}}</span>
                          </td>
                          <td class="col-xs-1 text-right">
                            <em class="fa fa-times pointer" (click)="deleteCustomerAttribute(i)">
                            </em>
                          </td>
                        </tr>
                      </table>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerConditions')"
                              (multiselectChanged)="customerConditionChange($event)" [form]="loyaltyScoresForm">
                            </app-question>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerOptions')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="items && items.multiple">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerSelectMulti')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="items && !items.multiple">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerInput')" [form]="loyaltyScoresForm"></app-question>
                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="!items">
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-success btn-icon no-margin-right pull-right"
                            (click)="addCustomerAttributeLine()">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- END VICTORY CONDITIONS -->

                <!-- LIMITS -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.limits'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.limits' | translate}}</span>
                    </div>
                    <!-- CHANCES TO WIN -->
                    <div class="row">
                      <label for="chancesToWin" class="text-bold mrg-left-15"
                        [translate]="'resources.scores.loyalty_scores.fields.chances_to_win'"></label>
                    </div>
                    <div class="row flex-end">
                      <div class="col-md-6">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('once')" [form]="loyaltyScoresForm"
                                        (multiselectChanged)="chancesToWinSelectorChange($event)"
                                        [disabled]="isUsed">
                          </app-question>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('once_limit')" [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                        </div>
                      </div>
                    </div>
                    <!-- POC APPAREL GROUP  -->
                    <ng-container *ngIf="flags.showMaxNumberVictories">
                      <div class="row">
                        <label for="max_num_victories" class="text-bold mrg-left-15"
                          [translate]="'resources.scores.loyalty_scores.fields.max_num_victories'"></label>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('max_num_victories')" [form]="loyaltyScoresForm"
                                          [disabled]="isUsed">
                            </app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!--  -->
                  </div>
                </div>
                <!-- END LIMITS -->

                <!-- VALIDITY OF POINTS AWARDED -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.once'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.validity_points_awarded' | translate}}</span>
                    </div>
                    <h5 class="text-bold mrg-top-10" [translate]="'resources.scores.loyalty_scores.fields.activation'"></h5>
                    <div class="row mrg-top-10">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="text-bold" for="activation_delay">
                            {{'resources.scores.loyalty_scores.fields.activation_delay' | translate}}
                            <span class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('activation_delay')" [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                        </div>
                      </div>

                      <div class="col-md-4 mrg-top-7">
                        <div class="form-group">
                          <label class="text-bold" for="activation_delay_unit"></label>
                          <app-question [question]="getInputConfig('activation_delay_unit')" [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                        </div>
                      </div>

                      <div class="col-md-4 mrg-top-35">
                        <div class="form-group" *ngIf="hasFormKeyWithValue('activation_delay_unit', 'months')"
                          style="display: inline-flex">
                          <app-question [question]="getInputConfig('activation_delay_beginning_month')"
                                        [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                          <span class="mrg-top-5" [translate]="'resources.scores.loyalty_scores.fields.activation_delay_beginning_month'"></span>
                        </div>
                      </div>
                    </div>

                    <h5 class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.expiration'"></h5>
                    <div class="row height-50">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('expiration_period_type')"
                                        [form]="loyaltyScoresForm">
                          </app-question>
                        </div>
                      </div>
                    </div>

                    <!-- IF RELATIVE PERIOD SELECTED -->
                    <div class="row" *ngIf="hasFormKeyWithValue('expiration_period_type', 'relative')">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="text-bold" for="availability_duration">
                            {{'resources.scores.loyalty_scores.fields.availability_duration' | translate}}
                            <span class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('availability_duration')"
                                        [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                        </div>
                      </div>

                      <div class="col-md-4 mrg-top-7">
                        <div class="form-group">
                          <label class="text-bold" for="availability_duration_unit"></label>
                          <app-question [question]="getInputConfig('availability_duration_unit')"
                                        [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                        </div>
                      </div>

                      <div class="col-md-4 mrg-top-35">
                        <div class="form-group" *ngIf="hasFormKeyWithValue('availability_duration_unit', 'months')"
                          style="display: inline-flex">
                          <app-question [question]="getInputConfig('availability_duration_natural')"
                                        [form]="loyaltyScoresForm"
                                        [disabled]="isUsed">
                          </app-question>
                          <span class="mrg-top-5" [translate]="'resources.scores.loyalty_scores.fields.availability_duration_natural'"></span>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- DISPLAY IF FIXED PERIOD SELECTED -->
                    <div class="row" *ngIf="hasFormKeyWithValue('expiration_period_type', 'fixed')">
                      <div class="col-md-4 mrg-top-10">
                        <div class="form-group">
                          <label class="text-bold" for="fixed_option_selected"
                            [translate]="'resources.scores.loyalty_scores.fields.expiration_occurs'"></label>
                          <app-question [question]="getInputConfig('fixed_option_selected')"
                                        [form]="loyaltyScoresForm"
                                        [disabled]="isUsed"
                                        (multiselectChanged)="displayExpirationDate($event)">
                          </app-question>
                          <ng-container *ngIf="expirationDate">
                            <i class="ti-info-alt mrg-top-5"></i>&nbsp;
                            <span [innerHTML]="'resources.scores.loyalty_scores.fields.expires' | translate: {expirationDate: expirationDate}"></span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->

                <!-- VALIDITY OF LOYALTY RULE -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.loyalty_policy'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.validity_points_policy' | translate}}</span>
                    </div>
                    <div class="row mrg-top-15">
                      <div class="col">
                        <div class="form-group">
                          <label class="text-bold" for="available_from"
                            [translate]="'resources.scores.loyalty_scores.fields.available_from'"></label>
                          <app-question [question]="getInputConfig('available_from')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="text-bold" for="available_to"
                            [translate]="'resources.scores.loyalty_scores.fields.available_to'"></label>
                          <app-question [question]="getInputConfig('available_to')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="text-bold" for="active">
                            <span>{{'resources.scores.loyalty_scores.fields.status' | translate}}</span>
                            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                                tooltipPosition="top"
                                pTooltip="{{'resources.scores.common_messages.status_tooltip' | translate}}">
                            </em>
                          </label>
                          <app-question [question]="getInputConfig('active')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!--  -->

                <!-- Partners -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.partners.title'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.partners' | translate}}</span>
                    </div>
                    <div class="row mrg-top-15">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="partner_id"
                            [translate]="'resources.scores.loyalty_scores.fields.partner_id'"></label>
                          <app-question [question]="getInputConfig('partner_id')" [form]="loyaltyScoresForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->

              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <span>
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
          <button class="btn btn-info no-mrg-right"
                  (click)="save()"
                  [translate]="'components.create-update.fields.save'"
                  [disabled]="loading">
          </button>
        </div>
      </div>
      <!--  -->

    </div>
  </div>
</div>
