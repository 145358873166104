import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PlanRoiCampaignGroup, PlanRoiGlobalTotal, EmbeddedCampaign, PlanRoiCampaignDetailAction } from '../plan-roi-campaign-group';
import { Flag } from "../../../../shared/services/feature-flags.service";
import { CONTROL_GROUP_SIGNIFICANCE } from "../../../../shared/models/campaign/campaign";

@Injectable()
export class PlanRoiDetailService {

  currencySymbol = '€';

  constructor(
    private decimalPipe: DecimalPipe,
    private translate: TranslateService
  ) {}

  getColumnList(currencySymbol = '€', flags?: { [name in Flag]: any }): TableColumn[] {
    this.currencySymbol = currencySymbol;

    const baseColumns = [
      {
        id: 'campaign_type',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_type'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_type')),
        visible: true,
        disabled: true
      },
      {
        id: 'campaign_name',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_name'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_name')),
        visible: true,
        disabled: true
      },
      {
        id: 'campaign_id',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_id'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_id')),
        visible: true,
        disabled: true
      },
      {
        id: 'segment_name',
        headerTitle: this.translate.instant('resources.plans.columns.segment_name'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.segment_name')),
        visible: true,
        disabled: true
      },
      {
        id: 'plans_reminders',
        headerTitle: this.translate.instant('resources.plans.columns.plans_reminders'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.plans_reminders')),
        visible: true
      },
      {
        id: 'customers_count',
        headerTitle: this.translate.instant('resources.plans.columns.customers_count'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.customers_count')),
        visible: true
      },
      {
        id: 'control_group',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.control_group'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.control_group')),
        visible: true
      },
      {
        id: 'delivered_coupons',
        headerTitle: this.translate.instant('resources.plans.columns.delivered_coupons'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.delivered_coupons')),
        visible: true
      },
      {
        id: 'delivered_percentage',
        headerTitle: this.translate.instant('resources.plans.columns.delivered_percentage'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.delivered_percentage')),
        visible: true
      },
      {
        id: 'redemptions',
        headerTitle: this.translate.instant('resources.plans.columns.redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.redemptions')),
        visible: true
      },
      {
        id: 'customer_redemptions',
        headerTitle: this.translate.instant('resources.plans.columns.customer_redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.customer_redemptions')),
        visible: true
      },
      {
        id: 'redemptions_percentage',
        headerTitle: this.translate.instant('resources.plans.columns.redemptions_percentage'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.redemptions_percentage')),
        visible: true
      },
      {
        id: 'unique_redemptions',
        headerTitle: this.translate.instant('resources.plans.columns.unique_redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.unique_redemptions')),
        visible: true
      },
      {
        id: 'budget',
        headerTitle: this.translate.instant('resources.plans.columns.budget', {currencySymbol: currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.budget', {currencySymbol: currencySymbol})),
        visible: true
      },
      {
        id: 'cost_vs_budget',
        headerTitle: this.translate.instant('resources.plans.columns.cost_vs_budget', {currencySymbol: currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.cost_vs_budget', {currencySymbol: currencySymbol})),
        visible: true
      },
      {
        id: 'discount_cost',
        headerTitle: this.translate.instant('resources.plans.columns.discount_cost', {currencySymbol: currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.discount_cost', {currencySymbol: currencySymbol})),
        visible: true
      },
      {
        id: 'coupon_id',
        headerTitle: this.translate.instant('resources.plans.columns.coupon_id'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.coupon_id')),
        visible: true
      },
      {
        id: 'budget_consumed',
        headerTitle: this.translate.instant('resources.plans.columns.budget_consumed'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.budget_consumed')),
        visible: true
      },
      {
        id: 'forecast_budget_consumed',
        headerTitle: this.translate.instant('resources.plans.columns.forecast_budget_consumed', {currencySymbol: currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.forecast_budget_consumed', {currencySymbol: currencySymbol})),
        visible: true
      },
      {
        id: 'budget_delivered_vs_forecast',
        headerTitle: this.translate.instant('resources.plans.columns.budget_delivered_vs_forecasted', {currencySymbol: currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.budget_delivered_vs_forecasted', {currencySymbol: currencySymbol})),
        visible: true
      },
      {
        id: 'lg_cost',
        headerTitle: this.translate.instant('resources.plans.columns.lg_cost'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.lg_cost')),
        visible: true
      },
      {
        id: 'inc_sales',
        headerTitle: this.translate.instant('resources.plans.columns.inc_sales'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.inc_sales')),
        visible: true
      },
      {
        id: 'inc_sales_ratio',
        headerTitle: this.translate.instant('resources.plans.columns.inc_sales_ratio'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.inc_sales_ratio')),
        visible: true
      }
    ];

    const channelColumns = [
      {
        id: 'duration',
        headerTitle: this.translate.instant('resources.plans.columns.duration'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.duration')),
        visible: true,
        disabled: false
      },
      {
        id: 'significance',
        headerTitle: this.translate.instant('resources.plans.columns.significance'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.significance')),
        visible: true,
        disabled: false
      },
      {
        id: 'exhausted_vouchers',
        headerTitle: this.translate.instant('resources.plans.columns.exhausted_vouchers'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.exhausted_vouchers')),
        visible: true,
        disabled: false
      },
      {
        id: 'exhausted_vouchers_ratio',
        headerTitle: this.translate.instant('resources.plans.columns.exhausted_vouchers_ratio'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.exhausted_vouchers_ratio')),
        visible: true,
        disabled: false
      },
      {
        id: 'total_vouchers_delivered',
        headerTitle: this.translate.instant('resources.plans.columns.total_vouchers_delivered'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.total_vouchers_delivered')),
        visible: true,
        disabled: false
      },
      {
        id: 'redemptions_from_total_vouchers_delivered',
        headerTitle: this.translate.instant('resources.plans.columns.redemptions_from_total_vouchers_delivered'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.redemptions_from_total_vouchers_delivered')),
        visible: true,
        disabled: false
      },
      {
        id: 'forecasted_incremental_sales',
        headerTitle: this.translate.instant('resources.plans.columns.forecasted_incremental_sales'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.forecasted_incremental_sales')),
        visible: true,
        disabled: false
      },
      {
        id: 'forecasted_incremental_ratio',
        headerTitle: this.translate.instant('resources.plans.columns.forecasted_incremental_ratio'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.forecasted_incremental_ratio')),
        visible: true,
        disabled: false
      },
      {
        id: 'incremental_sales_vs_estimation',
        headerTitle: this.translate.instant('resources.plans.columns.incremental_sales_vs_estimation'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.incremental_sales_vs_estimation')),
        visible: true,
        disabled: false
      },
      {
        id: 'incremental_ratio_vs_estimation',
        headerTitle: this.translate.instant('resources.plans.columns.incremental_ratio_vs_estimation'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.incremental_ratio_vs_estimation')),
        visible: true,
        disabled: false
      },
      {
        id: 'channel',
        headerTitle: this.translate.instant('resources.plans.columns.channel'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.channel')),
        visible: true,
        disabled: false
      },
      {
        id: 'delivery_cost',
        headerTitle: this.translate.instant('resources.plans.columns.delivery_cost',  {currencySymbol: currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.delivery_cost', {currencySymbol: currencySymbol})),
        visible: true,
        disabled: false
      },
      {
        id: 'email_delivered',
        headerTitle: this.translate.instant('resources.plans.columns.email_delivered'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.email_delivered')),
        visible: true,
        disabled: false
      },
      {
        id: 'email_or_total',
        headerTitle: this.translate.instant('resources.plans.columns.email_or_total'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.email_or_total')),
        visible: true,
        disabled: false
      },
      {
        id: 'email_clicks',
        headerTitle: this.translate.instant('resources.plans.columns.email_clicks'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.email_clicks')),
        visible: true,
        disabled: false
      },
      {
        id: 'email_errors',
        headerTitle: this.translate.instant('resources.plans.columns.email_errors'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.email_errors')),
        visible: true,
        disabled: false
      },
      {
        id: 'redemption_vs_or',
        headerTitle: this.translate.instant('resources.plans.columns.redemption_vs_or'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.redemption_vs_or')),
        visible: true,
        disabled: false
      },
      {
        id: 'sms_delivered',
        headerTitle: this.translate.instant('resources.plans.columns.sms_delivered'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.sms_delivered')),
        visible: true,
        disabled: false
      },
      {
        id: 'redemption_vs_sms_delivered',
        headerTitle: this.translate.instant('resources.plans.columns.redemption_vs_sms_delivered'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.redemption_vs_sms_delivered')),
        visible: true,
        disabled: false
      },
      {
        id: 'sms_errors',
        headerTitle: this.translate.instant('resources.plans.columns.sms_errors'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.sms_errors')),
        visible: true,
        disabled: false
      },
      {
        id: 'sms_unsubscribe',
        headerTitle: this.translate.instant('resources.plans.columns.sms_unsubscribe'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.sms_unsubscribe')),
        visible: true,
        disabled: false
      },
      {
        id: 'net_incremental_ratio',
        headerTitle: this.translate.instant('resources.plans.columns.net_incremental_ratio'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.net_incremental_ratio')),
        visible: true,
        disabled: false
      },
      {
        id: 'reminder_cost',
        headerTitle: this.translate.instant('resources.plans.columns.reminder_cost'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.reminder_cost')),
        visible: true,
        disabled: false
      },
      {
        id: 'discount_type',
        headerTitle: this.translate.instant('resources.coupons.columns.discount'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.discount')),
        visible: true,
        disabled: false
      },
      {
        id: 'max_units',
        headerTitle: this.translate.instant('resources.coupons.columns.max_units'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.max_units')),
        visible: true,
        disabled: false
      },
      {
        id: 'min_units',
        headerTitle: this.translate.instant('resources.coupons.columns.min_units'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.min_units')),
        visible: true,
        disabled: false
      },
      {
        id: 'max_money',
        headerTitle: this.translate.instant('resources.coupons.columns.max_money'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.max_money')),
        visible: true,
        disabled: false
      },
      {
        id: 'min_money',
        headerTitle: this.translate.instant('resources.coupons.columns.min_money'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.min_money')),
        visible: true,
        disabled: false
      },
      {
        id: 'affectation',
        headerTitle: this.translate.instant('resources.coupons.columns.affectation'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.affectation')),
        visible: true,
        disabled: false
      },
      {
        id: 'max_redemptions',
        headerTitle: this.translate.instant('resources.coupons.columns.max_redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.max_redemptions')),
        visible: true,
        disabled: false
      },
      {
        id: 'accumulative',
        headerTitle: this.translate.instant('resources.coupons.columns.accumulative'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.accumulative')),
        visible: true,
        disabled: false
      },
      {
        id: 'auto_selected',
        headerTitle: this.translate.instant('resources.coupons.columns.auto_selected'),
        plainTitle: this.normalizeString(this.translate.instant('resources.coupons.columns.auto_selected')),
        visible: true,
        disabled: false
      }
    ];

    const actionsFixedColumn = [
      {
        id: 'campaign_actions',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_actions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_actions')),
        disabled: true,
        visible: true
      }
    ];

    return flags.showPlanROIChannelColumns ?
      [...baseColumns, ...channelColumns, ...actionsFixedColumn] :
      [...baseColumns, ...actionsFixedColumn];
  }

  /**
   * Converts the provided data into a CSV format.
   *
   * @param {TableColumn[]} columns - The columns for the CSV file.
   * @param {PlanRoiCampaignGroup[]} planRoiData - The data to be converted into CSV format.
   * @param {DecimalPipe} decimalPipe - The pipe to format numbers.
   * @param {Object} totalValues - The total values to be included in the CSV.
   *
   * @returns {string} The data in CSV format.
   */
  dataToCSV(
    columns: TableColumn[],
    planRoiData: PlanRoiCampaignGroup[],
    totalValues: PlanRoiGlobalTotal,
    flags: { [name in Flag]: any }
  ): string {
    const location = 'es';
    let stringCsv = '';
    columns.forEach( tableColumn => {
      if ( tableColumn.visible ) { stringCsv += tableColumn.plainTitle + ';' };
    } );
    stringCsv += '\n';

    planRoiData.forEach((detail, i) => {
      detail.campaigns.forEach((campaign) => {
        campaign.actions.forEach((action) => {
          columns.forEach(column => {
            if (column.visible) {
              stringCsv += this.getTransFormedValue(column.id, detail, campaign, action, location);
            }
          });
          stringCsv += '\n';
        });
        // CAMPAIGN TOTAL ROW
        for (let i = 0; i <= 21; i++) {
          if (columns[i].visible) {
            stringCsv += this.getCampaignTotalTransformedValue(i, detail, campaign, location);
          }
        }
        stringCsv += '"";'.repeat(18);
        // END CAMPAIGN TOTAL ROW
        stringCsv += '\n';
      });

      // CATEGORY SUBTOTAL ROW
      for (let i = 0; i <= 21; i++) {
        if (columns[i].visible) {
          stringCsv += this.getCategorySubtotalTransformedValue(i, detail, location);
        }
      }
      stringCsv += '"";'.repeat(18);
      // END CATEGORY SUBTOTAL ROW
      stringCsv += '\n';
    });

    // OVERALL TOTAL ROW
    for (let i = 0; i <= 21; i++) {
      if (columns[i].visible) {
        stringCsv += this.getOverallTotalTransformedValue(i, totalValues, location);
      }
    }
    stringCsv += '"";'.repeat(19);
    // END OVERALL TOTAL ROW
    stringCsv += '\n';
    return stringCsv;
  }

  private normalizeString(str: string) {
    return str.replace('<br>', '');
  }

  private getTransFormedValue(
    colId: string,
    detail: PlanRoiCampaignGroup,
    campaign: EmbeddedCampaign,
    action: PlanRoiCampaignDetailAction,
    location: string
  ): string {
    const valueFunctions = {
      campaign_type: () => detail.category + ';',
      campaign_name: () => campaign.name + ';',
      campaign_id: () => action.campaignId + ';',
      segment_name: () => action.segmentName + ';',
      plans_reminders: () => this.decimalPipe.transform(action.reminderIds.length) + ';',
      customers_count: () => this.decimalPipe.transform(action.customersCount, '1.0-0', location) + ';',
      control_group: () => this.decimalPipe.transform(action.controlGroup, '1.0-0', location) + ';',
      delivered_coupons: () => this.decimalPipe.transform(action.deliveredCoupons, '1.0-0', location) + ';',
      delivered_percentage: () => this.decimalPipe.transform((action.deliveredPercentage * 100), '1.2-2') + ';',
      redemptions: () => this.decimalPipe.transform(action.redemptions, '1.0-0', location) + ';',
      customer_redemptions: () => this.decimalPipe.transform(action.customerRedemptions, '1.2-2', location) + ';',
      redemptions_percentage: () => this.decimalPipe.transform((action.redemptionsPercentage * 100), '1.2-2') + ';',
      unique_redemptions: () => this.decimalPipe.transform((action.uniqueRedemptionsPercentage * 100), '1.2-2') + ';',
      budget: () => this.decimalPipe.transform(action.budgetExpense, '1.2-2', location) + ';',
      cost_vs_budget: () => this.decimalPipe.transform(action.costVsBudget, '1.2-2', location) + ';',
      discount_cost: () => this.decimalPipe.transform(action.discountCost, '1.2-2', location) + ';',
      coupon_id: () => action.embeddedCampaign.couponId + ';',
      budget_consumed: () => this.decimalPipe.transform(action.budgetConsumed * 100, '1.2-2', location) + ';',
      forecast_budget_consumed: () => this.decimalPipe.transform(action.forecastBudgetConsumed, '1.2-2', location) + ';',
      budget_delivered_vs_forecast: () => this.decimalPipe.transform(action.budgetDeliveredVsForecasted * 100, '1.2-2', location) + ';',
      lg_cost: () => (detail.type === 'Plans::SupplierCampaign' ? this.decimalPipe.transform(action.lgCost, '1.2-2', location) : this.translate.instant('common.fields.na')) + ';',
      inc_sales: () => this.decimalPipe.transform(action.incSales, '1.2-2', location) + ';',
      inc_sales_ratio: () => this.decimalPipe.transform(action.incRatio, '1.2-2', location) + ';',
      duration: () => this.decimalPipe.transform(action.duration, '1.2-2', location ) + ';',
      significance: () => {
        return Number(action.significance) > CONTROL_GROUP_SIGNIFICANCE ? this.translate.instant('common.yes') : this.translate.instant('common.no') + ';'
      },
      exhausted_vouchers: () => this.decimalPipe.transform(action.exhaustedVouchers, '1.2-2', location ) + ';',
      exhausted_vouchers_ratio: () => this.decimalPipe.transform(action.exhaustedVouchersRatio * 100, '1.2-2', location ) + ';',
      total_vouchers_delivered: () => this.decimalPipe.transform(action.totalVouchersDelivered, '1.0-0', location ) + ';',
      redemptions_from_total_vouchers_delivered: () => this.decimalPipe.transform(action.redemptionsFromTotalVouchersDelivered * 100, '1.2-2', location ) + ';',
      forecasted_incremental_sales: () => this.decimalPipe.transform(action.forecastedIncrementalSales, '1.2-2', location ) + ';',
      forecasted_incremental_ratio: () => this.decimalPipe.transform(action.forecastedIncrementalRatio, '1.2-2', location ) + ';',
      incremental_sales_vs_estimation: () => this.decimalPipe.transform(action.incrementalSalesVsEstimation, '1.2-2', location ) + ';',
      incremental_ratio_vs_estimation: () => this.decimalPipe.transform(action.incrementalRatioVsEstimation, '1.2-2', location ) + ';',
      channel: () => this.translate.instant('resources.campaigns.channels.' + action.channel) + ';',
      delivery_cost: () => this.decimalPipe.transform(action.deliveryCost, '1.2-2', location) + ';',
      email_delivered: () => this.decimalPipe.transform(action.emailDelivered, '1.0-0', location) + ';',
      email_or_total: () => this.decimalPipe.transform(action.emailOrTotal * 100, '1.2-2', location) + ';',
      email_clicks: () => this.decimalPipe.transform(action.emailClicks, '1.0-0', location) + ';',
      email_errors: () => this.decimalPipe.transform(action.emailErrors, '1.0-0', location) + ';',
      redemption_vs_or: () => this.decimalPipe.transform(action.redemptionsVsOr * 100, '1.2-2', location) + ';',
      sms_delivered: () => this.decimalPipe.transform(action.smsDelivered, '1.0-0', location) + ';',
      redemption_vs_sms_delivered: () => this.decimalPipe.transform(action.redemptionsVsSmsDelivered * 100, '1.2-2', location) + ';',
      sms_errors: () => this.decimalPipe.transform(action.smsErrors, '1.0-0', location) + ';',
      sms_unsubscribe: () => this.decimalPipe.transform(action.smsUnsubscribe, '1.0-0', location) + ';',
      net_incremental_ratio: () => this.decimalPipe.transform(action.netIncrementalRatio, '1.2-2', location) + ';',
      reminder_cost: () => this.decimalPipe.transform(action.reminderCost, '1.2-2', location) + ';',
      discount_type: () => {
        let discount = action.embeddedCampaign.couponRaw['discount'] ?? 0;
        discount = this.decimalPipe.transform(discount, '1.2-2', location)
        const discountType = action.embeddedCampaign.couponRaw['discount_type'] === 'cash' ? this.currencySymbol : '%';
        return `${discount} ${discountType};`;
      },
      max_units: () => this.decimalPipe.transform(action.embeddedCampaign.couponRaw['max_units'], '1.2-2', location) + ';',
      min_units: () => this.decimalPipe.transform(action.embeddedCampaign.couponRaw['min_units'], '1.2-2', location) + ';',
      max_money: () => this.decimalPipe.transform(action.embeddedCampaign.couponRaw['max_money'], '1.2-2', location) + ';',
      min_money: () => this.decimalPipe.transform(action.embeddedCampaign.couponRaw['min_money'], '1.2-2', location) + ';',
      affectation: () => this.translate.instant('resources.coupons.fields.' + action.embeddedCampaign.couponRaw['affectation']) + ';',
      max_redemptions: () => this.decimalPipe.transform(action.embeddedCampaign.couponRaw['max_redemptions'], '1.2-2', location) + ';',
      accumulative: () => action.embeddedCampaign.couponRaw['cumulative'] ? this.translate.instant('common.yes') + ';' : this.translate.instant('common.no') + ';',
      auto_selected: () => action.embeddedCampaign.couponRaw['auto_select'] ? this.translate.instant('common.yes') + ';' : this.translate.instant('common.no') + ';',
      campaign_actions: () => ''
    };

    return valueFunctions[colId]();
  }

  private getCampaignTotalTransformedValue(index: number, detail: PlanRoiCampaignGroup, campaign: EmbeddedCampaign, location: string) {
    const columnFunctions = {
      1: () => this.translate.instant('resources.campaign_plans.types.custom.forecast.table.campaign_total'),
      5: () => this.decimalPipe.transform(campaign.customersCount, '1.0-0', location),
      7: () => this.decimalPipe.transform(campaign.deliveredCoupons, '1.0-0', location),
      8: () => this.decimalPipe.transform((campaign.deliveredPercentage * 100), '1.2-2'),
      9: () => this.decimalPipe.transform(campaign.redemptions, '1.0-0', location),
      10: () => this.decimalPipe.transform(campaign.customerRedemptions, '1.2-2', location),
      11: () => this.decimalPipe.transform((campaign.redemptionsPercentage * 100), '1.2-2'),
      12: () => this.decimalPipe.transform((campaign.redemptionsPercentage * 100), '1.2-2'),
      13: () => this.decimalPipe.transform(campaign.budgetExpense, '1.2-2', location),
      14: () => this.decimalPipe.transform(campaign.costVsBudget, '1.2-2', location),
      15: () => this.decimalPipe.transform(campaign.discountCost, '1.2-2', location),
      17: () => this.decimalPipe.transform(campaign.budgetConsumed * 100, '1.2-2', location),
      18: () => this.decimalPipe.transform(campaign.forecastBudgetConsumed, '1.2-2', location),
      19: () => this.decimalPipe.transform(campaign.budgetDeliveredVsForecasted * 100, '1.2-2', location),
      20: () => detail.type === 'Plans::SupplierCampaign' ? this.decimalPipe.transform(campaign.lgCost, '1.2-2', location) : this.translate.instant('common.fields.na'),
      21: () => this.decimalPipe.transform(campaign.incSales, '1.2-2', location),
      22: () => this.decimalPipe.transform(campaign.incRatio, '1.2-2', location)
    };
    return columnFunctions[index] ? columnFunctions[index]() + ';' : '"";';
  }

  private getCategorySubtotalTransformedValue(index: number, detail: PlanRoiCampaignGroup, location: string) {
    const subtotalColumnFunctions = {
      1: () => this.translate.instant('common.subtotal'),
      5: () => this.decimalPipe.transform(detail.subtotal.customersCount, '1.0-0', location),
      7: () => this.decimalPipe.transform(detail.subtotal.deliveredCoupons, '1.0-0', location),
      8: () => this.decimalPipe.transform((detail.subtotal.deliveredPercentage * 100), '1.2-2'),
      9: () => this.decimalPipe.transform(detail.subtotal.redemptions, '1.0-0', location),
      10: () => this.decimalPipe.transform(detail.subtotal.customerRedemptions, '1.2-2', location),
      11: () => this.decimalPipe.transform((detail.subtotal.redemptionsPercentage * 100), '1.2-2'),
      12: () => this.decimalPipe.transform((detail.subtotal.uniqueRedemptions * 100), '1.2-2'),
      13: () => this.decimalPipe.transform(detail.subtotal.budgetExpense, '1.2-2', location),
      14: () => this.decimalPipe.transform(detail.subtotal.costVsBudget, '1.2-2', location),
      15: () => this.decimalPipe.transform(detail.subtotal.discountCost, '1.2-2', location),
      17: () => this.decimalPipe.transform(detail.subtotal.budgetConsumed * 100, '1.2-2', location),
      18: () => this.decimalPipe.transform(detail.subtotal.forecastBudgetConsumed, '1.2-2', location),
      19: () => this.decimalPipe.transform(detail.subtotal.budgetDeliveredVsForecasted * 100, '1.2-2', location),
      20: () => this.decimalPipe.transform(detail.subtotal.lgCost, '1.2-2', location),
      21: () => this.decimalPipe.transform(detail.subtotal.incSales, '1.2-2', location),
      22: () => this.decimalPipe.transform(detail.subtotal.incRatio, '1.2-2', location)
    };
    return subtotalColumnFunctions[index] ? subtotalColumnFunctions[index]() + ';' : '"";';
  }

  private getOverallTotalTransformedValue(index: number, totalValues: PlanRoiGlobalTotal, location: string) {
    const totalColumnFunctions = {
      1: () => this.translate.instant('common.total'),
      5: () => this.decimalPipe.transform(totalValues.totalCustomersCount, '1.0-0', location),
      7: () => this.decimalPipe.transform(totalValues.totalDeliveredCoupons, '1.0-0', location),
      8: () => this.decimalPipe.transform((totalValues.totalDeliveredPercentage * 100), '1.2-2', location),
      9: () => this.decimalPipe.transform(totalValues.totalRedemptions, '1.0-0', location),
      10: () => this.decimalPipe.transform(totalValues.totalCustomerRedemptions, '1.2-2', location),
      11: () => this.decimalPipe.transform((totalValues.totalRedemptionsPercentage * 100), '1.2-2', location),
      12: () => this.decimalPipe.transform((totalValues.totalUniqueRedemptions * 100), '1.2-2', location),
      13: () => this.decimalPipe.transform(totalValues.totalBudgetExpense, '1.2-2', location),
      14: () => this.decimalPipe.transform(totalValues.totalCostVsBudget, '1.2-2', location),
      15: () => this.decimalPipe.transform(totalValues.totalDiscountCost, '1.2-2', location),
      17: () => this.decimalPipe.transform(totalValues.totalBudgetConsumed * 100, '1.2-2', location),
      18: () => this.decimalPipe.transform(totalValues.totalForecastBudgetConsumed, '1.2-2', location),
      19: () => this.decimalPipe.transform(totalValues.totalBudgetDeliveredVsForecasted * 100, '1.2-2', location),
      20: () => this.decimalPipe.transform(totalValues.totalLgCost, '1.2-2', location) ,
      21: () => this.decimalPipe.transform(totalValues.totalIncSales, '1.2-2', location),
      22: () => this.decimalPipe.transform(totalValues.totalIncRatio, '1.2-2', location)
    };
    return totalColumnFunctions[index] ? totalColumnFunctions[index]() + ';' : '"";';
  }
}

export interface TableColumn {
  id: string
  headerTitle: string,
  plainTitle: string,
  visible: boolean,
  disabled?: boolean
}