import { Component, OnInit, OnChanges, Input, SimpleChanges, EventEmitter, Output, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { ConfirmationService } from '../../services/confirmation.service';
import { CsvExportService } from '../../services/csv-export.service';
import { DataTableConfiguration, GetDataOptions } from './data-table-cfg';
import { DatePipe } from '@angular/common';
import { DateService } from '../../services/date.service';
import { DateTimeZonePipe } from '../../pipes/date-time-zone.pipe';
import { DictionaryService } from '../../services/dictionary.service';
import { FrogedService } from '../../services/froged/froged.service';
import { isNullOrUndefined } from '../../utils/common.utils';
import { ModalStatusService } from '../../services/modal-status.service';
import { ProfileService } from '../../../profiles/profile.service';
import { RefreshCacheService } from '../../services/refresh-cache.service';
import { ResourcesService } from '../../services/resources.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { FormGroup } from '@angular/forms';
import { ToggleCheckboxQuestion } from '../../models/forms/question-toggle-checkbox';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DictionaryService, RefreshCacheService]
})

export class DataTableComponent implements OnInit, OnChanges, OnDestroy {

  ableToCreate: boolean = true;
  data;
  data$: Subscription;
  datePipe = new DatePipe('en-US');
  emptyValue = '-';
  flags = this.featureFlags.flags;
  isFirstPage: boolean;
  isLoading: boolean;
  modalSubscription$: Subscription;
  nextTokenParam: string;
  numberOfPages = [10, 20, 50, 100];
  selected = [];
  subs$: Subscription[] = [];
  title: any;
  tokensData: string[] = [];

  toggleForm: FormGroup;
  toggleInput: ToggleCheckboxQuestion;

  page = {
    pageNumber: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0,
  };

  customCssClasses = {
    pagerLeftArrow: 'fa-light fa-chevron-left',
    pagerNext: 'mrg-left-10 fa-light fa-chevrons-right',
    pagerPrevious: 'mrg-right-10 fa-light fa-chevrons-left',
    pagerRightArrow: 'fa-light fa-chevron-right',
    sortAscending: 'fa fa-sort-asc',
    sortDescending: 'fa fa-sort-desc'
  };

  @Input() config: DataTableConfiguration;
  @Output() idSelected: EventEmitter<any> = new EventEmitter();
  @Output() applySelected: EventEmitter<any> = new EventEmitter();
  @Output() dataEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('loadMoreBtn') loadMoreBtn: ElementRef;

  constructor(
    private confirmationService: ConfirmationService,
    private csvExportService: CsvExportService,
    private dateService: DateService,
    private dateTimeZonePipe: DateTimeZonePipe,
    private dictionaryService: DictionaryService,
    private modalStatusService: ModalStatusService,
    private refreshCacheService: RefreshCacheService,
    private resourcesService: ResourcesService,
    private router: Router,
    private translate: TranslateService,
    private frogedService: FrogedService,
    private profileService: ProfileService,
    private featureFlags: FeatureFlagsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.isFirstPage = true;
    if (!this.config.tokenPagination) {
      this.initPage();
    }

    this.setPage({ offset: 0 });

    this.modalSubscription$ = this.modalStatusService.modalStatus.subscribe(() => {
      this.getData({refreshRows: true});
    });

    if (this.config.renderRowCheckbox === undefined || this.config.renderRowCheckbox === null) {
      this.config.renderRowCheckbox = true;
    }
    if (this.flags.managePlanOrCampaignApprovers) {
      this.handleForbbidenRoles();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.config.firstChange && this.rowsChanged(changes)) {
      this.handleData(changes.config.currentValue);
    }
  }

  ngOnDestroy() {
    if ( this.modalSubscription$ ) { this.modalSubscription$.unsubscribe(); }
    if ( this.data$ ) { this.data$.unsubscribe(); }
    if ( this.subs$.length > 0 ) { this.subs$.forEach( s$ => s$.unsubscribe());}
  }

  setPage(pageInfo) {
    if (!this.config.tokenPagination) {
      this.page.pageNumber = pageInfo.offset;
      this.config.requestData.pageNumber = this.getPage();
    }
    if (this.config.isActive) { this.getData(); }
  }

  changePageSize(event) {
    if (!this.config.tokenPagination) {
      this.page.pageNumber = 0 ;
      this.config.requestData.numberPerPage = +event.target.value;
      this.config.requestData.pageNumber = this.getPage();
    }

    this.page.size = +event.target.value;
    this.getData();
  }

  renderRowCheckboxPagination(event) {
    if (event && event.hasOwnProperty('page')) {
      this.config.requestData.pageNumber = event.page;
      this.getData();
    }
  }

  getPage() {
    return this.page.pageNumber + 1;
  }

  onSort(event) {
    let direction = event.newValue;
    if ( direction === 'asc' &&
         this.config.requestData.hasOwnProperty('sorting') &&
         this.config.requestData.sorting.hasOwnProperty('direction') &&
         this.config.requestData.sorting.direction &&
         direction === this.config.requestData.sorting.direction ) {
      direction = 'desc';
    } else {
      direction = 'asc';
    }

    if (!this.config.tokenPagination) {
      this.config.requestData.pageNumber = this.getPage();
    }

    this.config.requestData.sorting = { byProp: event.column.sortable, direction: direction };

    this.getData({ refreshRows: this.config.tokenPagination });
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onSelectAll(event) {
    const chkValue = event.target.checked;
    if (chkValue) {
      this.selected = [];
      this.config.rows.forEach(row => {
        this.selected.push(row);
      });
    } else {
      this.selected = [];
    }
  }

  getData(options?: GetDataOptions) {
    this.isLoading = true;
    this.config.requiresCacheService ? this.getDataWithCacheService(options) : this.getDataWithoutCacheService(options);
  }

  getId(row) {
    return row.id;
  }

  createNewResource() {
    this.router.navigate(this.config.createButton.redirectTo).catch(() => {});
  }

  onClick(event: Event, action, index) {
    event.preventDefault();
    event.stopPropagation();
    this.runActionCallback(action, index);
  }

  onDisabled(action, index) {
    if (action.hasOwnProperty('disabled') && action.disabled) {
      return action.disabled(this.data._embedded.list[index]);
    }
    return false;
  }

  onHide(action, row, index) {
    if (action.hasOwnProperty('show') && action.show) {
      return action.show(this.data._embedded.list[index]);
    }

    // TODO: Remove specific cases to each list config.  This appears to be specific for editable campaigns.
    if (action.id === 'edit' && row && row.protected && row.protected.parsedValue === 'true') {
      return true;
    }

    // When Campaigns: if editable is false, the button edit should not appear
    // Check show() method in print_undo vouchers to add edition to "editable" campaigns.
    if (action.id === 'edit' && row && row.editable && row.editable.value === 'false') {
      return true;
    }
    return true;
  }

  actionHasCallback(action: any): boolean {
    return action.hasOwnProperty('callback');
  }

  actionHasCustomRoute(action: any): boolean {
    return action.hasOwnProperty('customRoute');
  }

  onTableAction(action: { id: string, onClick?: Function }) {

    // FROGED event
    this.trackFrogedEvent(`moreActions_${action.id}`);

    if (action.hasOwnProperty('onClick')) {
      action.onClick( this.selected );
      return;
    }

    switch ( action.id ) {
      /* Common action to several lists. Logic can be moved to data table service. */
      case 'exportCSV':
        this.exportCSV();
        break;
    }
  }

  onFilter(filterParams) {
    this.isLoading = true;
    this.isFirstPage = true;
    this.config.isActive = true;
    this.config.requestData.filtering = filterParams;

    if ( !this.config.tokenPagination ) {
      this.config.requestData.pageNumber = 1;
    } else {
      this.data = {
        _embedded: { list: [] },
        _links: { total_count: { href: 0 }}
      };
    }

    this.getData();
  }

  isObject(value) {
    return value instanceof Object;
  }

  isObjectAndHasKey(value, key) {
    return value instanceof Object ? value.hasOwnProperty(key) : false;
  }

  isIconRenderable(value) {
    return value.parsedValue !== 'none' && value.parsedValue !== 'true';
  }

  nextTokenAppendPage() {
    let filtering;
    if (this.config.requestData.filtering && Object.keys(this.config.requestData.filtering).length > 0) {
      filtering = { ...this.config.requestData.filtering, ...{ starting_after: this.nextTokenParam } };
    } else {
      filtering = { starting_after: this.nextTokenParam };
    }
    this.config.requestData.filtering = filtering;
    this.getData();
  }

  highLightRows(ids: any[]) {
    const rowsToSelect = this.config.rows.filter(row => row.product_id === `${ids[0]}`) ;
    this.selected = [];
    this.selected.push(rowsToSelect[0]);
    const focus = document.getElementById('focused');
    focus.scrollIntoView();
  }

  getConfigColumnCellClass({row, column, value}): string {
    let cellCssClass = '';
    if (value?.resourceIcon && value?.customIconCssClass) {
      cellCssClass = value.customIconCssClass;
    }
    return cellCssClass;
  }

  getShowLink(action, index) {
    const element = this.data._embedded.list[index];
    if (element) {
      switch (action.id) {
        case 'details':
          return ['', {outlets: {modal: ['show', this.config.requestData.apiEndPoint, element.id] } }];
        case 'edit':
          return ['', {outlets: {modal: ['update', this.config.requestData.apiEndPoint, element.id] } }];
      }
    }
  }

  getCustomRoute(action, index) {
    const element = this.data._embedded.list[index];
    return action.customRoute(element);
  }

  /**
   * Method to use when is not possible to target with css selector due to dynamic rendering.
   */
  trackFrogedEvent(action: string): void {
    const frogedRoutes = {
      '/offers-personalization/coupons': 'coupons',
      '/offers-personalization/coupons/histories': 'coupons',
    }
    const url = this.router.url;

    if (frogedRoutes[url]) {
      this.frogedService.track(`OFFERS_${frogedRoutes[url]}_${action}`);
    }
  }

  private handleForbbidenRoles() {
    const userRole = this.profileService.getStoredUserRole();
    this.ableToCreate = ['admin', 'owner', 'manager', 'user_manager', 'content_creation'].includes(userRole);
  }

  private parseApiListResponse(apiResponse, options?: GetDataOptions): any {

    this.dataEmitter.emit(apiResponse);

    if (!apiResponse.hasOwnProperty('_embedded')) {
      return {
        _embedded: { list: apiResponse },
        _links: { total_count: { href: Object.keys(apiResponse).length } }
      };
    }

    if ( this.config.tokenPagination ) {

      let list: any[];

      if ( this.data && this.data._embedded && ( !options || options && !options.refreshRows ) ) {
        list = [...this.data._embedded.list, ...apiResponse.list];
        if (!this.isFirstPage) {
          this.scrollToLoadMoreBtn();
        }
      } else {
        list = apiResponse.list;
      }

      return {
        _embedded: { list: list },
        _links: { total_count: { href: list.length }}
      };
    }

    return apiResponse;
  }

  private scrollToLoadMoreBtn() {
    setTimeout(() => {
      if ( this.loadMoreBtn ) { this.loadMoreBtn.nativeElement.scrollIntoView(); }
    }, 100);
  }

  private processApiListAsRows() {
    this.config.rows = [];
    // Map each list item regarding its mapping information
    if (this.data._embedded.list) {
      this.data._embedded.list.map(
        item => {
          if (item) {
            this.config.rows.push(this.mapItem(item));
            this.config.rows = [...this.config.rows];
          }
        }
      );
    }

    if (this.data && this.data._links && this.data._links.hasOwnProperty('total_count')) {
      this.page.pageNumber = this.config.requestData.pageNumber - 1;
      this.page.totalElements = this.data._links.total_count.href;
      this.page.size = this.config.requestData.numberPerPage;
      this.page.totalPages = this.page.totalElements / this.page.size;
    } else {
      this.page.pageNumber = this.getPage() - 1;
      this.page.totalElements = 0;
      this.page.size = 10;
      this.page.totalPages = 0;
    }
    this.isLoading = false;
    this.isFirstPage = false;
  }

  private getDataWithoutCacheService(options: GetDataOptions) {
    if (this.config.isMock) {
      if (!this.config.mockConstant) {
        console.warn('DataTableComponent handled error: Please configure a MockConstant');
        return;
      }
      setTimeout(() => {
        this.isLoading = false;
        const items = this.config.mockConstant;
        if ( this.config.tokenPagination ) { this.updateTokens(items); }
        this.data = this.parseApiListResponse(items, options);
        this.processApiListAsRows();
      }, 1000 );
    } else {
      this.data$ = this.resourcesService.getData(this.config.requestData).subscribe(
        items => {
          if ( this.config.tokenPagination ) { this.updateTokens(items); }
          this.data = this.parseApiListResponse(items, options);
        },
        errData => {
          this.config.rows = [];
          this.isLoading = false;
          this.confirmationService.displayHttpErrorAlert(errData);
        },
        () => {
          this.processApiListAsRows();
        }
      );
    }
  }

  private getDataWithCacheService(options: GetDataOptions) {
    const newSubscription$ = this.refreshCacheService.getExpensiveData(this.config.requestData).subscribe(
      items => {
        if (this.refreshCacheService.isRequestFailed(items)) {
          this.isLoading = false;
          this.data = {_embedded: {list: []}};
          const errorTitle = this.translate.instant('common.error');
          const errorDesc = items.status;
          this.confirmationService.displayErrorAlert(errorTitle, errorDesc);
          if(newSubscription$){newSubscription$.unsubscribe()};
          return;
        }
        if (!this.refreshCacheService.isRequestPending(items)) {
          if ( this.config.tokenPagination ) { this.updateTokens(items); }
          this.data = this.parseApiListResponse(items, options);
          this.processApiListAsRows();
        }
      }
    );

    this.subs$.push( newSubscription$ );
  }

  private updateTokens(tokenData) {
    this.nextTokenParam = tokenData['next_token_starts_at'];
    this.tokensData.push(this.nextTokenParam);
  }

  private runActionCallback(action, index) {
    const element = this.data._embedded.list[index];
    if (element) { action.callback(element); }
  }

  private alertMessage() {
    this.confirmationService.displayErrorAlert('Error!', this.translate.instant('components.data-table.errors.none_selected'));
  }

  private exportCSV() {
    if (this.selected.length === 0) {
      this.alertMessage();
      return;
    }

    const csvData = this.csvExportService.prepareAriAsStringForCsv(this.config, this.selected);
    const csvBlob = this.csvExportService.getCsvBlob(csvData);
    const url = window.URL.createObjectURL(csvBlob);
    const nav = (window.navigator as any);

    if (nav.msSaveBlob) {
      nav.msSaveBlob(csvBlob, 'filename.csv');
    } else {
      const a = document.createElement('a');
      a.href = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(url));
      const prefix = this.config.requestData.csvPrefix ?? this.config.requestData.apiEndPoint;
      const fileName = prefix + '_' + moment().format('DD_MMMM_YYYY') + '.csv';
      a.download = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(fileName));
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  private initPage() {
    this.page.pageNumber = 0;
    this.page.totalElements = 0;
    this.page.size = 10;
    this.page.totalPages = this.page.totalElements / this.page.size;
  }

  private mapItem(item): Object {
    const mappedItem = {};
    this.config.tableMapping.map(
      itemMapping => {
        mappedItem[itemMapping['prop']] = this.getMappedValue(item, itemMapping);
      }
    );
    return mappedItem;
  }

  // TODO: Move to data table component service.
  // This should accept array of values and be able to make operations between them as concatenate.
  private getMappedValue(item, mapping) {

    let parsedValue = this.searchValueInObject(item, mapping, 'apiProp');

    if (parsedValue === null) { return '-'; }

    //
    if (mapping['type'] === 'image') {
      if(!parsedValue || parsedValue.indexOf('thumbnail/missing.png') >= 0) return null;
      parsedValue = { type: 'image', src: parsedValue };
    }

    if (mapping['type'] === 'date') {
      parsedValue = this.datePipe.transform(parsedValue, 'dd/MM/yyyy');
    }

    if (mapping['type'] === 'date_time') {
      parsedValue = parsedValue ? this.dateTimeZonePipe.transform(parsedValue, 'DD/MM/YYYY HH:mm') : '-';
    }

    if (mapping['type'] === 'date_duration') {
      parsedValue = moment.utc(moment.duration(parsedValue, 's')
        .asMilliseconds()).format('HH:mm:ss');
    }

    if (mapping['type'] === 'date_scientist') {
      parsedValue = new Date(parseFloat(parsedValue) * 1000);
      parsedValue = this.dateService.parseDateWithFormat(parsedValue, 'DD/MM/YYYY');
    }

    // Eval if the given object has link property
    if (mapping.hasOwnProperty('link')) {
      parsedValue = this.getItemRoute(item, mapping, parsedValue);
    }

    // Eval if the given object has icon
    if (mapping.hasOwnProperty('icon')) {
      if ( mapping.icon instanceof Function ) {
        parsedValue = mapping.icon(item);
      } else {
        parsedValue = this.getItemIcon(item, mapping, parsedValue);
      }
    }

    // Evail if the given object has badges
    if (mapping.hasOwnProperty('badge')) {
      parsedValue = this.getItemBadge(mapping, parsedValue);
    }

    // TODO GET TRANSLATED VALUE
    if (mapping.hasOwnProperty('translatable_key')) {
      parsedValue = this.dictionaryService.getNameByKeyAndId(mapping['translatable_key'], parsedValue);
    }

    return parsedValue;
  }

  private getItemRoute(item, mapping, parsedValue): any {
    const resourceId = this.searchValueInObject(item, mapping, 'resourceId');
    if (resourceId !== null) {
      return {
        'resourceLink': mapping.link.resourcePath,
        'resourceId': resourceId,
        'parsedValue': parsedValue
      };
    } else {
      return parsedValue;
    }
  }

  // TODO: Remove specifics
  private handleCampaignsContentShow(item, mapping) {
    if (item._embedded.coupons && item._embedded.coupons.length > 0 &&
      mapping.apiProp === '_embedded.coupons' && mapping.resourcePath) {
      return {
        'resourceLink': 'coupons',
        'resourceId': item._embedded.coupons[0].parent_id,
        'parsedValue': item._embedded.coupons[0].name
      };
    }
  }

  // TODO: Move to data table service - Icon should be a callback in given action cfg.
  private getItemIcon(item, mapping, parsedValue): any {
    if (parsedValue === undefined) {
      return '';
    }
    const icon = this.dictionaryService.getIconByKeyAndId(mapping.icon.dictionaryKey, parsedValue.toString());
    const name = this.dictionaryService.getNameByKeyAndId(mapping.icon.dictionaryKey, parsedValue.toString());

    let resourceIcon = null;
    if (mapping.icon.resourcePath instanceof Array) {
      resourceIcon = this.getIconLink(mapping.icon.resourcePath, item);
    } else {
      resourceIcon = ['show', mapping.icon.resourcePath, item.id];
    }
    return {
      'icon': icon,
      'name': name,
      'resourceIcon': resourceIcon,
      'customIconCssClass': mapping.icon.customIconCssClass,
      'parsedValue': parsedValue.toString()
    };
  }

  // This recieves a 'resourcePath' like ['campaigns', ':_embedded.campaign.id', 'histories', ':id', 'content']
  // and it extracts them from the 'item'
  // it returns an array like ['show', 'campaigns', 123, 'histories', 456, 'content']
  private getIconLink(resourcePath, item) {
    const resourceIcon = ['show'];
    resourcePath.map(pathElement => {
      if (pathElement.substring(0, 1) === ':') {
        const resourceSplitted = pathElement.substring(1, pathElement.length).split('.');
        let extractingPropertyFromItem = item;
        resourceSplitted.map(resource => {
          extractingPropertyFromItem = extractingPropertyFromItem[resource];
        });
        resourceIcon.push(extractingPropertyFromItem);
      } else {
        if (pathElement) {
          resourceIcon.push(pathElement);
        }
      }
    });

    return resourceIcon;
  }

  private getItemBadge(mapping, parsedValue): any {
    if (isNullOrUndefined(parsedValue)){
      return {'badge': '', 'value': '', 'css_class': ''};
    };
    const object = this.dictionaryService.getObjectByKeyAndId(mapping.badge.dictionaryKey, parsedValue.toString());
    return {
      'badge': object['name'],
      'value': object['value'],
      'css_class': object['css_class']
    };
  }

  // TODO: Move to data table service
  private searchValueInObject(item, mapping, targetProp) {

    if (mapping.hasOwnProperty('getValue') && mapping.getValue instanceof Function) {
      return mapping.getValue(item);
    }

    let splittedPath = [];
    splittedPath = targetProp === 'resourceId' ? mapping.link[targetProp].split('.') : mapping[targetProp].split('.');

    let mappedValue = item[splittedPath[0]];

    // TODO: Remove specifics.  This is particular is a link in a data table cell.
    if (this.config.requestData.apiEndPoint === 'campaigns' && mapping.apiProp === '_embedded.coupons') {
      mappedValue = this.handleCampaignsContentShow(item, mapping);
      return mappedValue;
    }

    if (mappedValue === null) { return mappedValue; }

    splittedPath.map(element => {
      if (mappedValue && mappedValue.hasOwnProperty(element)) {
        mappedValue = mappedValue[element];
      }
    });

    // Return hyphen char to table if the value is an object lacking the target key
    if (mappedValue === null || (typeof mappedValue === 'object' && !mappedValue.hasOwnProperty(mapping.type))) {
      return null;
    }

    return mappedValue;
  }

  private rowsChanged(changes: SimpleChanges): boolean {
    return changes.config.previousValue.rows !== changes.config.currentValue.rows;
  }

  private handleData(config) {
    if (config.rows !== undefined) {
      this.config.rows = [...config.rows];
    }
  }
}
