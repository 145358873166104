import { AttributesConditionsService } from '../../../shared/services/attributes-conditions.service';
import { BehaviorSubject } from 'rxjs';
import { ChallengesService } from '../challenges.service';
import { CheckboxQuestion } from '../../../shared/models/forms/question-checkbox';
import { CountriesService } from '../../../shared/services/countries.service';
import { DictionaryService } from '../../../shared/services/dictionary.service';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { FeaturesService } from '../../data-warehouse/products/features.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguagesService } from '../../../shared/services/languages.service';
import { LocationsService } from '../../data-warehouse/locations/locations.service';
import { LocationsTaxonomyTermsService } from '../../data-warehouse/locations/location-taxonomy-terms.service';
import { LoyaltyScoreMomentsService } from './loyalty_scores_moments/loyalty-score-moments.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { Observable } from 'rxjs';
import { Option } from '../../../shared/models/common/option';
import { PartnersService } from '../partners/partners.service';
import { PaymentMethodsService } from '../../config/company/payment-methods/payment-methods.service';
import { ProductsService } from '../../data-warehouse/products/products.service';
import { ProfileService } from '../../../profiles/profile.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { RadioQuestion } from '../../../shared/models/forms/question-radio';
import { ResourcesService } from '../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TiersService } from '../rewards/tiers.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class LoyaltyScoresService extends ResourcesService implements MultiselectDataSourceable {

  flags = this.featureFlags.flags;
  inputs: QuestionBase<any>[];
  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private attributeConditions: AttributesConditionsService,
    private challengesService: ChallengesService,
    private countriesService: CountriesService,
    private dictionarySrv: DictionaryService,
    private featureFlags: FeatureFlagsService,
    private languagesService: LanguagesService,
    private locations: LocationsService,
    private locationsTaxonomyTerms: LocationsTaxonomyTermsService,
    private loyaltyScoreMoment: LoyaltyScoreMomentsService,
    private partners: PartnersService,
    private paymentsMethods: PaymentMethodsService,
    private productFeatureService: FeaturesService,
    private productService: ProductsService,
    private profileService: ProfileService,
    private tiersService: TiersService,
    private translate: TranslateService
  ) {
    super(http);
  }

  lineConditionsCustomsArr:any[] = [
    { id: 'custom1', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom1')},
    { id: 'custom2', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom2')},
    { id: 'custom3', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom3')},
    { id: 'custom4', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom4')},
    { id: 'custom5', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom5')},
    { id: 'custom6', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom6')},
    { id: 'custom7', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom7')},
    { id: 'custom8', name: this.translate.instant('resources.scores.loyalty_scores.form.options.custom8')}
  ];

  refreshMultiselect() {
    this.optsSource.next('');
  }

  getAll() {

    const requestOptions = {
      apiEndPoint: 'loyalty_scores',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };

    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object) {
    let requestOptions = {
      apiEndPoint: 'loyalty_scores',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number) {
    return this.getLoyaltyScoresById(id);
  }

  getLoyaltyScores(): Observable<any> {
    return this.getData({apiEndPoint: `loyalty_scores`});
  }

  getLoyaltyScoresById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `loyalty_scores/${id}`});
  }

  cloneLoyaltyRule( id: number): Observable<any> {
    return this.postResource({}, `loyalty_scores/${id}/clone` );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  resourceOptions() {
    return [
      { id: 'include', name: this.translate.instant('resources.scores.loyalty_scores.form.options.include')},
      { id: 'exclude', name: this.translate.instant('resources.scores.loyalty_scores.form.options.exclude')}
    ];
  }

  featuresOptions() {
    return [
      { id: 'include', name: this.translate.instant('resources.scores.loyalty_scores.form.options.include')},
      { id: 'include_all', name: this.translate.instant('resources.scores.loyalty_scores.form.options.include_all')},
      { id: 'exclude', name: this.translate.instant('resources.scores.loyalty_scores.form.options.exclude')}
    ];
  }

  lineConditions() {
    const lineCustoms = this.profileService.getStoredUser()['company']['customs']['Line'];
    if (lineCustoms && Object.keys(lineCustoms).length > 0 ) {
      const parsedLineCustoms = Object.keys(lineCustoms).map(el => {
        return {
          id: el,
          name: lineCustoms[el].name
        };
      });
      return parsedLineCustoms;
    } else {
      return this.lineConditionsCustomsArr;
    }
  }

  getlineConditionsFieldName(field:string){
    const arr = this.lineConditionsCustomsArr.filter(el => el.id === field);
    return arr.length ? arr[0].name: null;
  }

  private periodsOptions() {
    return [
      { id: 'relative', name: this.translate.instant('resources.scores.loyalty_scores.form.options.relative')},
      { id: 'fixed', name: this.translate.instant('resources.scores.loyalty_scores.form.options.fixed')}
    ];
  }

  private expirationOptions() {
    return [
      { id: 'at_the_end_of_the_calendar_month', name: this.translate.instant('resources.scores.loyalty_scores.form.options.month')},
      { id: 'at_the_end_of_the_calendar_quarter', name: this.translate.instant('resources.scores.loyalty_scores.form.options.quarter')},
      { id: 'at_the_end_of_the_calendar_semester', name: this.translate.instant('resources.scores.loyalty_scores.form.options.semester')},
      { id: 'at_the_end_of_the_calendar_year', name: this.translate.instant('resources.scores.loyalty_scores.form.options.year')}
    ];
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'points',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'points_price_multiplicator',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      // FAKE APPAREL GROUP points_percentage
      new TextboxQuestion({
        key: 'points_percentage',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      // FAKE DEMO chunks
      new TextboxQuestion({
        key: 'chunks',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        label: this.translate.instant('resources.scores.loyalty_scores.fields.transferable'),
        key: 'transferable',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'loyalty_score_moment',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.loyaltyScoreMoment,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'strategy_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getStrategiesOptions(),
        getValue: (value) => {
          if (value) {
            return ([this.getStrategiesOptions().find( item => item.id === value)]);
          } else {
            return [];
          }
        },
        disabled: this.shouldDisableStrategies(formValues)
      }),
      new MultiSelectQuestion({
        key: 'countries',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        options: this.countriesService.getCountries(),
        required: true,
        getValue: (value) => {
          const selectedCountries = value.map( countryCode => {
            const country = this.countriesService.getCountriesById(countryCode);
            return country ? country : null
          });
          return selectedCountries.filter(el => el !== null);
        }
      }),
      new MultiSelectQuestion({
        key: 'customer_registration_countries',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        options: this.countriesService.getCountries(),
        required: false,
        getValue: (value) => {
          const selectedRegistrationCountries = value.map( countryCode => {
            const country = this.countriesService.getCountriesById(countryCode);
            return country ? country : null
          });
          return selectedRegistrationCountries.filter(el => el !== null);
        }
      }),
      new MultiSelectQuestion({
        key: 'locationsOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.resourceOptions(),
        getValue: (value) => ([this.resourceOptions().find( item => item.id === value)]),
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locations,
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        key: 'locationFeaturesOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.featuresOptions(),
        getValue: (value) => ([this.featuresOptions().find( item => item.id === value)]),
      }),
      new MultiSelectQuestion({
        key: 'feature_location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.locationsTaxonomyTerms,
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        key: 'productsOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: this.resourceOptions(),
        getValue: (value) => ([this.resourceOptions().find( item => item.id === value)]),
      }),
      new MultiSelectQuestion({
        key: 'product_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.productService,
        useToken: true,
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        key: 'productsFeaturesOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: this.featuresOptions(),
        getValue: (value) => ([this.featuresOptions().find( item => item.id === value)]),
      }),
      new MultiSelectQuestion({
        key: 'feature_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.productFeatureService,
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        key: 'paymentsOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: this.resourceOptions(),
        getValue: (value) => ([this.resourceOptions().find( item => item.id === value)]),
      }),
      new MultiSelectQuestion({
        key: 'payment_method_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.paymentsMethods,
        getValue: (value) => ({ selectedIds: value })
      }),
      new MultiSelectQuestion({
        key: 'lineConditions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.lineConditions(),
        getValue: (value) => ([this.lineConditions().find( item => item.id === value)])
      }),
      new MultiSelectQuestion({
        key: 'lineOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.lineOptions(),
        getValue: (value) => ([this.lineOptions().find( item => item.id === value)])
      }),
      new TextboxQuestion({
        key: 'lineInput',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'customerConditions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.attributeConditions,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'customerOptions',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        getValue: (value) => ([this.lineOptions().find( item => item.id === value)])
      }),
      new MultiSelectQuestion({
        key: 'customerSelectMulti',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        getValue: (value) => (this.lineOptions().find( item => item.id === value))
      }),
      new MultiSelectQuestion({
        key: 'lineConditionSelectMulti',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        getValue: (value) => (this.lineOptions().find( item => item.id === value))
      }),
      new TextboxQuestion({
        key: 'customerInput',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        label: this.translate.instant('resources.scores.loyalty_scores.fields.allways'),
        key: 'once_check',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: true,
        required: true,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'once_limit',
        type: 'number',
        disabled: false,
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'once',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.chancesOptions(),
        getValue: (value) => {
          if (value !== 'allways') {
            return ([this.chancesOptions().find( item => item.id === value)]);
          } else {
            setTimeout(() => {
              return [];
            }, 10);
          }
        }
      }),
      new TextboxQuestion({
        key: 'activation_delay',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'activation_delay_unit',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.activationOptions(),
        allowBlank: false,
        getValue: (value) => {
          if (value) {
            return ([this.activationOptions().find( item => item.id === value)]);
          } else {
            return [];
          }
        }
      }),
      new CheckboxQuestion({
        key: 'activation_delay_beginning_month',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        key: 'availability_duration_from',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'availability_duration',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'availability_duration_unit',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        allowBlank: false,
        options: this.activationOptions(),
        getValue: (value) => {
          if (value) {
            return ([this.activationOptions().find( item => item.id === value)]);
          } else {
            return [];
          }
        }
      }),
      new CheckboxQuestion({
        key: 'availability_duration_natural',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new RadioQuestion({
        key: 'active',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.dictionarySrv.getValuesByKey('loyalty_scores_status'),
        value: 'false',
        getValue: (value) => value ? 'true' : 'false'
      }),
      new RadioQuestion({
        key: 'expiration_period_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.periodsOptions(),
        value: 'relative',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'fixed_option_selected',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.expirationOptions(),
        getValue: (value) => value ? [(this.expirationOptions().find( item => item.id === value))] : []
      }),
      new MultiSelectQuestion({
        key: 'available_languages',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        options: this.languagesService.getLocalizedLanguages(),
      }),
      // POC APPAREL GROUP
      new TextboxQuestion({
        key: 'max_num_victories',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      // END
      new MultiSelectQuestion({
        key: 'partner_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.partners,
        getValue: (value) => ({ selectedIds: [value] })
      }),
      new MultiSelectQuestion({
        key: 'achievement_definition_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        dataSource: this.tiersService,
        selectedIds: formValues?.achievement_definitions?.length ? this.getAchievementValues(formValues.achievement_definitions) : []
      }),
      // CHALLENGES FIELD
      new MultiSelectQuestion({
        key: 'achievement_definition_challenge_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        dataSource: this.challengesService,
        selectedIds: formValues?.achievement_definition_challenges?.length ? this.getAchievementValues(formValues.achievement_definition_challenges) : []
      }),
      // END
      new MultiSelectQuestion({
        key: 'search_by',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.dictionarySrv.getValuesByKey('search_by_products_options')
      }),
    ];
    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);

    Object.keys(formValues).forEach(
      key => {
        if (key === 'included_location_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'locationsOptions' ).getValue('include');
          inputsArray.find( input => input.key === 'locationsOptions' ).value = value;
        } else if ( key === 'excluded_location_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'locationsOptions' ).getValue('exclude');
          inputsArray.find( input => input.key === 'locationsOptions' ).value = value;
        }

        if (key === 'feature_location_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'locationFeaturesOptions' ).getValue('include');
          inputsArray.find( input => input.key === 'locationFeaturesOptions' ).value = value;
        } else if ( key === 'included_all_feature_location_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'locationFeaturesOptions' ).getValue('include_all');
          inputsArray.find( input => input.key === 'locationFeaturesOptions' ).value = value;
        } else if ( key === 'excluded_feature_location_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'locationFeaturesOptions' ).getValue('exclude');
          inputsArray.find( input => input.key === 'locationFeaturesOptions' ).value = value;
        }

        if (key === 'included_product_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'productsOptions' ).getValue('include');
          inputsArray.find( input => input.key === 'productsOptions' ).value = value;
        } else if ( key === 'excluded_product_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'productsOptions' ).getValue('exclude');
          inputsArray.find( input => input.key === 'productsOptions' ).value = value;
        }

        if (key === 'included_feature_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'productsFeaturesOptions' ).getValue('include');
          inputsArray.find( input => input.key === 'productsFeaturesOptions' ).value = value;
        } else if ( key === 'included_all_feature_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'productsFeaturesOptions' ).getValue('include_all');
          inputsArray.find( input => input.key === 'productsFeaturesOptions' ).value = value;
        } else if ( key === 'excluded_feature_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'productsFeaturesOptions' ).getValue('exclude');
          inputsArray.find( input => input.key === 'productsFeaturesOptions' ).value = value;
        }

        if (key === 'payment_method_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'paymentsOptions' ).getValue('include');
          inputsArray.find( input => input.key === 'paymentsOptions' ).value = value;
        } else if ( key === 'included_all_payment_method_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'paymentsOptions' ).getValue('include_all');
          inputsArray.find( input => input.key === 'paymentsOptions' ).value = value;
        } else if ( key === 'excluded_payment_method_ids' && formValues[key].length) {
          const value = inputsArray.find( input => input.key === 'paymentsOptions' ).getValue('exclude');
          inputsArray.find( input => input.key === 'paymentsOptions' ).value = value;
        }

        if (key === 'once') {
          const onceInput = inputsArray.find(input => input.key === 'once');
          onceInput.value = [this.chancesOptions().find( item => item.id === formValues['once'] )];
        }

        if (key === 'available_languages') {
          const langValue = this.languagesService.getLocalizedLanguages().filter( item => formValues[key].includes(item.id));
          inputsArray.find( input => input.key === 'available_languages').value = langValue;
        }
      }
    );

    setTimeout(() => {
      if (formValues['once'] === 'allways') {
        inputsArray.find(input => input.key === 'once_limit').disabled = true;
      }
    })

    if (formValues['availability_duration_from'] === 'activation') {
      inputsArray.find(input => input.key === 'availability_duration_from').value = true;
    } else {
      inputsArray.find(input => input.key === 'availability_duration_from').value = false;
    }

    return this.inputs;
  }

  sendData(data) {
    return this.postResource(data, 'loyalty_scores');
  }

  updateData(data: any, _id: number) {
    return this.patchResource(data, `loyalty_scores/${_id}`);
  }

  grantPointsToSegment(data, id) {
    return this.postResource(data, `loyalty_scores/${id}/redeem_segment`);
  }

  getExpirationDate(strategySlug: string) {
    return this.getData({apiEndPoint: `loyalty_scores/calculate_fixed_period_date`, filtering: {strategy_slug: strategySlug}});
  }

  prepareDataToDispatch(form: UntypedFormGroup, motivator_line_attributes, _customer_attributes) {
    const points = form.value.points ? parseInt(form.value.points, 10) : null;
    const points_price_multiplicator = form.value.points_price_multiplicator ? parseInt(form.value.points_price_multiplicator, 10) : null;
    const moment = form?.value?.loyalty_score_moment?.length > 0 ? form.value.loyalty_score_moment[0].rawElement.slug : [];
    const countries = this.attachCountriesList(form.value);
    const customer_registration_countries = form.value.customer_registration_countries ? form.value.customer_registration_countries.map(item => item.id) : [];
    const location_ids = form.value.location_ids ? form.value.location_ids.map(item => item.id) : [];
    const feature_location_ids = form.value.feature_location_ids ? form.value.feature_location_ids.map(item => item.id) : [];
    const product_ids = form.value.product_ids ? form.value.product_ids.map(item => item.id) : [];
    const feature_ids = form.value.feature_ids ? form.value.feature_ids.map(item => item.id) : [];
    const payment_method_ids = form.value.payment_method_ids ? form.value.payment_method_ids.map(item => item.id) : [];
    const availability_duration_from = form.value.availability_duration_from ? 'activation' : 'win';
    const once = form?.value?.once?.length ? form.value.once[0].id : 'allways';
    const activation_delay_unit = form?.value?.activation_delay_unit?.length > 0 ? form.value.activation_delay_unit[0].id : null;
    const availability_duration_unit = form?.value?.availability_duration_unit?.length > 0 ? form.value.availability_duration_unit[0].id : null;
    const customer_attributes = _customer_attributes ? this.deleteAttributes(_customer_attributes, ['condition_name']) : [];
    const fixed_option_selected = form?.value?.fixed_option_selected?.length > 0 ? form.value.fixed_option_selected[0].id : null;
    const strategy_type = form?.value?.strategy_type?.length > 0 ? form.value.strategy_type[0].id: null;
    const partnerId = form?.value?.partner_id?.length > 0 ? form.value.partner_id[0].id : null;
    const achievementDefinition = this.getMultiselectFieldValue(form, 'achievement_definition_ids');
    const achievementChallengeDefinition = this.getMultiselectFieldValue(form, 'achievement_definition_challenge_ids');

    const loyaltyScoreOptions = {
      achievement_definition_ids: achievementDefinition,
      achievement_definition_challenge_ids: achievementChallengeDefinition,
      activation_delay_beginning_month: form.value.activation_delay_beginning_month,
      activation_delay_unit: activation_delay_unit,
      activation_delay: parseInt(form.value.activation_delay, 10),
      active: form.value.active,
      availability_duration_from: availability_duration_from,
      availability_duration_natural: form.value.availability_duration_natural,
      availability_duration_unit: availability_duration_unit,
      availability_duration: parseInt(form.value.availability_duration, 10),
      available_from: form.value.available_from,
      available_to: form.value.available_to,
      countries: countries,
      customer_attributes: customer_attributes,
      customer_registration_countries: customer_registration_countries,
      description: form.value.description,
      expiration_period_type: form.value.expiration_period_type,
      feature_ids: feature_ids,
      feature_location_ids: feature_location_ids,
      fixed_option_selected: fixed_option_selected,
      included_feature_ids: feature_ids,
      included_feature_location_ids: feature_location_ids,
      included_location_ids: location_ids,
      included_payment_method_ids: payment_method_ids,
      included_product_ids: product_ids,
      location_ids: location_ids,
      motivator_line_attributes: this.deleteAttributes(motivator_line_attributes, ['condition_name', 'field_name']),
      name: form.value.name,
      once_limit: form.value.once_limit,
      once: once,
      partner_id: partnerId,
      payment_method_ids: payment_method_ids,
      points_price_multiplicator: points_price_multiplicator,
      points: points,
      product_ids: product_ids,
      strategy_type: strategy_type,
      transferable: form.value.transferable,
      when: moment,
    };

    this.processFormOptions(form, loyaltyScoreOptions, location_ids, feature_location_ids, product_ids, feature_ids, payment_method_ids);
    return loyaltyScoreOptions;
  }

  getAccumulationTypeName(strategyType:string):string{

    switch (strategyType) {
      case 'multiplicator':
        return this.translate.instant('resources.scores.loyalty_scores.columns.multiplicator_amount_spent');
      case 'fixed_points':
        return this.translate.instant('resources.scores.loyalty_scores.columns.fixed_points');
      case 'chunk':
        return this.translate.instant('resources.scores.loyalty_scores.columns.chunk');
      default:
        return strategyType
    }
  }

  getPointsRuleName(strategyType:string, points:string, pointsPriceMultiplicator:string):string{
    switch (strategyType) {
      case 'multiplicator':
        return points && pointsPriceMultiplicator ? `${points} (€$£) x ${pointsPriceMultiplicator} points` : '-';
      case 'fixed_points':
        return points ? `${points} points` : '-';
      case 'chunk':
        return points && pointsPriceMultiplicator ? `${points} (€$£) = ${pointsPriceMultiplicator} points` : '-';
    }
  }

  private getStrategiesOptions(): Option[] {
    const strategiesOpts = [
      {
        'id': 'multiplicator',
        'name': this.translate.instant('resources.scores.loyalty_scores.fields.per_amount_spent')
      },
      {
        'id': 'fixed_points',
        'name': this.translate.instant('resources.scores.loyalty_scores.fields.fixed_amount')
      },
      {
        'id': 'chunk',
        'name': this.translate.instant('resources.scores.loyalty_scores.fields.per_chunks')
      }
    ];

    if(this.flags.showPointsPercentualField) {
      strategiesOpts.push({
        'id': 'percentage',
        'name': this.translate.instant('resources.scores.loyalty_scores.fields.percentage_amount')
      })
    }

    return strategiesOpts;
  }

  private attachCountriesList(form) {
    let countriesList;
    if(form.loyalty_score_moment[0]?.id === 'after_winning_challenge' || form.loyalty_score_moment[0]?.id.indexOf('custom_entity') === 0) {
      countriesList = this.countriesService.getCountries().map(country => country.id);
    } else {
      countriesList = form?.countries?.map(item => item.id) ?? [];
    }
    return countriesList;
  }

  private processFormOptions(
    form: UntypedFormGroup,
    loyaltyScoreOptions: unknown,
    location_ids: number[],
    feature_location_ids: number[],
    product_ids: number[],
    feature_ids: number[],
    payment_method_ids: number[]
  ) {
    const processOptions = (
      formValue: any[],
      optionType: string,
      excludedArray: number[],
      includedArray: string,
      includedAllArray?: string
    ) => {
      const firstOption = formValue?.[0]?.id;

      if (firstOption === 'exclude') {
        loyaltyScoreOptions[`excluded_${optionType}`] = excludedArray;
        loyaltyScoreOptions[includedArray] = [];
        if (includedAllArray) {
          loyaltyScoreOptions[includedAllArray] = [];
        }
      } else if (firstOption === 'include_all') {
        loyaltyScoreOptions[includedAllArray] = excludedArray;
        loyaltyScoreOptions[includedArray] = [];
      } else if (formValue?.length === 0) {
        loyaltyScoreOptions[optionType] = [];
        loyaltyScoreOptions[includedArray] = [];
        loyaltyScoreOptions[`excluded_${optionType}`] = [];
        if (includedAllArray) {
          loyaltyScoreOptions[includedAllArray] = [];
        }
      }
    };

    processOptions(form.value.locationsOptions, 'location_ids', location_ids, 'included_location_ids');
    processOptions(form.value.locationFeaturesOptions, 'feature_location_ids', feature_location_ids, 'included_feature_location_ids', 'included_all_feature_location_ids');
    processOptions(form.value.productsOptions, 'product_ids', product_ids, 'included_product_ids');
    processOptions(form.value.productsFeaturesOptions, 'feature_ids', feature_ids, 'included_feature_ids', 'included_all_feature_ids');
    processOptions(form.value.paymentsOptions, 'payment_method_ids', payment_method_ids, 'included_payment_method_ids', 'included_all_payment_method_ids');
  }

  private deleteAttributes(arr: any[], attributesToDelete: string[]) {
    let newArr:any[];
    attributesToDelete.forEach((attr:string) => {
      newArr = arr.map(element => {
        delete element[attr];
        return element;
      });
    });
    return newArr;
  }

  private shouldDisableStrategies(formValues: object) {
    if (Object.keys(formValues).length === 0) return true;
    return formValues['loyalty_score_moment'] && formValues['loyalty_score_moment']['slug'] !== 'after_create_activity';
  }

  private getMultiselectFieldValue(form: UntypedFormGroup, field: string) {
    let fieldValue = null;
    if (form.get(field).value && form.get(field).value.length > 0) {
      const fieldList = [];
      form.get(field).value.forEach(f => fieldList.push(f.id));
      fieldValue = fieldList;
    }
    return fieldValue;
  }

  private getAchievementValues(rawAchievementValues: {id: number}[]) {
    return rawAchievementValues?.map(el => el.id);
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).forEach(
      key => {
        const tmpInput = this.inputs.find(input => input.key === key);
        if (tmpInput?.getValue) {
          const value = tmpInput.getValue(formValues[key]);
          if (value?.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
    });
  }

  private lineOptions() {
    return [
      { id: 'eq', name: this.translate.instant('resources.scores.loyalty_scores.form.options.equal')},
      { id: 'not_eq', name: this.translate.instant('resources.scores.loyalty_scores.form.options.not_equal')}
    ];
  }

  private chancesOptions() {
    return [
      { id: 'num_year', name: this.translate.instant('resources.scores.loyalty_scores.form.options.num_year')},
      { id: 'num_month', name: this.translate.instant('resources.scores.loyalty_scores.form.options.num_month')},
      { id: 'num_day', name: this.translate.instant('resources.scores.loyalty_scores.form.options.num_day')},
      { id: 'num_total', name: this.translate.instant('resources.scores.loyalty_scores.form.options.num_total')},
      { id: 'allways', name: this.translate.instant('resources.scores.loyalty_scores.fields.allways')}
    ];
  }

  private statusOptions() {
    return [
      { id: 'true', name: this.translate.instant('resources.scores.loyalty_scores.form.options.active')},
      { id: 'false', name: this.translate.instant('resources.scores.loyalty_scores.form.options.inactive')}
    ];
  }

  private activationOptions() {
    return [
      { id: 'days', name: this.translate.instant('resources.scores.loyalty_scores.form.options.days')},
      { id: 'months', name: this.translate.instant('resources.scores.loyalty_scores.form.options.months')}
    ];
  }

}
