<div class="row mrg-top-15">
  <div class="col-md-8 mr-auto ml-auto">
    <div class="card card-block">
      <div class="row">
        <div class="col-md-4 text-right" *ngIf="hasImage">
          <img src="{{rewardData.thumbnail_medium}}" alt="thumbnail">
        </div>
        <div class="col-md-7 text-left" [ngClass]="'ml-auto mr-auto'">
          <table class="table table-hover" aria-hidden="true" *ngIf="rewardData">
            <tbody>
              <tr *ngIf="rewardData.status">
                <td class="no-border-top"><strong [translate]="'resources.scores.rewards.show.fields.status'"></strong></td>
                <td class="no-border-top" *ngIf="rewardData.status === 'active'"><span
                    class="font-size-12 label label-success"
                    [translate]="'resources.scores.rewards.show.fields.active'"></span></td>
                <td class="no-border-top" *ngIf="rewardData.status === 'inactive'"><span class="label label-danger"
                    [translate]="'resources.scores.rewards.show.fields.inactive'"></span></td>
              </tr>
              <tr>
                <td><strong [translate]="'resources.scores.rewards.show.fields.flexible_score_allowed'"></strong></td>
                <td *ngIf="rewardData.flexible_score_allowed"><span><i class="fa fa-check text-success"></i></span>
                </td>
                <td *ngIf="!rewardData.flexible_score_allowed"><span><i class="fa fa-times text-danger"></i></span>
                </td>
              </tr>
              <tr *ngIf="rewardData.id">
                <td><strong [translate]="'resources.scores.rewards.show.fields.id'"></strong></td>
                <td>{{rewardData.id}}</td>
              </tr>
              <tr *ngIf="rewardData.name">
                <td><strong [translate]="'resources.scores.rewards.show.fields.name'"></strong></td>
                <td>{{rewardData.name}}</td>
              </tr>
              <tr *ngIf="rewardData.countries">
                <td><strong [translate]="'resources.scores.loyalty_scores.fields.countries'"></strong>&nbsp;<span class="text-semibold text-dark">({{rewardData.countries.length}})</span></td>
                <td *ngIf="rewardData.countries.length"><a data-toggle="modal" data-target="#modal-countries" class="text-semibold text-dark pointer" [translate]="'resources.scores.loyalty_scores.buttons.view_all'"></a></td>
              </tr>
              <tr *ngIf="rewardData.points">
                <td><strong [translate]="'resources.scores.rewards.show.fields.points'"></strong></td>
                <td>{{rewardData.points | number:'':'es'}}</td>
              </tr>
              <tr *ngIf="rewardData.extra_money">
                <td><strong [translate]="'resources.scores.rewards.show.fields.extra_money'"></strong></td>
                <td>{{rewardData.extra_money | number:'':'es'}}</td>
              </tr>
              <tr *ngIf="rewardData.currency">
                <td><strong [translate]="'resources.scores.rewards.show.fields.currency'"></strong></td>
                <td>{{rewardData.currency}}</td>
              </tr>
              <tr>
                <td><strong [translate]="'resources.scores.rewards.show.fields.is_coupon'"></strong></td>
                <td *ngIf="rewardData.is_coupon"><span><i class="fa fa-check text-success"></i></span></td>
                <td *ngIf="!rewardData.is_coupon"><span><i class="fa fa-times text-danger"></i></span></td>
              </tr>
              <tr *ngIf="rewardData._embedded.coupon">
                <td><strong [translate]="'resources.scores.rewards.show.fields.coupon_id'"></strong></td>
                <td>
                  <a class="pointer colorLink" (click)="goToCoupon()">{{rewardData._embedded.coupon.name}}</a>
                </td>
              </tr>
              <tr *ngIf="rewardData.description">
                <td><strong [translate]="'resources.scores.rewards.show.fields.description'"></strong></td>
                <td>{{rewardData.description}}</td>
              </tr>
              <tr *ngIf="rewardData.available_from">
                <td><strong [translate]="'resources.scores.rewards.show.fields.available_from'"></strong></td>
                <td>{{rewardData.available_from | date: 'dd/MM/yyyy'}}</td>
              </tr>
              <tr *ngIf="rewardData.available_to">
                <td><strong [translate]="'resources.scores.rewards.show.fields.available_to'"></strong></td>
                <td>{{rewardData.available_to | date: 'dd/MM/yyyy'}}</td>
              </tr>
              <tr *ngIf="rewardData._embedded.locations.length > 0">
                <td><strong [translate]="'resources.scores.rewards.show.fields.locations'"></strong>&nbsp;<span
                    class="text-semibold text-dark">({{rewardData._embedded.locations.length}})</span></td>
                <td>
                  <ul class="list arrow bullet-success">
                    <li *ngFor="let item of rewardData._embedded.locations">
                      <a class="pointer colorLink" (click)="goToLocation(item.id)">{{item.name}}</a>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr *ngIf="rewardData._embedded.locations.length === 0">
                <td><strong [translate]="'resources.scores.rewards.show.fields.locations'"></strong></td>
                <td [translate]="'resources.scores.rewards.show.fields.redeemable'"></td>
              </tr>
              <tr *ngIf="rewardData._embedded.achievement_definitions.length > 0">
                <td><strong [translate]="'resources.scores.rewards.columns.tiers'"></strong>&nbsp;<span
                    class="text-semibold text-dark">({{rewardData._embedded.achievement_definitions.length}})</span></td>
                <td>
                  <ul class="list arrow bullet-success">
                    <li *ngFor="let item of rewardData._embedded.achievement_definitions">
                      <p>{{item.name_with_tier ? item.name_with_tier : item.name}}</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr *ngIf="rewardData._embedded.reward_taxonomy_terms.length > 0">
                <td><strong [translate]="'resources.scores.rewards.columns.category'"></strong>&nbsp;<span
                    class="text-semibold text-dark">({{rewardData._embedded.reward_taxonomy_terms.length}})</span></td>
                <td>
                  <ul class="list arrow bullet-success">
                    <li *ngFor="let item of rewardData._embedded.reward_taxonomy_terms">
                      <a [routerLink]="['', { outlets: { modal: ['show', 'reward_categories', item.id] } }]">
                        {{item.name}}
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>