import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { QuestionBase } from "../../models/forms/question-base";
import { MultiSelectQuestion } from "../../models/forms/question-multiselect";
import { QuestionControlService } from "../../services/question-control.service";
import { FiltersService } from "./filters.service";
import { Filter } from "./filter";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "../../services/confirmation.service";
import { Subject, takeUntil } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { TextboxQuestion } from "../../models/forms/question-textbox";
import { ProfileService } from "../../../profiles/profile.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss']
})

export class SaveFilterComponent implements OnInit, OnDestroy {

  saveFilterForm: FormGroup;
  filterModalForm: FormGroup;
  inputs: QuestionBase<MultiSelectQuestion>[] = [];
  filterModalFormInputs: QuestionBase<any>[] = [];
  selectedFilter: Filter;

  private destroy$: Subject<void> = new Subject<void>();

  @Input() filterForm: FormGroup;
  @Output() filterSelectedValues: EventEmitter<unknown> = new EventEmitter<unknown>();
  @ViewChild('modalToggleBtn') modalToggleBtn: ElementRef;

  constructor(
    private filterService: FiltersService,
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private profileService: ProfileService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initForms();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openSaveFilterModal(): void {
    this.modalToggleBtn.nativeElement.click();
  }

  onSelectFilter(event): void {
    if(event) {
      this.selectedFilter = event;
      this.filterSelectedValues.emit(this.parseValuesToEmit(event.rawElement.values));
      this.filterModalForm.patchValue({ name: this.selectedFilter.name });
      this.filterModalFormInputs[0].value = this.selectedFilter.name;
    } else {
      this.selectedFilter = null;
      this.filterModalForm.patchValue({ name: null });
      this.filterModalFormInputs[0].value = null;
    }
  }

  deleteFilter(): void {
    const name = `(${this.selectedFilter.id}) ${this.selectedFilter.name}`;
    const messageConfirmation = this.translate.instant('components.data-table-filter.messages.delete_confirmation');
    this.confirmationService.displayConfirmationAlert(name, messageConfirmation, 'question').then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.filterService.deleteFilter(this.selectedFilter.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: () => this.handleDeletedFilter(name),
          error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
        });
      }
    }).catch(() => {});
  }

  overwriteFilter(): void {
    this.performFilterRequest('components.data-table-filter.messages.update_success');
  }

  saveFilter(): void {
    this.performFilterRequest('components.data-table-filter.messages.create_success');
  }

  routerUrl(): string {
    return this.router.url;
  }

  private handleSavedFilter(name: string, successMessage: string, filter: Filter): void {
    this.confirmationService.displaySuccessAlert(name, this.translate.instant(successMessage)).catch(() => {});
    this.modalToggleBtn.nativeElement.click();
    this.inputs[0].dataSource.selectedOptsSource.next([filter.id]);
    this.selectedFilter = filter;
  }

  private handleDeletedFilter(name: string): void {
    const inputFilterSrv = (this.inputs[0].dataSource as unknown) as FiltersService;
    inputFilterSrv.refresh();
    inputFilterSrv.refreshSelectedOptsSource([]);
    this.saveFilterForm.patchValue({ filter: null });
    this.onSelectFilter(null);
    this.confirmationService.displaySuccessAlert(name, this.translate.instant('components.data-table-filter.messages.delete_success')).catch(() => {});
  }

  private parseValuesToEmit(selectedFilterValues: { [key: string]: string }): { [key: string]: string[] } {
    const result = {};
    for (const key in selectedFilterValues) {
      const value = selectedFilterValues[key];
      result[key] = typeof value === 'string' && value.indexOf(',') >= 0 ? value.split(',') : [value];
    }
    return result;
  }

  private performFilterRequest(successMessage: string): void {
    const payload = this.getFilterPayload();
    const req$ = successMessage.indexOf('update_success') >= 0 ?
      this.filterService.updateFilter(payload, this.selectedFilter.id) :
      this.filterService.createFilter(payload);
    req$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (res: Filter) => this.handleSavedFilter(payload.name, successMessage, res),
      error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
    });
  }

  private getFilterPayload(): any {
    return {
      name: this.filterModalForm.value.name,
      path: this.routerUrl(),
      values: this.formValuesPayload(),
      user_id: this.profileService.getStoredUser().id
    }
  }

  private initForms(): void {
    this.inputs = [
      new MultiSelectQuestion({
        key: 'filter',
        label: 'components.data-table-filter.filters.filters',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.filterService
      })
    ]
    this.saveFilterForm = this.qcs.toFormGroup(this.inputs);

    this.filterModalFormInputs = [
      new TextboxQuestion({
        key: 'name',
        cssClasses: 'form-control input-default',
        required: true,
        value: this.selectedFilter ? this.selectedFilter.name : '',
        type: 'text'
      })
    ];
    this.filterModalForm = this.qcs.toFormGroup(this.filterModalFormInputs);
    if(this.inputs[0].dataSource.refreshSelectedOptsSource instanceof Function) this.inputs[0].dataSource.refreshSelectedOptsSource([]);
  }

  private formValuesPayload(): { [key: string]: string } {
    const result = {};
    for (const key in this.filterForm.value) {
      if (this.filterForm.value.hasOwnProperty(key)) {
        const values = this.filterForm.value[key];
        if (Array.isArray(values)) {
          result[key] = values.map(value => value.id).join(',');
        } else if (values && typeof values === 'object' && values.id) {
          result[key] = values.id;
        } else {
          result[key] = values;
        }
      }
      if (result[key] === null || result[key] === '') delete result[key];
    }
    return result;
  }
}
