<div class="mrg-btm-10">
  <span *ngIf="form.valid" class="label label-success font-size-13">
    {{'resources.segment_conditions.messages.form_valid' | translate}}
  </span>
  <span *ngIf="!form.pristine && !form.valid" class="label label-info font-size-13">
    {{'resources.segment_conditions.errors.form_invalid' | translate}}
  </span>
</div>

<div class="row" *ngIf="item.content.key === 'email' ||
                        item.content.key === 'signup_date' ||
                        item.content.key === 'birthday'">
  <div *ngFor="let input of inputs; let i = index" [ngClass]="input['cssClass'] || 'col'">
    <app-question [question]="getInputConfig(input.key)" [form]="form"></app-question>
  </div>
</div>

<app-campaign-condition *ngIf="item.content.key === 'campaign'"
                        [form]="form"
                        [item]="item"
                        [inputs]="inputs"></app-campaign-condition>

<app-coupon-condition *ngIf="item.content.key === 'coupon'"
                      [form]="form"
                      [item]="item"
                      [inputs]="inputs"></app-coupon-condition>

<app-phone-prefix-condition *ngIf="item.content.key === 'phone_prefix'"
                      [item]="item"
                      [inputs]="inputs"
                      [form]="form"></app-phone-prefix-condition>

<app-email-impact-condition *ngIf="item.content.key === 'email_actions'"
                      [item]="item"
                      [inputs]="inputs"
                      [form]="form"></app-email-impact-condition>

<app-ticket-condition *ngIf="item.content.key === 'sales'"
                      [item]="item"
                      [inputs]="inputs"
                      [form]="form"></app-ticket-condition>

<app-taxonomies-count-condition *ngIf="item.content.key === 'taxonomies_count'"
                                [form]="form"
                                [item]="item"
                                [inputs]="inputs">{{item.content.key}}</app-taxonomies-count-condition>

<app-postal-code-condition *ngIf="item.content.key === 'postal_code'"
                                [form]="form"
                                [item]="item"
                                [inputs]="inputs">{{item.content.key}}</app-postal-code-condition>

<app-sql-condition *ngIf="item.content.key === 'sql'"
                                [form]="form"
                                [item]="item"
                                [inputs]="inputs">{{item.content.key}}</app-sql-condition>

<app-collective-condition *ngIf="item.content.key === 'collective'"
                                [form]="form"
                                [item]="item"
                                [inputs]="inputs">{{item.content.key}}</app-collective-condition>

<app-client-id-condition *ngIf="item.content.key === 'customer_id'"
                                [form]="form"
                                [item]="item"
                                [inputs]="inputs">{{item.content.key}}</app-client-id-condition>

<app-client-usual-location-condition *ngIf="item.content.key === 'usual_location'"
                                    [form]="form"
                                    [item]="item"
                                    [inputs]="inputs">{{item.content.key}}</app-client-usual-location-condition>

<app-client-usual-location-condition *ngIf="item.content.key === 'registration_location'"
                                    [form]="form"
                                    [item]="item"
                                    [inputs]="inputs">{{item.content.key}}</app-client-usual-location-condition>

<app-voucher-count *ngIf="item.content.key === 'voucher_count'"
                   [form]="form"
                   [item]="item"
                   [inputs]="inputs"></app-voucher-count>

<app-max-sales-condition *ngIf="item.content.key === 'max_sales'"
                         [form]="form"
                         [item]="item"
                         [inputs]="inputs"></app-max-sales-condition>

<app-last-purchase-condition *ngIf="item.content.key === 'last_purchase'"
                             [form]="form"
                             [item]="item"
                             [inputs]="inputs"></app-last-purchase-condition>

<app-nested-segment-condition *ngIf="item.content.key === 'nested'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"
                              [filters]="{system_mode: 'not_system'}"></app-nested-segment-condition>

<app-customer-channel-status-segment-condition  *ngIf="item.content.key === 'customer_channel_status'"
                                                [form]="form"
                                                [item]="item"
                                                [inputs]="inputs"></app-customer-channel-status-segment-condition>

<app-available-score-segment-condition *ngIf="item.content.key === 'score_available'"
                                      [form]="form"
                                      [item]="item"
                                      [inputs]="inputs"></app-available-score-segment-condition>

<app-average-activity-condition *ngIf="item.content.key === 'average_activity'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"></app-average-activity-condition>

<app-custom-segment-condition *ngIf="item.content.key === 'custom'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"></app-custom-segment-condition>

<app-custom-segment-condition *ngIf="item.content.key === 'customf'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"></app-custom-segment-condition>

<app-calculated-values-buyer-types-segment-condition *ngIf="item.content.key === 'calculated_values_buyer_types'"
                                                     [form]="form"
                                                     [item]="item"
                                                     [inputs]="inputs"></app-calculated-values-buyer-types-segment-condition>

<app-system-segment-condition *ngIf="item.content.key === 'buyer_type_segment'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"
                              [filters]="{segment_category_id: systemCategories.buyer_types}"></app-system-segment-condition>

<app-system-segment-condition *ngIf="item.content.key === 'client_expense_segment'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"
                              [filters]="{segment_category_id: systemCategories.sales}"></app-system-segment-condition>

<app-system-segment-condition *ngIf="item.content.key === 'activity_segment'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"
                              [filters]="{segment_category_id: systemCategories.activity}"></app-system-segment-condition>

<app-system-segment-condition *ngIf="item.content.key === 'recency_segment'"
                              [form]="form"
                              [item]="item"
                              [inputs]="inputs"
                              [filters]="{segment_category_id: systemCategories.active_types}"></app-system-segment-condition>

<app-client-categories-condition *ngIf="item.content.key === 'customer_category'"
                                 [form]="form"
                                 [item]="item"
                                 [inputs]="inputs"></app-client-categories-condition>

<app-country-condition *ngIf="item.content.key === 'country'"
                      [form]="form"
                      [item]="item"
                      [inputs]="inputs"></app-country-condition>

<app-name-condition *ngIf="item.content.key === 'name'"
                    [form]="form"
                    [item]="item"
                    [inputs]="inputs"></app-name-condition>                          
                         
<app-city-condition *ngIf="item.content.key === 'city'"
                    [form]="form"
                    [item]="item"
                    [inputs]="inputs"></app-city-condition>

<app-state-condition *ngIf="item.content.key === 'state'"
                    [form]="form"
                    [item]="item"
                    [inputs]="inputs"></app-state-condition>
                  
<app-gender-condition *ngIf="item.content.key === 'gender'"
                      [form]="form"
                      [item]="item"
                      [inputs]="inputs"></app-gender-condition>

<app-customer-attribute-condition *ngIf="item.content.key === 'customer_attribute'"
                      [form]="form"
                      [item]="item"
                      [inputs]="inputs"></app-customer-attribute-condition>

<app-custom-entity-condition *ngIf="item.content.key === 'custom_entity'"
                      [form]="form"
                      [item]="item"
                      [rawConditionValues]="rawConditionValues"
                      [inputs]="inputs"></app-custom-entity-condition>