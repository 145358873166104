import { Component, OnInit } from "@angular/core";
import { Reward } from "../../../../../shared/models/reward/reward";
import { ActivatedRoute } from "@angular/router";
import { RewardsService } from "../../rewards.service";
import { ConfirmationService } from "../../../../../shared/services/confirmation.service";
import { FeatureFlagsService } from "../../../../../shared/services/feature-flags.service";
import { CountriesService } from "../../../../../shared/services/countries.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-reward-details-content',
  templateUrl: './reward-details.component.html',
  styleUrls: ['./reward-details.component.scss']
})

export class RewardDetailsComponent implements OnInit {

  rewardData: Reward;
  rewardId = this.route.snapshot.parent.params.id;
  hasImage: boolean;
  flags = this.featureFlags.flags;

  constructor(
    private route: ActivatedRoute,
    private rewardsService: RewardsService,
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
    private countriesService: CountriesService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  private getParams() {
    this.route.parent.params.subscribe({
      next: params => {
        if (params.id) {
          this.getRewardById();
        }
      }
    });
  }

  private getRewardById() {
    this.rewardsService.getRewardsById(this.rewardId).subscribe({
      next: (reward: Reward) => {
        this.rewardData = reward;
        this.rewardData.countries = this.rewardData.countries ? this.parseCountries(this.rewardData.countries) : null;
        this.hasImage = this.rewardData.thumbnail_medium.indexOf('images/medium/missing') >= 0 ? false : true;
      },
      error: (err: HttpErrorResponse) => {
        this.confirmationService.displayErrorAlert('Error', err.error.error);
      }
    });
  }

  private parseCountries(countriesList: string[]) {
    this.countriesService.getCountries('default');
    return countriesList.map(item => {
      let country = this.countriesService.getCountriesById(item);
      if (!country) {
        country = {id: item, name: item};
      }
      return country;
    });
  }
}
