import { CheckboxQuestion } from '../../../../../../../shared/models/forms/question-checkbox';
import { CommentsService } from '../../../../../../comments/comments.service';
import { CountriesService } from '../../../../../../../shared/services/countries.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationsService } from '../../../../../locations/locations.service';
import { LoyaltyScoresService } from '../../../../../../scores/loyalty_scores/loyalty-scores.service';
import { MultiSelectQuestion } from '../../../../../../../shared/models/forms/question-multiselect';
import { NewCard, NewUnifiedCustomerCard } from '../../../../../../../shared/models/customers/new-card';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../../../../../../shared/models/forms/question-base';
import { ResourcesService } from '../../../../../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { CurrentCompany } from '../../../../../../../shared/models/current-user';
import { ProfileService } from '../../../../../../../profiles/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ActionModalsService extends ResourcesService {

  inputs: QuestionBase<any>[];
  currentCompany = new CurrentCompany(this.profileService.getProfileCompany());

  constructor(
    http: HttpClient,
    private commentsService: CommentsService,
    private countries: CountriesService,
    private locations: LocationsService,
    private loyaltyScores: LoyaltyScoresService,
    private translate: TranslateService,
    private profileService: ProfileService,
  ) {
    super(http);
  }

  getInputs(): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'score',
        type: 'number',
        min: 1,
        step: 1,
        cssClasses: 'form-control input-default',
        required: true
      }),
      new CheckboxQuestion({
        key: 'allow_partial',
        type: 'checkbox',
        label: this.translate.instant('resources.customers.show.action_modals.allow_partial'),
        cssClasses: 'form-control input-default'
      }),
      new TextboxQuestion({
        key: 'destination_customer_id',
        type: 'number',
        cssClasses: 'form-control input-default',
        required: true
      }),
      new TextboxQuestion({
        key: 'new_customer_id_code',
        type: this.currentCompany.isAccountsIdentifierByCode() ? 'text' : 'number',
        cssClasses: 'form-control input-default',
        required: true
      }),
      new MultiSelectQuestion({
        key: 'country',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        required: true,
        options: this.countries.getCountries()
      }),
      new MultiSelectQuestion({
        key: 'location_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        required: true,
        dataSource: this.locations
      }),
      new MultiSelectQuestion({
        key: 'loyalty_score_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: true,
        dataSource: this.loyaltyScores
      }),
      // IF !HASCOMMENTS WE SHOULD USE THIS QUESTION
      new TextboxQuestion({
        key: 'comments',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true
      }),
      // IF HASCOMMENTS WE SHOULD USE THIS QUESTION
      new MultiSelectQuestion({
        key: 'multi_comments',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.commentsService,
        required: true
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  grantPoints(data: unknown, customer_id: number): Observable<any> {
    return this.postResource(data, `profiles/${customer_id}/give_score`);
  }

  subtractPoints(data: unknown, customer_id: number): Observable<any> {
    return this.postResource(data, `profiles/${customer_id}/subtract_score`);
  }

  transferPoints(score: number, allow_partial: boolean, customer_id: number, destination_customer_id: number, country: string, comments: string ) {
    return this.postResource({score: score, allow_partial: allow_partial, destination_customer_id: destination_customer_id, country: country, comments: comments}, 'profiles/' + customer_id + '/transfer_score');
  }

  blockUser(customer_id) {
    return this.patchResource({}, 'profiles/' + customer_id + '/deactivate');
  }

  deliverNewCard(newCardData: NewCard) {
    return this.postResource(newCardData, 'customers/transfer_new');
  }

  deliverNewUnifiedCustomerCard(newUnifiedCustomerCardData: NewUnifiedCustomerCard, customerId: string) {
    return this.postResource(newUnifiedCustomerCardData, `customers/${customerId}/cards`);
  }

  customerFusion(old_customer_id: string,  new_customer_id_code: string) {
    const newCustomerKey = this.currentCompany.isAccountsIdentifierByCode() ? 'new_customer_code' : 'new_customer_id';
    const payload = { old_customer_id: old_customer_id,  [newCustomerKey]: new_customer_id_code };

    return this.postResource(payload, 'customers/transfer');
  }

}
