export const PlanCampaignActionMock = [
  {
    name: 'Alcohol Purchase',
    id: 101,
    audience: {
      impacted_customer: 1.251,
      average_expenditure: 31.65,
      average_ticket: 16.40,
      units: 3,
      frequency: 1.9,
      pvp: 12.23,
      affectation_sales: 31.650,
      sales: 31.65
    },
    forecast: {
      impacted_customer: 0,
      max_coupon_redemptions: 0,
      max_redemptions: 0,
      coupons_delivered: 0,
      total_coupons_delivered: 0,
      percent_delivered: 0,
      total_redemptions: 0,
      unique_redemptions: 0,
      percent_redemptions: 0,
      redeem: 0,
      customers_redemptions: 0,
      discount: 0,
      percent_discount: 0,
      min_purchase: 0,
      discount_cost: 0,
      sales_incremental: 0,
      ratio_incremental: 0,
      percent_incremental: 0
    },
    _embedded: {
      segment: {
        id: 789
      }
    }
  },
  {
    name: 'Fruits Purchase 2',
    id: 102,
    audience: {
      impacted_customer: 1.251,
      average_expenditure: 31.65,
      average_ticket: 16.40,
      units: 3,
      frequency: 1.9,
      pvp: 12.23,
      affectation_sales: 31.650,
      sales: 31.65
    },
    audience_config: {
      estimated_customer_redemption: 1,
      estimated_delivery: 1,
      estimated_redemption: 1
    }
  },
  {
    name: 'Fruits Purchase 3',
    id: 103,
    audience: {
      impacted_customer: 1.251,
      average_expenditure: 31.65,
      average_ticket: 16.40,
      units: 3,
      frequency: 1.9,
      pvp: 12.23,
      affectation_sales: 31.650,
      sales: 31.65
    },
    audience_config: {
      estimated_customer_redemption: 1,
      estimated_delivery: 0,
      estimated_redemption: 0
    }
  },
  {
    name: 'Fruits Purchase 4',
    id: 104,
    audience: {
      impacted_customer: 1.251,
      average_expenditure: 31.65,
      average_ticket: 16.40,
      units: 3,
      frequency: 1.9,
      pvp: 12.23,
      affectation_sales: 31.650,
      sales: 31.65
    }
  },
  {
    name: 'Fruits Purchase 5',
    id: 105,
    audience: {
      impacted_customer: 1.251,
      average_expenditure: 31.65,
      average_ticket: 16.40,
      units: 3,
      frequency: 1.9,
      pvp: 12.23,
      affectation_sales: 31.650,
      sales: 31.65
    }
  }
];
