import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ResourcesService } from '../../services/resources.service';
import { MultiselectDataSource, MultiselectDataSourceable } from '../multiselect/multiselect';
import { HttpClient } from '@angular/common/http';
import { QuestionBase } from '../../models/forms/question-base';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FiltersService extends ResourcesService implements MultiselectDataSourceable {

  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();

  selectedOptsSource = new BehaviorSubject<string[]>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  refresh() {
    this.optsSource.next(null);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ): Observable<object> {

    let requestOptions = {
      apiEndPoint: 'filters',
      numberPerPage: 10,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    // User search from multiselect
    if ( searchValues ) {
      const filterObj = { filtering: {name: searchValues} };
      requestOptions = {...requestOptions, ...filterObj};
    }

    // Configuration filters set to <app-question> component
    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `filters/${id}` });
  }

  deleteFilter(id: string): Observable<any> {
    return this.deleteResource({}, `filters/${id}`);
  }

  updateFilter(filterPayload, filterId: string): Observable<any> {
    return this.patchResource(filterPayload, `filters/${filterId}`);
  }

  createFilter(filterPayload): Observable<any> {
    return this.postResource(filterPayload, 'filters');
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name, element);
  }

  /**
   * Assign values to form.
   * Case 1: if the question is data sourceable multiselect values should be assigned to the question.selectedIds.
   * - An observable should be emitted to notify the multiselect component to refresh its selected options.
   * Case 2: if the question is a multiselect with static options (not data sourceable) values should be assigned to the question.value
   * Case 3: Default case - value should be patched in form
   * @param baseForm The given formGroup to patch values to.
   * @param questions The array of inputs that compose the given formGroup.
   * @param formValues An unknown object containing the values to patch to the form.
   */
  assignValues(baseForm: FormGroup, questions: QuestionBase<any>[], formValues: unknown): void {
    Object.keys(formValues).forEach(key => {
      const question = questions.find(q => q.key === key);
      // Avoid patching values to questions that are not in the form
      if (!question) return;
      if (question.dataSource && question.dataSource.refreshSelectedOptsSource instanceof Function ) {
        question.dataSource.refreshSelectedOptsSource(formValues[key]);
      } else if(!question.dataSource && question.options?.length > 0) {
        let selectedOptions = [];
        if (typeof question.options?.[0]?.id === 'number') {
          selectedOptions = question.options.filter(option => formValues[key].map(Number).includes(option.id));
        } else {
          selectedOptions = question.options.filter(option => formValues[key].includes(option.id));
        }
        baseForm.get(key).setValue(selectedOptions);
       } else {
        baseForm.get(key).setValue(formValues[key][0]);
      }
      question.disabled = false;
    });
  }

  refreshSelectedOptsSource(selectedIds: string[]) {
    this.selectedOptsSource.next(selectedIds);
  }
}


