<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1>{{ 'resources.reports.form.fields.detail' | translate }}</h1>
          </div>
        </div>
				<button class="modal-close" (click)="closeModal()">
					<i class="ti-close"></i>
				</button>
			</div>

      <div class="modal-body height-100 background-gray">
        <div class="row no-margin-right">
          <div class="col-md-8 mr-auto ml-auto">
            <div class="card">
              <div class="card-heading border bottom">
                <h4 class="card-title" [translate]="'resources.reports.form.fields.details'"></h4>
              </div>
              <div class="card-block">
                <form class="form-horizontal" *ngIf="reportIncrementalForm">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="row flex-column">
                          <label for="name" class="col-md-3 mrg-top-10 text-semibold control-label"
                            [translate]="'resources.reports.form.fields.name'"></label>
                          <div class="col-md-6">
                            <app-question [question]="getInputConfig('name')" [form]="reportIncrementalForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mrg-top-10">
                    <div class="col-md-6">
                      <span class="font-size-16 card-title" [translate]="'resources.reports.form.fields.last_period'"></span>
                    </div>
                  </div>

                  <div class="row mrg-top-10">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('before_date_from')" [form]="reportIncrementalForm"></app-question>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('before_date_to')" [form]="reportIncrementalForm"></app-question>
                      </div>
                    </div>
                  </div>

                  <div class="row mrg-top-10">
                    <div class="col-md-6">
                      <span class="font-size-16 card-title" [translate]="'resources.reports.form.fields.next_period'"></span>
                    </div>
                  </div>

                  <div class="row mrg-top-10">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('after_date_from')" [form]="reportIncrementalForm"></app-question>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('after_date_to')" [form]="reportIncrementalForm"></app-question>
                      </div>
                    </div>
                  </div>

                  <!-- CAMPAIGNS  -->
                  <span class="font-size-16 card-title" [translate]="'resources.reports.form.fields.campaigns'"></span>
                  <div class="row">

                    <div class="col-md-12">
                      <div class="form-group">
                        <div *ngFor="let campaignFormId of campaignFormsIds; let i = index;">
                          <app-campaign-input [formKey]="campaignFormId" [index]="i" [campaignValues]="formValues[i]" [form]="reportIncrementalForm" (onDeleteCampaign)="deleteCampaignHandler($event)"></app-campaign-input>
                        </div>

                        <button type="button" class="btn btn-info btn-icon mrg-top-15" (click)="addCampaign($event)">
                          <i class="fa fa-plus"></i>
                          <span [translate]="'resources.reports.form.fields.add_campaign'"></span>
                        </button>

                      </div>
                    </div>
                  </div>

                <ng-container *ngIf="anyCampaignWithNextValue()">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="prediction_percentage_redemption" class="mrg-top-10 text-semibold control-label"
                          [translate]="'resources.reports.form.fields.prediction_percentage_redemption'"></label>
                        <app-question [question]="getInputConfig('prediction_percentage_redemption')"
                          [form]="reportIncrementalForm"></app-question>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="prediction_coupon_discount" class="mrg-top-10 text-semibold control-label">
                          {{'resources.reports.form.fields.prediction_coupon_discount' | translate}}({{currencySymbol}})
                        </label>
                        <app-question [question]="getInputConfig('prediction_coupon_discount')" [form]="reportIncrementalForm">
                        </app-question>
                      </div>
                    </div>
                  </div>
                </ng-container>

                  <!-- END CAMPAIGNS -->

                  <div class="row mrg-top-20">
                    <div class="col-md-6">
                      <span class="font-size-16 card-title" [translate]="'resources.reports.form.fields.incremental_expenditure'"></span>
                    </div>
                    <div class="col-md-6">
                      <span class="font-size-16 card-title" [translate]="'resources.reports.form.fields.affectation'"></span>
                      <span class="text-danger">*</span>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('analysis_type')" [form]="reportIncrementalForm">
                        </app-question>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <app-question [question]="getInputConfig('affectation')" [form]="reportIncrementalForm"></app-question>
                      </div>
                      <div class="form-group">
                        <app-question *ngIf="hasFormKeyWithValue('affectation', 'attribute')" [question]="getInputConfig('features')" [form]="reportIncrementalForm"></app-question>
                      </div>
                    </div>
                  </div>

                </form>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <span><i class="fa fa-spinner fa-spin" *ngIf="loading"></i></span>
          <button class="btn btn-info" (click)="returnToList()" [translate]="'resources.reports.buttons.back'"></button>
          <button class="btn btn-info" (click)="sendData()" [translate]="'resources.reports.buttons.send'"></button>
        </div>
			</div>
    </div>
  </div>
</div>
