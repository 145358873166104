<ng-container *ngIf="type === 'challenge'; else tiersBlock">
  <div class="alert alert-info" role="alert">
    <span class="alert-text">
      {{'resources.scores.challenges.warnings.challenge_info' | translate}}
    </span>
  </div>

  <div class="row" *ngIf="tiersDefinitionForm">
    <div class="col-md-6">
      <div class="card">
        <div class="card-heading border bottom">
          <h4 class="card-title text-bold">{{'resources.scores.tiers-management.fields.localized_content' | translate}}</h4>
        </div>
        <div class="card-block">
          <app-language-accordions [inputs]="inputs"
                                   [form]="tiersDefinitionForm"
                                   [mode]="mode"
                                   [filteredAvailableLanguages]="filteredAvailableLanguages">
          </app-language-accordions>
        </div>
      </div>
      <!-- PARTNERS -->
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-heading border bottom">
              <h4 class="card-title text-bold">{{'resources.scores.challenges.fields.partners' | translate}}</h4>
            </div>
            <div class="card-body no-pdd-btm">
              <div class="alert alert-info" role="alert">
                <span class="alert-text">
                  {{'resources.scores.challenges.warnings.partner_info' | translate}}
                </span>
              </div>
              <label for="partner_id">{{'resources.scores.challenges.fields.partners' | translate}}</label>
              <app-question [question]="getInputConfig('partner_id')" [form]="tiersDefinitionForm"></app-question>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DEFINITION -->
    <div class="col-md-6">
      <div class="card">
        <div class="card-heading border bottom">
          <h4 class="card-title text-bold">{{'resources.scores.tiers-management.fields.definition' | translate}}</h4>
        </div>
        <div class="card-body no-pdd-btm">
          <div class="form-group">
            <!-- IMAGE -->
            <img *ngIf="tierDefinition?.challenge_definition?.image" class="imgAlignment" src="{{tierDefinition.challenge_definition.image}}" alt="thumbnail">
            <label for="image">{{'resources.scores.challenges.fields.image' | translate}}</label>
            <app-question [question]="getInputConfig('image')" (change)="onFileChange($event)" [form]="tiersDefinitionForm"></app-question>
            <!--  -->
            <!-- CHALLENGE TYPE -->
            <label for="challenge_type">{{'resources.scores.challenges.fields.type' | translate}}</label>
            <app-question [question]="getInputConfig('challenge_type')" [form]="tiersDefinitionForm"></app-question>
            <!--  -->
            <!-- AVAILABLE FROM/TO -->
            <div class="row">
              <div class="col-md-6">
                <label for="available_from">
                  {{'resources.scores.challenges.fields.available_from' | translate}}
                </label>
                <i class="ti-info-alt mrg-left-5" tooltipPosition="top" pTooltip="{{ 'resources.scores.challenges.messages.available_tooltip' | translate }}"></i>
                <app-question [question]="getInputConfig('available_from')" [form]="tiersDefinitionForm"></app-question>
              </div>

              <div class="col-md-6">
                <label for="available_to">{{'resources.scores.challenges.fields.available_to' | translate}}</label>
                <app-question [question]="getInputConfig('available_to')" [form]="tiersDefinitionForm"></app-question>
              </div>
            </div>
            <!--  -->
            <!-- DISPLAY FROM/TO -->
            <div class="row">
              <div class="col-md-6">
                <label for="display_from">
                  {{'resources.scores.challenges.fields.display_from' | translate}}
                </label>
                <i class="ti-info-alt mrg-left-5" tooltipPosition="top" pTooltip="{{ 'resources.scores.challenges.messages.display_tooltip' | translate }}"></i>
                <app-question [question]="getInputConfig('display_from')" [form]="tiersDefinitionForm"></app-question>
              </div>

              <div class="col-md-6">
                <label for="display_to">
                  {{'resources.scores.challenges.fields.display_to' | translate}}
                </label>
                <app-question [question]="getInputConfig('display_to')" [form]="tiersDefinitionForm"></app-question>
              </div>
            </div>
            <!--  -->
            <!-- CUSTOMER ATTRIBUTE -->
            <label for="customer_attribute">{{'resources.scores.tiers-management.fields.customer_attribute' | translate}}</label>
            <app-question [question]="getInputConfig('customer_attribute')" [form]="tiersDefinitionForm" (multiselectChanged)="displaySelectedAttribute($event)"></app-question>
            <div *ngIf="customerAttributeInfo" class="alert alert-info" role="alert">
              <p class="text-bold">{{'resources.scores.tiers-management.fields.data_period' | translate}}: {{customerAttributeInfo.data_period_type}}</p>
              <p class="text-bold">{{'resources.scores.tiers-management.fields.recurrence' | translate}}: {{customerAttributeInfo.recurrence}}</p>
            </div>
            <!--  -->
            <!-- COUNTRIES -->
            <label for="countries" [translate]="'resources.scores.tiers-management.fields.countries'"></label>
            <i class="tooltip-position ti-info-alt mrg-top-5" tooltipPosition="right"
                pTooltip="{{ 'resources.scores.challenges.tooltips.countries' | translate }}">
            </i>
            <app-question [question]="getInputConfig('countries')" [form]="tiersDefinitionForm"></app-question>
            <!--  -->
            <!-- STATUS -->
            <label for="active" [translate]="'resources.scores.challenges.fields.status'"></label>
            <i class="tooltip-position ti-info-alt mrg-top-5" tooltipPosition="right"
                pTooltip="{{ 'resources.scores.challenges.tooltips.status' | translate }}">
            </i>
            <app-question [question]="getInputConfig('active')" [form]="tiersDefinitionForm"></app-question>
            <!--  -->
          </div>

        </div>
      </div>

      <!-- EXPIRATION POLICY -->
      <div class="card">
        <div class="card-heading border bottom">
          <h4 class="card-title text-bold">{{'resources.scores.tiers-management.fields.expiration_policy' | translate}}</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="expiration" [translate]="'resources.scores.tiers-management.fields.expiration'"></label>
            <app-question [question]="getInputConfig('expiration')" [form]="tiersDefinitionForm"></app-question>
            <div class="col-md-4 no-pdd" *ngIf="hasFormKeyWithValue('expiration', 'relative_months')">
              <label for="expiration_relative_value" [translate]="'resources.scores.tiers-management.fields.expiration_after'"></label>
              <app-question [question]="getInputConfig('expiration_relative_value')" [form]="tiersDefinitionForm"></app-question>
            </div>
            <div class="col-md-4 no-pdd" *ngIf="hasFormKeyWithValue('expiration', 'relative_days')">
              <label for="expiration_relative_value" [translate]="'resources.scores.tiers-management.fields.expiration_after'"></label>
              <app-question [question]="getInputConfig('expiration_relative_value')" [form]="tiersDefinitionForm"></app-question>
            </div>
          </div>
        </div>
      </div>

      <!-- LIMITS -->
      <div class="card">
        <div class="card-heading border bottom">
          <h4 class="card-title text-bold">{{'resources.scores.challenges.fields.limits' | translate}}</h4>
        </div>
        <div class="card-body no-pdd-btm">
          <div class="alert alert-info" role="alert">
            <span class="alert-text">
              {{'resources.scores.challenges.warnings.limits' | translate}}
            </span>
          </div>
          <div class="form-group">
            <app-question [question]="getInputConfig('open_to_all')" [form]="tiersDefinitionForm"></app-question>
            <ng-container *ngIf="tiersDefinitionForm.value.open_to_all">
              <div class="row">
                <div class="col-md-6">
                  <label for="enrollments">
                    {{'resources.scores.challenges.fields.enrollments' | translate}}
                  </label>
                  <span *ngIf="getInputConfig('enrollments').required" class="text-danger">*</span>
                  <app-question [question]="getInputConfig('enrollments')" [form]="tiersDefinitionForm"></app-question>
                </div>
                <div class="col-md-6">
                  <label for="max_enrollments">
                    {{'resources.scores.challenges.fields.max_subscriptions' | translate}}
                  </label>
                  <span *ngIf="getInputConfig('max_enrollments').required" class="text-danger">*</span>
                  <app-question [question]="getInputConfig('max_enrollments')" [form]="tiersDefinitionForm"></app-question>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- CHALLENGES -->
      <div class="card">
        <div class="card-heading border bottom">
          <h4 class="card-title text-bold">{{'menu_dia.loyalty_management.sections.challenges' | translate}}</h4>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-6">
              <label for="level_name">{{'resources.scores.challenges.fields.name' | translate}}</label>
              <span *ngIf="getInputConfig('level_name').required" class="text-danger">*</span>
              <app-question [question]="getInputConfig('level_name')" [form]="tiersDefinitionForm"></app-question>
            </div>
            <div class="col-md-6">
              <label for="threshold_challenge">{{'resources.scores.challenges.fields.level' | translate}}</label>
              <span *ngIf="getInputConfig('threshold_challenge').required" class="text-danger">*</span>
              <app-question [question]="getInputConfig('threshold_challenge')" [form]="tiersDefinitionForm"></app-question>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>

<!-- TIERS TEMPLATE -->
<ng-template #tiersBlock>
  <ng-container *ngIf="tiersDefinitionForm">
    <div class="card">
      <div class="card-heading border bottom">
        <h4 class="card-title text-bold">{{'resources.scores.tiers-management.fields.definition' | translate}}</h4>
      </div>
      <div class="card-body no-pdd-btm">
        <ng-container *ngIf="flags.challengesAvailableLanguagesVisibility && type === 'challenge' ">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <app-language-accordions [inputs]="inputs"
                                        [form]="tiersDefinitionForm"
                                        [mode]="mode"
                                        [filteredAvailableLanguages]="filteredAvailableLanguages">
                </app-language-accordions>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-group">
          <!-- NAME -->
          <label for="name">{{'common.name' | translate}}</label>
          <app-question *ngIf="type === 'tier'" [question]="getInputConfig('name')" [form]="tiersDefinitionForm"></app-question>
          <!-- DESCRIPTION -->
          <label for="description">{{'resources.scores.tiers-management.fields.description' | translate}}</label>
          <app-question *ngIf="type === 'tier'" [question]="getInputConfig('description')" [form]="tiersDefinitionForm"></app-question>
          <!-- CUSTOMER ATTRIBUTE -->
          <label for="customer_attribute">{{'resources.scores.tiers-management.fields.customer_attribute' | translate}}</label>
          <app-question [question]="getInputConfig('customer_attribute')" [form]="tiersDefinitionForm" (multiselectChanged)="displaySelectedAttribute($event)"></app-question>
          <div *ngIf="customerAttributeInfo" class="alert alert-info" role="alert">
            <p class="text-bold">{{'resources.scores.tiers-management.fields.data_period' | translate}}: {{customerAttributeInfo.data_period_type}}</p>
            <p class="text-bold">{{'resources.scores.tiers-management.fields.recurrence' | translate}}: {{customerAttributeInfo.recurrence}}</p>
          </div>
          <!-- COUNTRIES -->
          <label for="countries" [translate]="'resources.scores.tiers-management.fields.countries'"></label>
          <i class="tooltip-position ti-info-alt mrg-top-5" tooltipPosition="right"
              pTooltip="{{ 'resources.scores.tiers-management.tooltips.countries' | translate }}">
          </i>
          <app-question [question]="getInputConfig('countries')" [form]="tiersDefinitionForm"></app-question>
          <!-- STATUS -->
          <label for="active" [translate]="'resources.scores.challenges.fields.status'"></label>
            <i class="tooltip-position ti-info-alt mrg-top-5" tooltipPosition="right"
                pTooltip="{{ 'resources.scores.tiers-management.tooltips.status' | translate }}">
            </i>
          <app-question [question]="getInputConfig('active')" [form]="tiersDefinitionForm"></app-question>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-heading border bottom">
        <h4 class="card-title text-bold">{{'resources.scores.tiers-management.fields.expiration_policy' | translate}}</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="expiration" [translate]="'resources.scores.tiers-management.fields.expiration'"></label>
          <app-question [question]="getInputConfig('expiration')" [form]="tiersDefinitionForm"></app-question>
          <div class="col-md-4 no-pdd" *ngIf="hasFormKeyWithValue('expiration', 'relative_months')">
            <app-question [question]="getInputConfig('expiration_relative_value')" [form]="tiersDefinitionForm"></app-question>
          </div>
          <div class="col-md-4 no-pdd" *ngIf="hasFormKeyWithValue('expiration', 'relative_days')">
            <app-question [question]="getInputConfig('expiration_relative_value')" [form]="tiersDefinitionForm"></app-question>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
