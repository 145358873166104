import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { PlansService } from '../plans.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, firstValueFrom, noop } from 'rxjs';
import { Plan } from '../plan';
import { isNullOrUndefined } from '../../../shared/utils/common.utils';
import { Router } from '@angular/router';
import { DateService } from '../../../shared/services/date.service';
import { FeatureFlagsService } from './../../../shared/services/feature-flags.service';
import { CsvExportService } from '../../../shared/services/csv-export.service';
import { S3Service } from '../../../shared/services/s3.service';
import * as moment from 'moment';

type AnalyzeResponse = {
  success: boolean;
  errors: {
    customer_ids: string[];
    product_ids: string[];
    discount: string[];
  };
  actions_exceeded: boolean;
};

@Component({
  selector: 'app-plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.css']
})
export class PlanFormComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[];
  planForm: UntypedFormGroup;
  subs$: Subscription[] = [];
  actionsCSVexceeded = false;
  csvPath: string;
  loading = false;
  flags = this.featureFlags.flags;

  @Input() plan: Plan;
  @Output() planEmitter = new EventEmitter<Plan>();

  constructor(
    private qcs: QuestionControlService,
    private plansService: PlansService,
    private modalStatusService: ModalStatusService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private router: Router,
    private dateService: DateService,
    private featureFlags: FeatureFlagsService,
    private http: HttpClient,
    private csvExportService: CsvExportService,
    private s3Service: S3Service
  ) { }

  ngOnInit() {
    this.inputs = this.plansService.getInputs(this.plan);
    this.planForm = this.qcs.toFormGroup(this.inputs);
    this.handlePrintDisabled();
    this.handleFormChanges();
    this.planForm.setValidators(this.getValidators());
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  save() {
    this.loading = true;
    const payload = this.getPayload();
    this.modalStatusService.formLoaderStatus.emit('loading');
    const plan$ = this.getRequestMethod(payload, this.plan).subscribe({
      next: (plan: Plan) => {
        this.handleSuccessResponse(plan);
      },
      error: (errorData: HttpErrorResponse) => {
        this.handleErrorResponse(errorData);
      }
    });
    this.subs$.push(plan$);
  }

  markAsDirty(str: string) {
    this.planForm.get(str).markAsDirty();
  }

  handleFormChanges() {
    const availableTo = this.planForm.get('available_to');
    const availableFrom = this.planForm.get('available_from');
    const printTo = this.planForm.get('print_to');
    const printFrom = this.planForm.get('print_from');
    const printDisabled = this.planForm.get('print_disabled');
    const csv = this.planForm.get('csv');
    const createdByCsv = this.planForm.get('created_by_csv');

    this.subs$.push(
      // Update 'print_from' based on 'available_from' and 'print_disabled'
      availableFrom.valueChanges.subscribe(
        (availableFromValue) => printFrom.setValue(printDisabled.value ? '' : availableFromValue)
      ),
      // Sync 'print_to' with 'available_to', considering 'print_disabled'
      availableTo.valueChanges.subscribe(
        (availableToValue) => {
          if (printDisabled.value) {
            printTo.setValue(null);
          } else {
            const calculatedDate = calculateDate(availableToValue);
            adjustPrintToMinMaxDates(printFrom.value, calculatedDate);
            printTo.setValue(calculatedDate);
          }
        }
      ),
      createdByCsv.valueChanges.subscribe(
        (createByCSV: boolean) => setDisabledFields(['csv'], !createByCSV)
      ),
      csv.valueChanges.subscribe(
        (csv) => csv && csv.length > 0 ? this.uploadToS3(createdByCsv.value) : noop
      ),
      printDisabled.valueChanges.subscribe(
        (printDisabled) => setDisabledFields(['print_from', 'print_to'], printDisabled)
      ),
      printFrom.valueChanges.subscribe(
        (printFromValue) => adjustPrintToMinMaxDates(printFromValue, calculateDate(availableTo.value))
      ),
      printTo.valueChanges.subscribe(
        (printToValue) => {
          const isValid = this.dateService.isDate2BeforeDate1(printToValue, printFrom.value);
          if (!isValid) {
            adjustPrintToMinMaxDates(printFrom.value, printFrom.value);
          }
        }
      )
    );

    // Helper Functions
    const calculateDate = (date: Date) => this.dateService.calculateDate('substract', 4, 'days', date);
    const adjustPrintToMinMaxDates = (minDate: string, maxDate: string) => {
      const printToInput = this.inputs.find((item) => item.key === 'print_to');
      printToInput.minDate = minDate ?? null;
      printToInput.maxDate = maxDate;
    }
    const setDisabledFields = (fields: string[], disable: boolean) => {
      fields.forEach(field => {
        const formField = this.planForm.get(field);
        const fieldInput = this.inputs.find(input => input.key === field);

        if (disable) {
          formField.disable();
          formField.setValue(null);
          fieldInput.required = false;
        } else {
          formField.enable();
          fieldInput.required = true;
        }
      });
    };

    // Initial State Configuration
    setDisabledFields(['csv'], true);
  }

  private handlePrintDisabled() {
    const printFromInput = this.inputs.find((item => item.key === 'print_from'));
    const printToInput = this.inputs.find((item => item.key === 'print_to'));
    if (this.planForm?.value.print_disabled) {
      printFromInput.disabled = true;
      printToInput.disabled = true;
    }

    if (this.planForm && !this.planForm.value.name) {
      this.planForm.get('print_disabled').patchValue(false);
      printFromInput.disabled = false;
      printToInput.disabled = false;
    }
  }

  private getPayload(): object {
    const { name, available_from, available_to, print_disabled, print_from = '', print_to = '', created_by_csv } = this.planForm.value;

    const payload = {
      name,
      available_from,
      available_to,
      print_disabled,
      print_from,
      print_to,
      created_by_csv
    };

    if (created_by_csv) {
      payload['csv_path'] = this.csvPath;
    }

    return payload;
  }

  private getRequestMethod(payload: object, plan?: Plan): Observable<any> {
    return isNullOrUndefined(plan) ? this.plansService.createPlan(payload) : this.plansService.updatePlan(`${plan.id}`, payload);
  }

  private handleSuccessResponse(plan: Plan) {
    this.handleRequestFinished();
    let successTitle = this.translate.instant('resources.plans.messages.created_success_title');
    let successText = plan.created_by_csv
      ? this.translate.instant('resources.plans.messages.created_success_csv_text')
      : this.translate.instant('resources.plans.messages.created_success_text');
    if (!isNullOrUndefined(this.plan)) {
      successTitle = this.translate.instant('resources.plans.messages.updated_success_title');
      successText = this.translate.instant('resources.plans.messages.updated_success_text');
    }
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => { });
    this.plan = new Plan(plan);
    this.planEmitter.emit(this.plan);
    this.router.navigate([{ outlets: { modal: `show/plans/${plan.id}` } }]).catch(() => { });
  }

  private handleErrorResponse(errorData: HttpErrorResponse) {
    this.handleRequestFinished();
    if (errorData?.error?.error && !errorData.error.errors) {
      this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
    } else if (errorData?.error?.errors?.length > 0) {
      this.qcs.paintErrorsInForm(this.inputs, this.planForm, errorData.error.errors);
    } else {
      this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.message);
    }
  }

  private handleRequestFinished() {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.loading = false;
  }

  private getValidators(): ValidatorFn[] {
    return [
      (form: UntypedFormGroup) => {
        const from = form.get('available_from');
        const to = form.get('available_to');
        if (this.dateService.isDate2BeforeDate1(from.value, to.value)) {
          return this.setError(to, { invalidDateRange: true });
        } else {
          to.setErrors(null);
        }
        form.controls['print_from'].setErrors(null);
        form.controls['print_to'].setErrors(null);
        return null;
      },
      (form: UntypedFormGroup) => {
        const printFrom = form.get('print_from');
        const printTo = form.get('print_to');
        if (this.dateService.isDate2BeforeDate1(printFrom.value, printTo.value)) {
          return this.setError(printTo, { invalidDateRange: true });
        } else {
          printTo.setErrors(null);
        }
        form.controls['print_from'].setErrors(null);
        form.controls['print_to'].setErrors(null);
        return null;
      },
      (form: UntypedFormGroup) => {
        const from = form.get('available_from');
        if (!this.dateService.isValidDate(from.value)) {
          return this.setError(from, { invalidDate: true });
        } else {
          form.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const to = form.get('available_to');
        if (!this.dateService.isValidDate(to.value)) {
          return this.setError(to, { invalidDate: true });
        } else {
          form.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const name = form.get('name');
        const printDisabled = form.get('print_disabled');
        const printFrom = form.get('print_from');
        if (name && !printDisabled.value && !printFrom.value) {
          return this.setError(printFrom, { invalidDate: true });
        } else {
          form.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const name = form.get('name');
        const printDisabled = form.get('print_disabled');
        const printTo = form.get('print_to');
        if (name && !printDisabled.value && !printTo.value) {
          return this.setError(printTo, { invalidDate: true });
        } else {
          form.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const printFrom = form.get('print_from');
        const printDisabled = form.get('print_disabled');
        if (!this.dateService.isValidDate(printFrom.value) && !printDisabled) {
          return this.setError(printFrom, { invalidDate: true });
        } else {
          form.setErrors(null);
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const printDisabled = form.get('print_disabled');
        const printTo = form.get('print_to');
        if (!this.dateService.isValidDate(printTo.value) && !printDisabled) {
          return this.setError(printTo, { invalidDate: true });
        } else {
          form.setErrors(null);
        }
        return null;
      }
    ];
  }

  private setError(control: AbstractControl, errorHash: object): object {
    control.setErrors(errorHash);
    return errorHash;
  }

  // ------------------ CSV ------------------
  downloadTemplate() {
    this.http.get('plans/csv_template', { responseType: 'text' as 'json' }).subscribe(data => {
      this.downloadCSV(data);
    });
  }
  private downloadCSV(data) {
    const blob = this.csvExportService.getCsvBlob(data);
    const url = window.URL.createObjectURL(blob);
    const filename = `csv_template.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  private async uploadToS3(csvValue: boolean): Promise<void> {
    if (!csvValue){ return; }
    this.resetCSVState();
    try {
      const file = await this.getCSVFile();
      const uploadedFile = await this.s3Service.uploadFile(file);
      await this.analyzeCSV(uploadedFile);
    } catch (err) {
      this.handleCSVError(err);
    }
  }

  private resetCSVState(): void {
    this.actionsCSVexceeded = false;
    this.csvPath = null;
    this.loading = true;
  }

  private getCSVFile(): Promise<File> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const [file] = this.planForm.value.csv;
        const timestamp = moment().format('YYYYMMDD_HHmm');
        const filename = `plans_recommended_campaign_${timestamp}_${file.name}`;
        resolve(new File([file], filename, { type: file.type }));
      });
    });
  }

  private async analyzeCSV(file: string): Promise<void> {
    const res: AnalyzeResponse = await firstValueFrom(this.plansService.analyzeCSV({ file }));
    this.actionsCSVexceeded = res.actions_exceeded;
    this.csvPath = file;
    this.loading = false;
  }

  private handleCSVError(err: any): void {
    this.confirmationService.displayHttpErrorAlert(err);
    this.planForm.get('csv').setValue(null);
    this.loading = false;
  }

}
