import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { ResourcesService } from '../resources.service';

@Injectable({
  providedIn: 'root'
})
export class TriggerTypesService extends ResourcesService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  getTriggerTypes(): Observable<object> {
    const requestOptions = {
      apiEndPoint: '/trigger_types',
      numberPerPage: 300,
      filtering: {},
      sorting: {
        byProp: '',
        direction: ''
      }
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'trigger_types',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }
    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `trigger_types/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    const name = this.parseSlugToName(element.slug);
    return new MultiselectDataSource(element.id, name, element);
  }

  private parseSlugToName(slug: string): string {
    let name = slug;
    name = name.replace(/-/g, ' ');
    name = name.replace(/_/g, ' ');
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  }
}
