import { AceModule } from 'ngx-ace-wrapper';
import { ActivityTabComponent } from '../resources/analytics/migrations/tabs/activity/activity-tab.component';
import { AnalyticsLegendComponent } from './modals/analytics-legend/analytics-legend.component';
import { AnalyticsService } from './services/analytics/analytics.service';
import { ApiKeyDetailComponent } from '../resources/api/keys/api-keys-detail/api-keys-detail.component';
import { ApiKeyFormComponent } from '../resources/api/keys/api-keys-form/api-keys-form.component';
import { ApiKeyService } from '../resources/api/keys/api-key-service';
import { ApiService } from '../resources/api/api.service';
import { AttributesConditionsService } from './services/attributes-conditions.service';
import { AttributesCustomEntityConditionComponent } from './components/create-update-segments-v2/segment-condition/custom-entity-condition/components/attributes-custom-entity-condition/attributes-custom-entity-condition.component';
import { AttributesListCustomEntityConditionComponent } from './components/create-update-segments-v2/segment-condition/custom-entity-condition/components/attributes-list-custom-entity-condition/attributes-list-custom-entity-condition.component';
import { AudienceContentComponent } from '../resources/plans/campaigns/custom-campaign-plan-audience/audience-content/audience-content.component';
import { AudienceCouponRecommenderComponent } from '../resources/plans/campaigns/custom-campaign-plan-audience/audience-coupon-recommender/audience-coupon-recommender.component';
import { AudienceDatesComponent } from '../resources/plans/campaigns/custom-campaign-plan-audience/audience-dates/audience-dates.component';
import { AudienceSelectorComponent } from '../resources/segments/audience-selector/audience-selector.component';
import { AvailableScoreConditionService } from './services/conditions/services/available-score-condition/available-score-condition.service';
import { AvailableScoreSegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/available-score-segment-condition/available-score-segment-condition.component';
import { AverageActivityConditionComponent } from './components/create-update-segments-v2/segment-condition/average-activity-condition/average-activity-condition.component';
import { AverageActivityConditionService } from './services/conditions/average-activity-condition.service';
import { BigqueryJobsComponent } from '../resources/config/bigquery-jobs/bigquery-jobs.component';
import { BigqueryjobsService } from '../resources/config/bigquery-jobs/bigqueryjobs.service';
import { CalculatedValuesBuyerTypesSegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/calculated-values-buyer-types-segment-condition/calculated-values-buyer-types-segment-condition.component';
import { CalculatedValuesBuyerTypesSegmentConditionService } from './services/conditions/calculated-values-buyer-types-segment-condition.service';
import { CalculationSectionComponent } from '../resources/data-warehouse/customer-attributes/components/form-customer-attributes/calculation-section/calculation-section.component';
import { CamelCasePipe } from './pipes/camel-case/camel-case.pipe';
import { CampaignCategoriesService } from '../resources/campaigns/campaign-categories/campaign-categories.service';
import { CampaignConditionComponent } from './components/create-update-segments-v2/segment-condition/campaign-condition/campaign-condition.component';
import { CampaignConditionService } from './services/conditions/campaign-condition.service';
import { CampaignContentBlockComponent } from './components/create-update-campaigns-dynamic-form/blocks/campaign-content-block/campaign-content-block.component';
import { CampaignDefinitionBlockComponent } from './components/create-update-campaigns-dynamic-form/blocks/campaign-definition-block/campaign-definition-block.component';
import { CampaignInputComponent } from '../resources/analytics/reports/edit-report/incremental/campaign-input/campaign-input.component';
import { CampaignReportBlockComponent } from './components/create-update-campaigns-dynamic-form/blocks/campaign-report-block/campaign-report-block.component';
import { CampaignSegmentBlockComponent } from './components/create-update-campaigns-dynamic-form/blocks/campaign-segment-block/campaign-segment-block.component';
import { CampaignSelectionComponent } from '../resources/plans/plan-campaign-new/campaign-selection.component';
import { CampaignsSenderService } from './services/campaigns-sender.service';
import { CampaignTemplatesService } from './components/create-update-templates/service/campaign-templates-service.service';
import { CampaignValidateModalComponent } from '../resources/campaigns/campaign-validate-modal/campaign-validate-modal.component';
import { CampaignValidationBlockComponent } from './components/create-update-campaigns-dynamic-form/blocks/campaign-validation-block/campaign-validation-block.component';
import { CardPlan1Component } from './components/cards/card-plan1/card-plan1.component';
import { CardPlan2Component } from './components/cards/card-plan2/card-plan2.component';
import { CardRecipeComponent } from './components/cards/card-recipe/card-recipe.component';
import { CashbackComponent } from '../resources/config/loyalty/cashback/cashback.component';
import { CashBackService } from '../resources/config/loyalty/cashback/cashback.service';
import { ChipModule } from 'primeng/chip';
import { CityConditionComponent } from './components/create-update-segments-v2/segment-condition/city-condition/city-condition.component';
import { CityConditionService } from './services/conditions/services/city-condition/city-condition.service';
import { ClientBirthdayConditionService } from './services/conditions/client-birthday-condition.service';
import { ClientCategoriesConditionComponent } from './components/create-update-segments-v2/segment-condition/client-categories-condition/client-categories-condition.component';
import { ClientCategoriesConditionService } from './services/conditions/client-categories-condition.service';
import { ClientEmailConditionService } from './services/conditions/client-email-condition.service';
import { ClientIdConditionComponent } from './components/create-update-segments-v2/segment-condition/client-id-condition/client-id-condition.component';
import { ClientIdConditionService } from './services/conditions/client-id-condition.service';
import { ClientRegistrationDateConditionService } from './services/conditions/services/client-registration-date-condition/client-registration-date-condition.service';
import { ClientsConstantTabComponent } from '../resources/analytics/club/tabs/clients-constant/clients-constant-tab.component';
import { ClientUsualLocationConditionComponent } from './components/create-update-segments-v2/segment-condition/client-usual-location-condition/client-usual-location-condition.component';
import { ClientUsualLocationConditionService } from './services/conditions/client-usual-location-condition.service';
import { ClubEvolutionComponent } from '../resources/analytics/club/tabs/club-evolution/club-evolution.component';
import { ClubIndicatorsTabComponent } from '../resources/analytics/club/tabs/club-indicators/club-indicators-tab.component';
import { CmsService } from '../resources/content-designer/campaign-news-list/cms.service';
import { CollectiveConditionComponent } from './components/create-update-segments-v2/segment-condition/collective-condition/collective-condition.component';
import { CollectiveConditionService } from './services/conditions/collective-condition.service';
import { CommonModule, CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { ConditionService } from './services/conditions/condition.service';
import { ConfigService } from './config/config.service';
import { ConfirmationService } from './services/confirmation.service';
import { ConnectorActionsService } from './services/connectors/connector-actions.service';
import { ConnectorsService } from './services/connectors/connectors.service';
import { ConsistenceTabComponent } from '../resources/analytics/customers/tabs/consistence/consistence-tab.component';
import { ContactsTabComponent } from '../resources/analytics/customers/tabs/contacts/contacts-tab.component';
import { CountryConditionComponent } from './components/create-update-segments-v2/segment-condition/country-condition/country-condition.component';
import { CountryConditionService } from './services/conditions/country-condition.service';
import { CouponConditionComponent } from './components/create-update-segments-v2/segment-condition/coupon-condition/coupon-condition.component';
import { CouponConditionService } from './services/conditions/coupon-condition.service';
import { CouponFormSectionComponent } from '../resources/data-warehouse/data-warehouse-coupons/form-coupons/components/coupon-form-section/coupon-form-section.component';
import { CouponFormSectionRowsComponent } from '../resources/data-warehouse/data-warehouse-coupons/form-coupons/components/coupon-form-section-row/coupon-form-section-rows.component';
import { CouponFormWarningsComponent } from '../resources/data-warehouse/data-warehouse-coupons/form-coupons/components/coupon-form-warnings/coupon-form-warnings.component';
import { CouponHeaderComponentComponent } from '../resources/data-warehouse/data-warehouse-coupons/form-coupons/components/coupon-header-component/coupon-header-component.component';
import { CouponsBlockComponent } from '../resources/data-warehouse/tickets/show/coupons-block/coupons-block.component';
import { CreateUpdateCampaignsDynamicFormComponent } from './components/create-update-campaigns-dynamic-form/create-update-campaigns-dynamic-form.component';
import { CreateUpdateCustomCustomerJourneyComponent } from './components/create-update-customer-journeys-dynamic-form/journey-custom/create-update-custom-customer-journey.component';
import { CreateUpdateCustomerJourneysDynamicFormComponent } from './components/create-update-customer-journeys-dynamic-form/create-update-customer-journeys-dynamic-form.component';
import { CreateUpdateLandingsComponent } from './components/create-update-landings/selection-landings.component';
import { CreateUpdateLandingsService } from './components/create-update-landings/service/create-update-landings.service';
import { CreateUpdateTemplatesComponent } from './components/create-update-templates/create-update-templates.component';
import { CreditAmountInfoComponent } from '../resources/config/company/add-card/credit-amount-info/credit-amount-info.component';
import { CsvExportService } from './services/csv-export.service';
import { CustomCampaignPlanAudienceComponent } from '../resources/plans/campaigns/custom-campaign-plan-audience/custom-campaign-plan-audience.component';
import { CustomCampaignPlanDefinitionComponent } from '../resources/plans/campaigns/custom-campaign-plan-definition/custom-campaign-plan-definition.component';
import { CustomCampaignPlanForecastComponent } from '../resources/plans/campaigns/custom-campaign-plan-forecast/custom-campaign-plan-forecast.component';
import { CustomConditionService } from './services/conditions/custom-condition.service';
import { CustomEntityConditionComponent } from './components/create-update-segments-v2/segment-condition/custom-entity-condition/custom-entity-condition.component';
import { CustomEntityConditionService } from './services/conditions/services/custom-entity-condition/custom-entity-condition.service';
import { CustomEntitiesService } from '../resources/data-warehouse/custom-entities/custom-entities.service';
import { CustomerAttributeConditionComponent } from './components/create-update-segments-v2/segment-condition/customer-attribute-condition/customer-attribute-condition.component';
import { CustomerAttributeConditionService } from './services/conditions/services/customer-attribute-condition/customer-attribute-condition.service';
import { CustomerAttributesComponent } from '../resources/data-warehouse/customer-attributes/customer-attributes.component';
import { CustomerAttributesInputsService } from '../resources/data-warehouse/customer-attributes/services/customer-attributes-inputs/customer-attributes-inputs.service';
import { CustomerAttributesOptionsService } from '../resources/data-warehouse/customer-attributes/services/customer-attributes-options/customer-attributes-options.service';
import { CustomerAttributesService } from '../resources/data-warehouse/customer-attributes/services/customer-attributes/customer-attributes.service';
import { CustomerChannelStatusConditionService } from './services/conditions/customer-channel-status-condition.service';
import { CustomerChannelStatusSegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/customer-channel-status-segment-condition/customer-channel-status-segment-condition.component';
import { CustomerFusionComponent } from '../resources/data-warehouse/profiles/show/tabs/summary/action-modals/customer-fusion/customer-fusion.component';
import { CustomerJourneysHistoriesService } from '../resources/customer-journeys/customer-journeys-histories.service';
import { CustomersComponent } from '../resources/analytics/customers/customers.component';
import { CustomersService } from '../resources/analytics/customers/customers.service';
import { CustomersTabComponent } from '../resources/analytics/customers/tabs/customers/customers-tab.component';
import { CustomersTypologyComponent } from '../resources/analytics/club/tabs/customers-typology/customers-typology.component';
import { CustomSegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/custom-segment-condition/custom-segment-condition.component';
import { DashCategoriesService } from '../resources/analytics/categories/dash-categories.service';
import { DashPromotionsService } from '../resources/analytics/promotions/dashPromotions.service';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DataTableFilterAnalyticsComponent } from './components/data-table-filter/data-table-filter-analytics/data-table-filter-analytics.component';
import { DataTableFilterComponent } from './components/data-table-filter/data-table-filter.component';
import { DataTableFilterService } from './components/data-table-filter/data-table-filter.service';
import { DataWarehouseCouponsComponent } from '../resources/data-warehouse/data-warehouse-coupons/data-warehouse-coupons.component';
import { DateService } from './services/date.service';
import { DateTimeZonePipe } from './pipes/date-time-zone.pipe';
import { DateTimeZoneService } from './services/date-time-zone.service';
import { DetailsBlockComponent } from '../resources/data-warehouse/tickets/show/details-block/details-block.component';
import { DndModule } from 'ngx-drag-drop';
import { DumpTablesService } from './services/dump-tables.service';
import { DynamicBaseFiltersService } from './components/dynamic-tabs/services/dynamic-base-filters.service';
import { DynamicDateInputComponent } from './components/dynamic-date-input/dynamic-date-input.component';
import { DynamicFormQuestionComponent } from './components/dynamic-form-question/dynamic-form-question.component';
import { DynamicTabsComponent } from './components/dynamic-tabs/dynamic-tabs.component';
import { EditIncrementalComponent } from '../resources/analytics/reports/edit-report/incremental/edit-incremental.component';
import { EditReportComponent } from '../resources/analytics/reports/edit-report/edit-report.component';
import { EditROIByStoreComponent } from '../resources/analytics/reports/edit-report/roi-by-store/edit-roi-by-store.component';
import { EditSegmentEvoComponent } from '../resources/analytics/reports/edit-report/segment-evo/edit-segment-evo.component';
import { EmailImpactConditionComponent } from './components/create-update-segments-v2/segment-condition/email-impact-condition/email-impact-condition.component';
import { EmailTemplateEditComponent } from '../resources/content-designer/campaign-templates/email-template-edit/email-template-edit.component';
import { EmailTemplatesService } from './services/email-templates.service';
import { EmailTemplateViewComponent } from '../resources/content-designer/campaign-templates/email-template-view/email-template-view.component';
import { EmptyDataPipe } from './pipes/emptyData.pipe';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FeatureInputComponent } from '../resources/plans/campaigns/supplier-campaign-plan-definition/white-label-inputs/feature-input.component';
import { FeatureInternalsService } from '../resources/data-warehouse/products/feature-internals.service';
import { FeaturesService } from '../resources/data-warehouse/products/features.service';
import { FidelizationService } from '../resources/analytics/fidelization/fidelization.service';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FiltersService } from './components/save-filter/filters.service';
import { FluidHeightDirective } from './directives/fluid-height.directive';
import { ForecastContentComponent } from '../resources/plans/campaigns/custom-campaign-plan-forecast/forecast-content/forecast-content.component';
import { FormBigqueryJobsComponent } from '../resources/config/bigquery-jobs/form/form-bigquery-jobs.component';
import { FormCampaignsCategoriesComponent } from '../resources/campaigns/campaign-categories/form-campaigns-categories/form-campaigns-categories.component';
import { FormCashbackComponent } from '../resources/config/loyalty/cashback/form/form-cashback.component';
import { FormCmsComponent } from '../resources/content-designer/campaign-news-list/form/form-cms.component';
import { FormCompanyPromotionsComponent } from '../resources/data-warehouse/promotions/forms/form-promotional-discounts/form-promotional-discounts.component';
import { FormContactPoliciesComponent } from '../resources/config/contact-policies/form-contact-policies/form-contact-policies.component';
import { FormCouponsComponent } from '../resources/data-warehouse/data-warehouse-coupons/form-coupons/form-coupons.component';
import { FormCreditTransactionsComponent } from '../resources/config/credit-transactions/form-credit-transactions/form-credit-transactions.component';
import { FormCurrencyConversionsComponent } from '../resources/config/currencies/form-currency-conversions/form-currency-conversions.component';
import { FormCustomEntitiesComponent } from '../resources/data-warehouse/custom-entities/form-custom-entities/form-custom-entities.component';
import { FormCustomEntityComponent } from '../resources/scores/loyalty_scores/form-loyalty-scores/form-custom-entity/form-custom-entity.component';
import { FormCustomerAttributesComponent } from '../resources/data-warehouse/customer-attributes/components/form-customer-attributes/form-customer-attributes.component';
import { FormExecuteDateComponent } from '../resources/config/bigquery-jobs/show/form-execute-date/form-execute-date.component';
import { FormFeatureTaxonomiesComponent } from '../resources/data-warehouse/products/form-feature-taxonomies/form-feature-taxonomies.component';
import { FormLocationsComponent } from '../resources/data-warehouse/locations/form-locations/form-locations.component';
import { FormLocationsTaxonomiesComponent } from '../resources/data-warehouse/locations/form-locations-taxonomies/form-locations-taxonomies.component';
import { FormLocationTaxonomiesTermsComponent } from '../resources/data-warehouse/locations/form-location-taxonomies-terms/form-location-taxonomies-terms.component';
import { FormLoyaltyScoreMomentsComponent } from '../resources/scores/loyalty_scores/loyalty_scores_moments/form-loyalty-score-moments/form-loyalty-score-moments.component';
import { FormLoyaltyScoresComponent } from '../resources/scores/loyalty_scores/form-loyalty-scores/form-loyalty-scores.component';
import { FormMessageCategoriesComponent } from '../resources/content-designer/campaign-messages/form-message-categories/form-message-categories.component';
import { FormMessagesComponent } from '../resources/content-designer/campaign-messages/form-messages/form-messages.component';
import { FormNewsTaxonomiesComponent } from '../resources/content-designer/campaign-news-list/tabs/news-taxonomies/form/form-news-taxonomies.component';
import { FormNewsTaxonomyTermsComponent } from '../resources/content-designer/campaign-news-list/tabs/news-taxonomy-terms/form/form-news-taxonomy-terms.component';
import { FormPaymentMethodsComponent } from '../resources/config/company/payment-methods/form-payment-methods/form-payment-methods.component';
import { FormProductsComponent } from '../resources/data-warehouse/products/form-products/form-products.component';
import { FormProductsFeaturesComponent } from '../resources/data-warehouse/products/form-products-features/form-products-features.component';
import { FormProductsInternalCategoriesComponent } from '../resources/data-warehouse/products/form-products-internal-categories/form-products-internal-categories.component';
import { FormProfilesComponent } from '../resources/data-warehouse/profiles/form/form-profiles.component';
import { FormPromotionsComponent } from '../resources/data-warehouse/promotions/forms/form-promotions/form-promotions.component';
import { FormRecipesComponent } from '../resources/recipes/form-recipes/form-recipes.component';
import { FormReservedIdsComponent } from '../resources/config/reserved-ids/form/form-reserved-ids.component';
import { FormRewardCategoriesComponent } from '../resources/scores/rewards/reward-categories/form-reward-categories/form-reward-categories.component';
import { FormRewardsComponent } from '../resources/scores/rewards/form-rewards/form-rewards.component';
import { FormRewardTaxonomiesComponent } from '../resources/scores/rewards/reward-taxonomies/form-reward-taxonomies/form-reward-taxonomies.component';
import { FormScoreExchangesComponent } from '../resources/scores/score_exchanges/form-score-exchanges/form-score-exchanges.component';
import { FormSegmentCategoriesComponent } from '../resources/segments/form-segment-categories/form-segment-categories.component';
import { FormUsersComponent } from '../resources/users/form-users/form-users.component';
import { FormWalletsComponent } from '../resources/scores/wallets/form-wallets/form-wallets.component';
import { FullscreenModalContainerComponent } from './components/fullscreen-modal-container/fullscreen-modal-container.component';
import { GenderConditionComponent } from './components/create-update-segments-v2/segment-condition/gender-condition/gender-condition.component';
import { GenderConditionService } from './services/conditions/services/gender-condition/gender-condition.service';
import { GrantPointsComponent } from '../resources/data-warehouse/profiles/show/tabs/summary/action-modals/grant-points/grant-points.component';
import { GroupConditionService } from './services/conditions/group-condition.service';
import { IconInfoButtonComponent } from './components/icon-info-button/icon-info-button.component';
import { ImportCouponsComponent } from '../resources/data-warehouse/data-warehouse-coupons/import-coupons/import-coupons.component';
import { ImportLocationTaxonomyTermsComponent } from '../resources/data-warehouse/locations/import-location-taxonomy-terms/import-location-taxonomy-terms.component';
import { ImportProductCategoriesComponent } from '../resources/data-warehouse/products/import-product-categories/import-product-categories.component';
import { ImportProductsComponent } from './import-products/import-products.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { JourneyCampaignsBlockComponent } from './components/create-update-customer-journeys-dynamic-form/journey-campaigns-block/journey-campaigns-block.component';
import { JourneyContentBlockComponent } from './components/create-update-customer-journeys-dynamic-form/journey-content-block/journey-content-block.component';
import { JourneyDefinitionBlockComponent } from './components/create-update-customer-journeys-dynamic-form/journey-definition-block/journey-definition-block.component';
import { JourneySummaryBlockComponent } from './components/create-update-customer-journeys-dynamic-form/journey-summary-block/journey-summary-block.component';
import { LanguageAccordionsComponent } from './components/language-accordions/language-accordions.component';
import { LanguagesService } from './services/languages.service';
import { LargeModalContainerComponent } from './components/large-modal-container/large-modal-container.component';
import { LastPurchaseConditionComponent } from './components/create-update-segments-v2/segment-condition/last-purchase-condition/last-purchase-condition.component';
import { LastPurchaseConditionService } from './services/conditions/services/last-purchase-condition/last-purchase-condition.service';
import { LgCurrencyPipe } from './pipes/lg-currency.pipe';
import { LgLanguageTagPipe } from './pipes/lg-language-tag-translate.pipe';
import { LgMultilanguageTranslatePipe } from './pipes/lg-multilanguage-translate.pipe';
import { LinesBlockComponent } from '../resources/data-warehouse/tickets/show/lines-block/lines-block.component';
import { LoadCsvComponent } from '../resources/content-designer/campaign-messages/loadCSV/load-csv.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LocationsTaxonomyTermsService } from '../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { LocationTaxonomiesService } from '../resources/data-warehouse/locations/location-taxonomies.service';
import { LookerCfgGlobalFiltersService } from './components/looker-dashboard-filter/services/looker-cfg-global-filters/looker-cfg-global-filters.service';
import { LookerDashboardFilterComponent } from './components/looker-dashboard-filter/looker-dashboard-filter.component';
import { LookerGlobalFiltersService } from './components/looker-dashboard-filter/services/looker-global-filters/looker-global-filters.service';
import { LookerIFrameContainerComponent } from './components/looker-iframe-container/looker-iframe-container.component';
import { LookerNielsenFiltersService } from './components/looker-dashboard-filter/services/looker-nielsen-filters/looker-nielsen-filters.service';
import { MailConditionService } from './services/conditions/mail-condition.service';
import { MatrixService } from './services/matrix.service';
import { MaxSalesConditionComponent } from './components/create-update-segments-v2/segment-condition/max-sales-condition/max-sales-condition.component';
import { MaxSalesConditionService } from './services/conditions/max-sales-condition.service';
import { MessageCategoriesService } from '../resources/content-designer/campaign-messages/message-categories.service';
import { MessagesService } from '../resources/content-designer/campaign-messages/messages.service';
import { MigrationsFlowComponent } from '../resources/analytics/migrations/tabs/migrations-flow/migrations-flow.component';
import { MulticouponJourneyService } from './services/multicoupon-journey.service';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { MultiselectService } from './components/multiselect/multiselect.service';
import { NameConditionComponent } from './components/create-update-segments-v2/segment-condition/name-condition/name-condition.component';
import { NameConditionService } from './services/conditions/services/name-condition/name-condition.service';
import { NestedSegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/nested-segment-condition/nested-segment-condition.component';
import { NestedSegmentConditionService } from './services/conditions/nested-segment-condition.service';
import { NewCardComponent } from '../resources/data-warehouse/profiles/show/tabs/summary/action-modals/new-card/new-card.component';
import { NewsTaxonomiesService } from '../resources/content-designer/campaign-news-list/news-taxonomies.service';
import { NewsTaxonomyTermsService } from '../resources/content-designer/campaign-news-list/news-taxonomy-terms-service';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NotificationsService } from './services/notifications.service';
import { OpenInfoPanelDirective } from './directives/toggle-info-panel.directive';
import { OperatorsService } from './services/conditions/common/operators.service';
import { OrderByPipe } from './pipes/order-by.pipe';
import { OrderBySelectedPipe } from './pipes/order-by-selected.pipe';
import { PartnerDetailComponent } from '../resources/scores/partners/partner-detail/partner-detail.component';
import { PartnerFormComponent } from '../resources/scores/partners/partner-form/partner-form.component';
import { PartnersService } from '../resources/scores/partners/partners.service';
import { PaymentMethodsService } from '../resources/config/company/payment-methods/payment-methods.service';
import { PhonePrefixConditionComponent } from './components/create-update-segments-v2/segment-condition/phone-prefix-condition/phone-prefix-condition.component';
import { PhonePrefixConditionService } from './services/conditions/phone-prefix-condition.service';
import { PlanCampaignCloneComponent } from '../resources/plans/plan-campaign-clone/plan-campaign-clone.component';
import { PlanCampaignListComponent } from '../resources/plans/plan-campaign-list/plan-campaign-list.component';
import { PlanCampaignNewComponent } from '../resources/plans/plan-campaign-new/plan-campaign-new.component';
import { PlanCampaignService } from '../resources/plans/campaigns/plan-campaign.service';
import { PlanFormComponent } from '../resources/plans/plan-form/plan-form.component';
import { PlansService } from '../resources/plans/plans.service';
import { PlTabComponent } from '../resources/analytics/club/tabs/pl/pl-tab.component';
import { PointsBlockComponent } from '../resources/data-warehouse/tickets/show/points-block/points-block.component';
import { PostalCodeConditionComponent } from './components/create-update-segments-v2/segment-condition/postal-code-condition/postal-code-condition.component';
import { PostalCodeConditionService } from './services/conditions/postal-code-condition.service';
import { PotentialJourneyService } from './services/potential-journey.service';
import { PrimeNgModule } from './primeNG/primeng/primeng.module';
import { ProductSalesTabComponent } from '../resources/analytics/results/tabs/product-sales/product-sales-tab.component';
import { ProductsService } from '../resources/data-warehouse/products/products.service';
import { ProfileFormService } from '../resources/data-warehouse/profiles/form/form-profile.service';
import { ProfilesComponent } from '../resources/data-warehouse/profiles/profiles.component';
import { PromotionsBlockComponent } from '../resources/data-warehouse/tickets/show/promotions-block/promotions-block.component';
import { PumpTablesService } from './services/pump-tables.service';
import { PushTemplateEditComponent } from '../resources/content-designer/campaign-templates/push-template-edit-component/push-template-edit.component';
import { PushTemplatesService } from './services/push-templates.service';
import { QuestionControlService } from './services/question-control.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecoveryCampaignGeneralInputsComponent } from '../resources/plans/campaigns/recovery-campaign-plan-definition/recovery-campaign-general-inputs/recovery-campaign-general-inputs/recovery-campaign-general-inputs.component';
import { RecoveryCampaignPlanDefinitionComponent } from '../resources/plans/campaigns/recovery-campaign-plan-definition/recovery-campaign-plan-definition/recovery-campaign-plan-definition.component';
import { RecuperationJourneyService } from './services/recuperation-journey.service';
import { RedirectionService } from './services/redirection.service';
import { RefreshCacheService } from './services/refresh-cache.service';
import { RepetitionTabComponent } from '../resources/analytics/migrations/tabs/repetition/repetition-tab.component';
import { ReportIncrementalService } from '../resources/analytics/reports/edit-report/incremental/report-incremental.service';
import { RetroTagComponent } from '../resources/segments/retro-tag/retro-tag.component';
import { RetroTagService } from '../resources/segments/retro-tag/services/retro-tag.service';
import { ReturnsBlockComponent } from '../resources/data-warehouse/tickets/show/returns-block/returns-block.component';
import { RewardTaxonomiesService } from '../resources/scores/rewards/reward-taxonomies/reward-taxonomies.service';
import { RolesService } from '../resources/users/roles.service';
import { RouterModule } from '@angular/router';
import { S3Service } from './services/s3.service';
import { SalesDetailDashboardComponent } from '../resources/analytics/sales/sales-detail-dashboard.component';
import { SalesService } from '../resources/analytics/sales/sales.service';
import { SalesSummaryDashboardComponent } from '../resources/analytics/sales/sales-summary-dashboard.component';
import { SaveFilterComponent } from './components/save-filter/save-filter.component';
import { SchedulePlanFormComponent } from '../resources/plans/plans-list/schedule-plan-form/schedule-plan-form.component';
import { SegmentCategoriesService } from '../resources/segments/segment-categories.service';
import { SegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/segment-condition.component';
import { SegmentInputsComponent } from '../resources/plans/campaigns/custom-campaign-plan-definition/segment-inputs/segment-inputs.component';
import { ShowAffectationComponent } from '../resources/data-warehouse/data-warehouse-coupons/show/show-history/show-affectation/show-affectation.component';
import { ShowBigqueryJobsComponent } from '../resources/config/bigquery-jobs/show/show-bigquery-jobs.component';
import { ShowCampaignCategoriesComponent } from '../resources/campaigns/campaign-categories/show-campaign-categories/show-campaign-categories.component';
import { ShowCampaignsComponent } from '../resources/campaigns/campaign-manual/show-campaigns/show-campaigns.component';
import { ShowCampaignsContentComponent } from '../resources/campaigns/campaign-manual/show-campaigns-content/show-campaigns-content.component';
import { ShowCampaignsHistoryComponent } from '../resources/campaigns/campaign-manual/show-campaigns-history/show-campaigns-history.component';
import { ShowCompanyPromotionsComponent } from '../resources/data-warehouse/promotions/show/promotional_discounts/show-promotional-discounts.component';
import { ShowCouponsComponent } from '../resources/data-warehouse/data-warehouse-coupons/show/show-coupons.component';
import { ShowCreditTransactionsComponent } from '../resources/config/credit-transactions/show-credit-transactions/show-credit-transactions.component';
import { ShowCustomEntitiesComponent } from '../resources/data-warehouse/custom-entities/show-custom-entities/show-custom-entities.component';
import { ShowCustomerAttributesComponent } from '../resources/data-warehouse/customer-attributes/components/show-customer-attributes/show-customer-attributes.component';
import { ShowFeatureInternalsComponent } from '../resources/data-warehouse/products/show-feature-internals/show-feature-internals.component';
import { ShowHistoryComponent } from '../resources/data-warehouse/data-warehouse-coupons/show/show-history/show-history.component';
import { ShowHistoryInfoComponent } from '../resources/data-warehouse/data-warehouse-coupons/show/show-history/show-history-info/show-history-info.component';
import { ShowLocationsComponent } from '../resources/data-warehouse/locations/show-locations/show-locations.component';
import { ShowLocationTaxonomiesComponent } from '../resources/data-warehouse/locations/show-location-taxonomies/show-location-taxonomies.component';
import { ShowLocationTaxonomyTermsComponent } from '../resources/data-warehouse/locations/show-location-taxonomy-terms/show-location-taxonomy-terms.component';
import { ShowLogsComponent } from '../resources/status/show/show-logs.component';
import { ShowLoyaltyScoreMomentComponent } from '../resources/scores/loyalty_scores/loyalty_scores_moments/show-loyalty-score-moment/show-loyalty-score-moment.component';
import { ShowLoyaltyScoresComponent } from '../resources/scores/loyalty_scores/show_loyalty_scores/show-loyalty-scores.component';
import { ShowMessageCategoriesComponent } from '../resources/content-designer/campaign-messages/show-message-categories/show-message-categories.component';
import { ShowMessagesComponent } from '../resources/content-designer/campaign-messages/show-messages/show-messages.component';
import { ShowNewsListComponent } from '../resources/content-designer/campaign-news-list/show-news-list/show-news-list.component';
import { ShowNewsTaxonomiesComponent } from '../resources/content-designer/campaign-news-list/show-news-taxonomies/show-news-taxonomies.component';
import { ShowNewsTaxonomyTermsComponent } from '../resources/content-designer/campaign-news-list/show-news-taxonomy-terms/show-news-taxonomy-terms.component';
import { ShowPeriodsComponent } from '../resources/config/periods/show-periods/show-periods.component';
import { ShowProductsComponent } from '../resources/data-warehouse/products/show-products/show-products.component';
import { ShowProductsFeaturesComponent } from '../resources/data-warehouse/products/show-products-features/show-products-features.component';
import { ShowProductsFeatureTaxonomiesComponent } from '../resources/data-warehouse/products/show-products-feature-taxonomies/show-products-feature-taxonomies.component';
import { ShowProfilesComponent } from '../resources/data-warehouse/profiles/show/show-profiles.component';
import { ShowPromotionsComponent } from '../resources/data-warehouse/promotions/show/promotions/show-promotions.component';
import { ShowRecipesComponent } from '../resources/recipes/show-recipes/show-recipes.component';
import { ShowRedemptionsComponent } from '../resources/scores/redemptions/show/show-redemptions.component';
import { ShowReportIncrementalComponent } from '../resources/analytics/reports/shows/show-report-incremental/show-report-incremental.component';
import { ShowReportRoiByStore } from '../resources/analytics/reports/shows/show-report-roi-by-store/show-report-roi-by-store.component';
import { ShowReportsComponent } from '../resources/analytics/reports/shows/show-reports.component';
import { ShowReportSegmentComponent } from '../resources/analytics/reports/shows/show-report-segment/show-report-segment.component';
import { ShowRewardsCategoriesComponent } from '../resources/scores/rewards/reward-categories/show-rewards-categories/show-rewards-categories.component';
import { ShowRewardsComponent } from '../resources/scores/rewards/show-rewards/show-rewards.component';
import { ShowRewardsTaxonomiesComponent } from '../resources/scores/rewards/reward-taxonomies/show-rewards-taxonomies/show-rewards-taxonomies.component';
import { ShowScoreExchangesComponent } from '../resources/scores/score_exchanges/show-score-exchanges/show-score-exchanges.component';
import { ShowSegmentCategoriesComponent } from '../resources/segments/show/show-segment-categories/show-segment-categories.component';
import { ShowSegmentsComponent } from '../resources/segments/show/show-segments.component';
import { ShowStatisticsComponent } from '../resources/data-warehouse/data-warehouse-coupons/show/show-tab-statistics/show-statistics.component';
import { ShowStreamingApiComponent } from '../resources/api/streaming-api/show/show-streaming-api.component';
import { ShowSynchrosComponent } from '../resources/api/synchros/show-synchros/show-synchros.component';
import { ShowTabCustomerComponent } from '../resources/segments/show/tabs/show-tab-customer.component';
import { ShowUsersComponent } from '../resources/users/show-users/show-users.component';
import { ShowVersionsComponent } from '../resources/config/versions/show/show-versions.component';
import { ShowVouchersComponent } from '../resources/coupons/coupons-vouchers/show/show-vouchers.component';
import { ShowWalletsComponent } from '../resources/scores/wallets/show/show-wallets.component';
import { SingleInputComponent } from './components/looker-dashboard-filter/single-input/single-input.component';
import { SmsTemplateEditComponent } from '../resources/content-designer/campaign-templates/sms-template-edit-component/sms-template-edit.component';
import { SmsTemplatesService } from './services/sms-templates.service';
import { SqlConditionComponent } from './components/create-update-segments-v2/segment-condition/sql-condition/sql-condition.component';
import { SqlConditionService } from './services/conditions/sql-condition.service';
import { StateConditionComponent } from './components/create-update-segments-v2/segment-condition/state-condition/state-condition.component';
import { StateConditionService } from './services/conditions/services/state-condition/state-condition.service';
import { StepsBubblesComponent } from './components/form-wizard/steps-bubbles/steps-bubbles.component';
import { StepsComponent } from '../resources/config/bigquery-jobs/form/steps/steps.component';
import { StepsNavComponent } from './components/form-wizard/steps-nav/steps-nav.component';
import { SubtractPointsComponent } from '../resources/data-warehouse/profiles/show/tabs/summary/action-modals/subtract-points/subtract-points.component';
import { SupplierCampaignPlanDefinitionComponent } from '../resources/plans/campaigns/supplier-campaign-plan-definition/supplier-campaign-plan-definition.component';
import { SupplierGeneralInputsComponent } from '../resources/plans/campaigns/supplier-campaign-plan-definition/supplier-general-inputs/supplier-general-inputs.component';
import { SystemSegmentConditionComponent } from './components/create-update-segments-v2/segment-condition/system-segment-condition/system-segment-condition.component';
import { SystemSegmentConditionService } from './services/conditions/system-segment-condition.service';
import { SystemSegmentsService } from '../resources/segments/system-segments.service';
import { SystemTagHistoriesService } from './services/system-tag-histories.service';
import { TabCashbackComponent } from '../resources/config/loyalty/cashback/tab-cashback.component';
import { TabCollectivesComponent } from '../resources/data-warehouse/profiles/tab-collectives.component';
import { TabCouponsComponent } from '../resources/data-warehouse/data-warehouse-coupons/tab-coupons.component';
import { TabHistoryComponent } from '../resources/api/synchros/tab-history.component';
import { TabHistoryComponent as CouponHistoriesComponent } from '../resources/data-warehouse/data-warehouse-coupons/tab-history.component';
import { TabJobsComponent } from '../resources/config/bigquery-jobs/tab-jobs.component';
import { TabProfilesComponent } from '../resources/data-warehouse/profiles/tab-profiles.component';
import { TagHistoriesService } from './services/tag-histories.service';
import { TagSalesExtraValuesService } from './services/tag-sales-extra-values.service';
import { TaxonomiesCountConditionComponent } from './components/create-update-segments-v2/segment-condition/taxonomies-count/taxonomies-count-condition.component';
import { TaxonomiesCountConditionService } from './services/conditions/services/taxonomies-count-condition/taxonomies-count-condition.service';
import { TemplatesService } from './services/templates.service';
import { TicketConditionComponent } from './components/create-update-segments-v2/segment-condition/ticket-condition/ticket-condition.component';
import { TicketsConditionService } from './services/conditions/services/tickets-condition/tickets-condition.service';
import { TimeZonesService } from './services/time-zones.service';
import { TransferScoreComponent } from '../resources/data-warehouse/profiles/show/tabs/summary/action-modals/transfer-score/transfer-score.component';
import { TranslateModule } from '@ngx-translate/core';
import { TriggerActionsService } from './services/connectors/trigger-actions.service';
import { TriggerTypesService } from './services/connectors/trigger-types.service';
import { TypeRedemptionsComponent } from '../resources/analytics/customers/tabs/type-redemptions/type-redemptions.component';
import { UpCrossCampaignDefinitionComponent } from '../resources/plans/campaigns/up-cross-campaign-definition/up-cross-campaign-definition.component';
import { UpCrossGeneralInputsComponent } from '../resources/plans/campaigns/up-cross-campaign-definition/up-cross-general-inputs/up-cross-general-inputs.component';
import { UpdateLandingComponent } from './components/create-update-landings/show/update-landing.component';
import { UploadImagesComponent } from '../resources/data-warehouse/data-warehouse-coupons/upload-images/upload-images.component';
import { UserPasswordValidationComponent } from './components/user-password-validation/user-password-validation.component';
import { VoucherCountComponent } from './components/create-update-segments-v2/segment-condition/voucher-count-condition/voucher-count.component';
import { VoucherCountConditionService } from './services/conditions/services/voucher-count-condition/voucher-count-condition.service';
import { VoucherCountTabComponent } from '../resources/analytics/club/tabs/voucher-count/voucher-count-tab.component';
import { WhiteLabelInputsComponent } from '../resources/plans/campaigns/supplier-campaign-plan-definition/white-label-inputs/white-label-inputs.component';
import { CardRetailDemoComponent } from './components/cards/card-retail-demo-1/card-retail-demo.component';

@NgModule({
  imports: [
    AceModule,
    ChipModule,
    CommonModule,
    CouponFormWarningsComponent,
    CouponHeaderComponentComponent,
    DndModule,
    FormsModule,
    IconInfoButtonComponent,
    InputSwitchModule,
    NgSelectModule,
    NgxDatatableModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    PrimeNgModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    UserPasswordValidationComponent
  ],
  declarations: [
    ActivityTabComponent,
    AnalyticsLegendComponent,
    ApiKeyDetailComponent,
    ApiKeyFormComponent,
    AttributesCustomEntityConditionComponent,
    AttributesListCustomEntityConditionComponent,
    AudienceContentComponent,
    AudienceCouponRecommenderComponent,
    AudienceDatesComponent,
    AudienceSelectorComponent,
    AvailableScoreSegmentConditionComponent,
    AverageActivityConditionComponent,
    BigqueryJobsComponent,
    CalculatedValuesBuyerTypesSegmentConditionComponent,
    CalculationSectionComponent,
    CamelCasePipe,
    CampaignConditionComponent,
    CampaignContentBlockComponent,
    CampaignDefinitionBlockComponent,
    CampaignInputComponent,
    CampaignReportBlockComponent,
    CampaignSegmentBlockComponent,
    CampaignSelectionComponent,
    CampaignValidateModalComponent,
    CampaignValidationBlockComponent,
    CardPlan1Component,
    CardPlan2Component,
    CardRetailDemoComponent,
    CardRecipeComponent,
    CashbackComponent,
    CityConditionComponent,
    ClientCategoriesConditionComponent,
    ClientIdConditionComponent,
    ClientsConstantTabComponent,
    ClientUsualLocationConditionComponent,
    ClubEvolutionComponent,
    ClubIndicatorsTabComponent,
    CollectiveConditionComponent,
    ConsistenceTabComponent,
    ContactsTabComponent,
    CountryConditionComponent,
    CouponConditionComponent,
    CouponFormSectionComponent,
    CouponFormSectionRowsComponent,
    CouponHistoriesComponent,
    CouponsBlockComponent,
    CreateUpdateCampaignsDynamicFormComponent,
    CreateUpdateCustomCustomerJourneyComponent,
    CreateUpdateCustomerJourneysDynamicFormComponent,
    CreateUpdateLandingsComponent,
    CreateUpdateTemplatesComponent,
    CreditAmountInfoComponent,
    CustomCampaignPlanAudienceComponent,
    CustomCampaignPlanDefinitionComponent,
    CustomCampaignPlanForecastComponent,
    CustomerAttributeConditionComponent,
    CustomerAttributesComponent,
    CustomerChannelStatusSegmentConditionComponent,
    CustomEntityConditionComponent,
    CustomerFusionComponent,
    CustomersComponent,
    CustomersTabComponent,
    CustomersTypologyComponent,
    CustomSegmentConditionComponent,
    DataTableComponent,
    DataTableFilterAnalyticsComponent,
    DataTableFilterComponent,
    DataWarehouseCouponsComponent,
    DateTimeZonePipe,
    DetailsBlockComponent,
    DynamicDateInputComponent,
    DynamicFormQuestionComponent,
    DynamicTabsComponent,
    EditIncrementalComponent,
    EditReportComponent,
    EditROIByStoreComponent,
    EditSegmentEvoComponent,
    EmailImpactConditionComponent,
    EmailTemplateEditComponent,
    EmailTemplateViewComponent,
    EmptyDataPipe,
    ErrorPageComponent,
    FeatureInputComponent,
    FileUploadComponent,
    FluidHeightDirective,
    ForecastContentComponent,
    FormBigqueryJobsComponent,
    FormCampaignsCategoriesComponent,
    FormCashbackComponent,
    FormCmsComponent,
    FormCompanyPromotionsComponent,
    FormCouponsComponent,
    FormCreditTransactionsComponent,
    FormCurrencyConversionsComponent,
    FormCustomEntitiesComponent,
    FormCustomEntityComponent,
    FormCustomerAttributesComponent,
    FormExecuteDateComponent,
    FormFeatureTaxonomiesComponent,
    FormLocationsComponent,
    FormLocationsTaxonomiesComponent,
    FormLocationTaxonomiesTermsComponent,
    FormLoyaltyScoreMomentsComponent,
    FormLoyaltyScoresComponent,
    FormMessageCategoriesComponent,
    FormMessagesComponent,
    FormNewsTaxonomiesComponent,
    FormNewsTaxonomyTermsComponent,
    FormPaymentMethodsComponent,
    FormProductsComponent,
    FormProductsFeaturesComponent,
    FormProductsInternalCategoriesComponent,
    FormProfilesComponent,
    FormPromotionsComponent,
    FormRecipesComponent,
    FormReservedIdsComponent,
    FormRewardCategoriesComponent,
    FormRewardsComponent,
    FormRewardTaxonomiesComponent,
    FormScoreExchangesComponent,
    FormSegmentCategoriesComponent,
    FormUsersComponent,
    FormWalletsComponent,
    FullscreenModalContainerComponent,
    GenderConditionComponent,
    GrantPointsComponent,
    ImportCouponsComponent,
    ImportLocationTaxonomyTermsComponent,
    ImportProductCategoriesComponent,
    ImportProductsComponent,
    InfoPanelComponent,
    JourneyCampaignsBlockComponent,
    JourneyContentBlockComponent,
    JourneyDefinitionBlockComponent,
    JourneySummaryBlockComponent,
    LanguageAccordionsComponent,
    LargeModalContainerComponent,
    LastPurchaseConditionComponent,
    LgCurrencyPipe,
    LgMultilanguageTranslatePipe,
    LinesBlockComponent,
    LoadCsvComponent,
    LoaderComponent,
    LookerDashboardFilterComponent,
    LookerIFrameContainerComponent,
    MaxSalesConditionComponent,
    MigrationsFlowComponent,
    MultiselectComponent,
    NameConditionComponent,
    NestedSegmentConditionComponent,
    NewCardComponent,
    OpenInfoPanelDirective,
    OrderByPipe,
    OrderBySelectedPipe,
    PartnerDetailComponent,
    PartnerFormComponent,
    PhonePrefixConditionComponent,
    PlanCampaignCloneComponent,
    PlanCampaignListComponent,
    PlanCampaignNewComponent,
    PlanFormComponent,
    PlTabComponent,
    PointsBlockComponent,
    PostalCodeConditionComponent,
    ProductSalesTabComponent,
    ProfilesComponent,
    PromotionsBlockComponent,
    PushTemplateEditComponent,
    RecoveryCampaignGeneralInputsComponent,
    RecoveryCampaignPlanDefinitionComponent,
    RepetitionTabComponent,
    RetroTagComponent,
    ReturnsBlockComponent,
    SalesDetailDashboardComponent,
    SalesSummaryDashboardComponent,
    SaveFilterComponent,
    SchedulePlanFormComponent,
    SegmentConditionComponent,
    SegmentInputsComponent,
    ShowAffectationComponent,
    ShowBigqueryJobsComponent,
    ShowCampaignCategoriesComponent,
    ShowCampaignsComponent,
    ShowCampaignsContentComponent,
    ShowCampaignsHistoryComponent,
    ShowCompanyPromotionsComponent,
    ShowCouponsComponent,
    ShowCreditTransactionsComponent,
    ShowCustomEntitiesComponent,
    ShowCustomerAttributesComponent,
    ShowFeatureInternalsComponent,
    ShowHistoryComponent,
    ShowHistoryInfoComponent,
    ShowLocationsComponent,
    ShowLocationTaxonomiesComponent,
    ShowLocationTaxonomyTermsComponent,
    ShowLogsComponent,
    ShowLoyaltyScoreMomentComponent,
    ShowLoyaltyScoresComponent,
    ShowMessageCategoriesComponent,
    ShowMessagesComponent,
    ShowNewsListComponent,
    ShowNewsTaxonomiesComponent,
    ShowNewsTaxonomyTermsComponent,
    ShowPeriodsComponent,
    ShowProductsComponent,
    ShowProductsFeaturesComponent,
    ShowProductsFeatureTaxonomiesComponent,
    ShowProfilesComponent,
    ShowPromotionsComponent,
    ShowRecipesComponent,
    ShowRedemptionsComponent,
    ShowReportIncrementalComponent,
    ShowReportRoiByStore,
    ShowReportsComponent,
    ShowReportSegmentComponent,
    ShowRewardsCategoriesComponent,
    ShowRewardsComponent,
    ShowRewardsTaxonomiesComponent,
    ShowScoreExchangesComponent,
    ShowSegmentCategoriesComponent,
    ShowSegmentsComponent,
    ShowStatisticsComponent,
    ShowStreamingApiComponent,
    ShowSynchrosComponent,
    ShowTabCustomerComponent,
    ShowUsersComponent,
    ShowVersionsComponent,
    ShowVouchersComponent,
    ShowWalletsComponent,
    SingleInputComponent,
    SmsTemplateEditComponent,
    SqlConditionComponent,
    StateConditionComponent,
    StepsBubblesComponent,
    StepsComponent,
    StepsNavComponent,
    SubtractPointsComponent,
    SupplierCampaignPlanDefinitionComponent,
    SupplierGeneralInputsComponent,
    SystemSegmentConditionComponent,
    TabCashbackComponent,
    TabCollectivesComponent,
    TabCouponsComponent,
    TabHistoryComponent,
    TabJobsComponent,
    TabProfilesComponent,
    TaxonomiesCountConditionComponent,
    TicketConditionComponent,
    TransferScoreComponent,
    TypeRedemptionsComponent,
    UpCrossCampaignDefinitionComponent,
    UpCrossGeneralInputsComponent,
    UpdateLandingComponent,
    UploadImagesComponent,
    VoucherCountComponent,
    VoucherCountTabComponent,
    WhiteLabelInputsComponent,
    FormContactPoliciesComponent
  ],
  providers: [
    AnalyticsService,
    ApiKeyService,
    ApiService,
    AttributesConditionsService,
    AvailableScoreConditionService,
    AverageActivityConditionService,
    BigqueryjobsService,
    CalculatedValuesBuyerTypesSegmentConditionService,
    CamelCasePipe,
    CampaignCategoriesService,
    CampaignConditionService,
    CampaignsSenderService,
    CampaignTemplatesService,
    CashBackService,
    CityConditionService,
    ClientBirthdayConditionService,
    ClientCategoriesConditionService,
    ClientEmailConditionService,
    ClientIdConditionService,
    ClientRegistrationDateConditionService,
    ClientUsualLocationConditionService,
    CmsService,
    CollectiveConditionService,
    ConditionService,
    ConfigService,
    ConfirmationService,
    ConnectorActionsService,
    ConnectorsService,
    CountryConditionService,
    CouponConditionService,
    CreateUpdateLandingsService,
    CsvExportService,
    CurrencyPipe,
    CustomConditionService,
    CustomEntitiesService,
    CustomerAttributeConditionService,
    CustomerAttributesInputsService,
    CustomerAttributesOptionsService,
    CustomerAttributesService,
    CustomerChannelStatusConditionService,
    CustomEntityConditionService,
    CustomerJourneysHistoriesService,
    CustomersService,
    DashCategoriesService,
    DashPromotionsService,
    DataTableFilterService,
    DateService,
    DateTimeZonePipe,
    DateTimeZoneService,
    DecimalPipe,
    DumpTablesService,
    DynamicBaseFiltersService,
    EmailTemplatesService,
    FeatureInternalsService,
    FeaturesService,
    FidelizationService,
    FiltersService,
    GenderConditionService,
    GroupConditionService,
    LanguagesService,
    LastPurchaseConditionService,
    LgCurrencyPipe,
    LgLanguageTagPipe,
    LocationsTaxonomyTermsService,
    LocationTaxonomiesService,
    LookerCfgGlobalFiltersService,
    LookerGlobalFiltersService,
    LookerNielsenFiltersService,
    MailConditionService,
    MatrixService,
    MaxSalesConditionService,
    MessageCategoriesService,
    MessagesService,
    MulticouponJourneyService,
    MulticouponJourneyService,
    MultiselectService,
    NameConditionService,
    NestedSegmentConditionService,
    NewsTaxonomiesService,
    NewsTaxonomyTermsService,
    NotificationsService,
    OperatorsService,
    OrderBySelectedPipe,
    PartnersService,
    PaymentMethodsService,
    PhonePrefixConditionService,
    PlanCampaignService,
    PlansService,
    PostalCodeConditionService,
    PotentialJourneyService,
    ProductsService,
    ProfileFormService,
    PumpTablesService,
    PushTemplatesService,
    QuestionControlService,
    RecuperationJourneyService,
    RedirectionService,
    RefreshCacheService,
    ReportIncrementalService,
    RetroTagService,
    RewardTaxonomiesService,
    RolesService,
    S3Service,
    SalesService,
    SegmentCategoriesService,
    SmsTemplatesService,
    SqlConditionService,
    StateConditionService,
    SystemSegmentConditionService,
    SystemSegmentsService,
    SystemTagHistoriesService,
    TagHistoriesService,
    TagSalesExtraValuesService,
    TaxonomiesCountConditionService,
    TemplatesService,
    TicketsConditionService,
    TimeZonesService,
    TitleCasePipe,
    TriggerActionsService,
    TriggerTypesService,
    VoucherCountConditionService
  ],
  exports: [
    AceModule,
    ActivityTabComponent,
    ApiKeyDetailComponent,
    ApiKeyFormComponent,
    CamelCasePipe,
    CampaignSelectionComponent,
    CardPlan1Component,
    CardPlan2Component,
    CardRecipeComponent,
    CardRetailDemoComponent,
    CommonModule,
    CouponsBlockComponent,
    CreateUpdateCampaignsDynamicFormComponent,
    CreditAmountInfoComponent,
    DataTableComponent,
    DataTableFilterAnalyticsComponent,
    DataTableFilterComponent,
    DateTimeZonePipe,
    DetailsBlockComponent,
    DynamicFormQuestionComponent,
    DynamicTabsComponent,
    EmptyDataPipe,
    ErrorPageComponent,
    FileUploadComponent,
    FluidHeightDirective,
    FormCustomEntityComponent,
    FormRewardTaxonomiesComponent,
    IconInfoButtonComponent,
    InfoPanelComponent,
    LanguageAccordionsComponent,
    LgCurrencyPipe,
    LgMultilanguageTranslatePipe,
    LinesBlockComponent,
    LoaderComponent,
    LookerDashboardFilterComponent,
    LookerIFrameContainerComponent,
    MigrationsFlowComponent,
    MultiselectComponent,
    NgxDropzoneModule,
    NgxSummernoteModule,
    OpenInfoPanelDirective,
    OpenInfoPanelDirective,
    OrderByPipe,
    OrderBySelectedPipe,
    PartnerDetailComponent,
    PartnerFormComponent,
    PlanCampaignListComponent,
    PlanCampaignNewComponent,
    PlanFormComponent,
    PlTabComponent,
    PointsBlockComponent,
    PrimeNgModule,
    ProductSalesTabComponent,
    ProfilesComponent,
    PromotionsBlockComponent,
    RepetitionTabComponent,
    ReturnsBlockComponent,
    SalesDetailDashboardComponent,
    SalesSummaryDashboardComponent,
    SegmentConditionComponent,
    ShowRewardsTaxonomiesComponent,
    SingleInputComponent,
    StepsBubblesComponent,
    StepsNavComponent,
    TabCollectivesComponent,
    TabProfilesComponent,
    TranslateModule,
    UserPasswordValidationComponent
  ]
})

export class SharedModule { }
