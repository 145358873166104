import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { DataTableFilterService } from '../../../shared/components/data-table-filter/data-table-filter.service';
import { FeatureFlagsService } from './../../../shared/services/feature-flags.service';
import { FilterConfiguration } from '../../../shared/components/data-table-filter/data-table-filter-cfg';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { ProfileService } from '../../../profiles/profile.service';
import { ProfilesService } from './profiles.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { Role } from '../../../profiles/roles.service';
import { Subject, takeUntil } from 'rxjs';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { USERS_PERSONAL_DATA_AUTH } from '../../../shared/constants/navigation-roles-not-authorized.constants';

type Custom1 = {name: string, values?: unknown[]}

@Component({
  selector: 'app-profiles-tab-profiles',
  templateUrl: './tab-profiles.component.html',
  styleUrls: ['./tab-profiles.component.scss']
})

export class TabProfilesComponent implements OnInit, OnDestroy {

  currentRole: string;
  dataTableConfig: DataTableConfiguration;
  dataTableFilterConfig: FilterConfiguration = { disableOnSubmit: true };
  filters: QuestionBase<any>[];
  filterSlug: string = 'customers-filter';
  flags = this.featureFlags.flags;
  role: Role;

  get isFranchiseRole(): boolean {
    return this.isCurrentRoleSlug(['franchise']);
  }

  private destroy$: Subject<void> = new Subject<void>();

  private get custom1(): Custom1 {
    const company = this.profileService.getProfileCompany();
    return company.customs?.Profile?.custom1;
  }

  private get isCustomerServiceSpecialOrLimitedRole(): boolean {
    return this.isCurrentRoleSlug(['customer_service_mango', 'customer_service_mango_limited']);
  }

  private get isIntegrationRole(): boolean {
    return this.isCurrentRoleSlug(['integration']);
  }

  private get isStoreManagerRole(): boolean {
    return this.isCurrentRoleSlug(['store_manager']);
  }

  private get isCustomerReadOnly(): boolean {
    return this.flags.customerOnlyRead;
  }

  private get isCustomerServiceDataReadOnly(): boolean {
    return this.flags.customerServiceDataReadOnly;
  }

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
    private filterService: DataTableFilterService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private profilesService: ProfilesService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setFilters();
    this.initializeDataTable();
    this.currentRole = this.profileService.getStoredUserRole();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterHandler(filterFormValue): void {
    this.changeDetector.detectChanges();
    if (filterFormValue.custom1) {
      filterFormValue['custom1[]'] = filterFormValue.custom1;
      delete filterFormValue.custom1;
    }
    this.dataTable.onFilter(filterFormValue);
  }

  dataTableEmitted(): void {
    this.filterService.loaderStatus.next(true);
  }

  private confirmDeleteCustomer(id: number): void {
    this.confirmationService.displayConfirmationAlert(this.translate.instant('resources.profiles.warnings.sure'), this.translate.instant('resources.profiles.warnings.confirm'))
    .then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.profilesService.deleteCustomer(id).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.profiles.warnings.delete_confirm_success')).catch(() => {});
            this.modalStatusService.modalStatus.emit();
          },
          error => {
            this.confirmationService.displayHttpErrorAlert(error);
          }
        );
      }
    }).catch(() => {});
  }

  private initializeDataTable(): void {
    this.profileService.getCurrentUserProfile().pipe(takeUntil(this.destroy$)).subscribe({
      next: (userData) => {
        this.role = new Role(userData.role);
        this.setDataTableConfig();
      },
      error: (errData) => console.warn(errData)
    });
  }

  private setDataTableConfig(): void {
    this.dataTableConfig = {
      renderRowCheckbox: !this.isFranchiseRole,
      isActive: true,
      tokenPagination: true,
      createButton: {
        label: this.translate.instant('resources.profiles.buttons.new_profile'),
        redirectTo: [{outlets: { modal: 'new/profile' }}],
        isHidden: () => this.isCustomerServiceSpecialOrLimitedRole || this.isCustomerReadOnly || this.isCustomerServiceDataReadOnly || this.isStoreManagerRole
      },
      requestData: {
        apiEndPoint: 'customers/v2',
        translationResource: 'resources.profiles.columns.'
      },
      tableActions: [
        {
          name: this.translate.instant('components.table_actions.export_csv'),
          icon: 'ei-export',
          id: 'exportCSV',
          disabled: () => !this.showPersonalData() || this.isFranchiseRole
        }
      ],
      rowActions: this.isFranchiseRole ? [] : this.setRowActions(),
      columns: [
        { name: this.translate.instant('resources.profiles.columns.id'), prop: 'id' },
        { name: this.translate.instant('resources.profiles.columns.name'), prop: 'name' },
        { name: this.translate.instant('resources.profiles.columns.surname'), prop: 'surname' },
        { name: this.translate.instant('resources.profiles.columns.email'), prop: 'email' },
        { name: this.translate.instant('resources.profiles.columns.phone'), prop: 'phone' },
        { name: this.translate.instant('resources.profiles.columns.score'), prop: 'score' },
        { name: this.translate.instant('resources.profiles.columns.created_at'), prop: 'created_at' },
        { name: this.translate.instant('resources.profiles.columns.synchro'), prop: 'synchro' },
        { name: this.translate.instant('resources.profiles.columns.state'), prop: 'state' }
      ],
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: '_embedded.customer.name' },
        { prop: 'surname', type: 'text', apiProp: '_embedded.customer.surname_1' },
        { prop: 'email', type: 'text', apiProp: '_embedded.customer.email' },
        { prop: 'phone', type: 'number', apiProp: '_embedded.customer.phone' },
        { prop: 'score', type: 'number', apiProp: 'score' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at', getValue: (element) => element?.created_at ?? null },
        { prop: 'synchro', type: 'date', apiProp: '_embedded.synchro.created_at' },
        { prop: 'state', type: 'text', apiProp: '_embedded.customer.status', badge: { dictionaryKey: 'profiles_status' } }
      ]
    };

    if (this.flags.showCustomerCode) {
      this.addCustomerCodeColumn();
    }

    if (this.custom1 && Object.keys(this.custom1).length) {
      this.addCustom1Column();
    }
  }

  private setFilters(): void {
    const filters = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'customer_id',
        label: 'resources.profiles.filters.id',
        type: 'number'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: 'resources.profiles.filters.name',
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'surname_1',
        label: 'resources.profiles.filters.surname_1',
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'surname_2',
        label: 'resources.profiles.filters.surname_2',
        type: 'text',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'email',
        label: 'resources.profiles.filters.email',
        type: 'text'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'phone',
        label: 'resources.profiles.filters.phone',
        type: 'number'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'dni',
        label: 'resources.profiles.filters.dni',
        type: 'text'
      })
    ];

    if (this.flags.showCustomerCode) {
      const codeFilter = new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'code',
        label: 'resources.profiles.filters.code',
        type: 'text'
      });
      filters.splice(1, 0, codeFilter);
    }

    if (this.custom1 && Object.keys(this.custom1).length) {
      const custom1Filter = new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'custom1',
        label: this.custom1.name,
        type: 'text',
      })
      filters.push(custom1Filter);
    }

    this.filters = filters;
  }

  private setRowActions(): object[] {
    return [
      {
        name: this.translate.instant('resources.profiles.row_actions.details'),
        icon: 'eye',
        id: 'details',
        show: () => !this.isIntegrationRole,
        customRoute: (element) => [`/data-warehouse/profiles/${element.id}/summary`]
      },
      {
        name: this.translate.instant('resources.profiles.row_actions.details'),
        icon: 'eye',
        id: 'details',
        show: () => this.isIntegrationRole,
        customRoute: (element) => [`/data-warehouse/profiles/${element.id}/vouchers`]
      },
      {
        name: this.translate.instant('resources.campaigns.row_actions.edit'),
        icon: 'pencil',
        id: 'edit',
        show: () => !this.isCustomerServiceSpecialOrLimitedRole && !this.isCustomerReadOnly && !this.isCustomerServiceDataReadOnly && !this.isStoreManagerRole,
        customRoute: (element: { id: number }) => {
          return ['', {outlets: {modal: ['update', 'profile', element.id ]}}];
        }
      },
      {
        name: this.translate.instant('resources.profiles.row_actions.delete'),
        icon: 'trash',
        id: 'delete',
        show: (element) => this.role.slug === 'admin' && !this.isStoreManagerRole && !this.isCustomerReadOnly && !this.isCustomerServiceDataReadOnly && !this.isCustomerStatusDeleted(element),
        callback: (element) => {
          this.confirmDeleteCustomer(element.id);
        }
      }
    ]
  }

  private addCustomerCodeColumn() {
    this.dataTableConfig.columns.splice(1, 0,
      { name: this.translate.instant('resources.profiles.columns.code'), prop: 'code' }
    );

    this.dataTableConfig.tableMapping.push(
      { prop: 'code', type: 'text', apiProp: 'code', getValue: (element) =>  {
        const codeText = element._embedded && element._embedded.customer && element._embedded.customer.code ? element._embedded.customer.code : null;
        return this.handleElementText(codeText);
      }}
    );
  }

  private addCustom1Column(): void {
    const phoneCol = this.dataTableConfig.columns.findIndex(col => col.prop === 'phone');
    this.dataTableConfig.columns.splice(phoneCol + 1, 0,
      { name: this.custom1.name, prop: 'custom1' }
    );

    this.dataTableConfig.tableMapping.push(
      { prop: 'custom1', type: 'text', apiProp: 'custom1', getValue: (element) => { return element.custom1 ? element.custom1 : '-' }}
    );
  }

  private handleElementText(elementText: string): string | { breakFullText: string, parsedValue: string } {
    if (elementText) {
     // As it is an object, the parsedValue parameter is printed on exported CSV
      return { breakFullText: elementText, parsedValue: elementText };
    } else {
      return '-';
    }
  }

  private showPersonalData(): boolean {
    return this.flags.showCustomerPersonalData || USERS_PERSONAL_DATA_AUTH.includes(this.currentRole);
  }

  private isCustomerStatusDeleted(customerData: object): boolean {
    return customerData && customerData['_embedded']?.customer?.status && customerData['_embedded'].customer.status === 'deleted';
  }

  private isCurrentRoleSlug(roleSlugs: string[]): boolean {
    return this.role?.slug ? roleSlugs.includes(this.role.slug) : false;
  }

}
