import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { sortObjArrayByProperty } from '../../../services/array.service';
import { LocationsTaxonomyTermsService } from '../../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { LocationsService } from '../../../../resources/data-warehouse/locations/locations.service';
import { FeatureTaxonomiesService } from '../../../../resources/data-warehouse/products/feature-taxonomies.service';
import { FeaturesService } from '../../../../resources/data-warehouse/products/features.service';
import { ProductsService } from '../../../../resources/data-warehouse/products/products.service';
import { isNullOrUndefined } from '../../../../shared/utils/common.utils';
import { QuestionBase } from '../../../models/forms/question-base';
import { DateService } from '../../../services/date.service';
import { QuestionControlService } from '../../../services/question-control.service';
import { FilterConfiguration } from '.././data-table-filter-cfg';
import { DataTableFilterService } from '.././data-table-filter.service';
import { pairwise, startWith } from 'rxjs/operators';
import { handlePeriodFilter } from '../../../../shared/utils/dynamic-data.utils';
import { CurrentCompany } from '../../../models/current-user';
import { ProfileService } from '../../../../profiles/profile.service';
import { SegmentCategoriesService } from '../../../../resources/segments/segment-categories.service';
import { FiltersService } from '../../save-filter/filters.service';
import { FeatureFlagsService } from '../../../services/feature-flags.service';

@Component({
  selector: 'app-data-table-filter-analytics',
  templateUrl: './data-table-filter-analytics.component.html',
  styleUrls: ['./data-table-filter-analytics.component.scss'],
  providers: [ QuestionControlService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableFilterAnalyticsComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup
  company: CurrentCompany = new CurrentCompany(this.profileService.getProfileCompany());
  payLoad: Object = {};
  disableBtns: boolean;
  standardFilters: QuestionBase<any>[] = [];
  preStandardFilters: QuestionBase<any>[] = [];
  checkboxRowFilters: QuestionBase<any>[] = [];
  subs$: Subscription[] = [];
  isCollapsed = false;
  requestInterval;
  requestIntervalDelay = 2000;
  flags = this.featureFlags.flags;

  @Input() slug: string;
  @Input() questions: QuestionBase<any>[] = [];
  @Input() defaultQuestions: QuestionBase<any>[] = [];
  @Input() cfg: FilterConfiguration = {
    disableOnInit: false,
    disableOnSubmit: false,
    disableSubmitBtn: false,
    dashboardPerUnitMeasure: false,
    ensureButtonDisabling: false
  };
  @Input() isDynamicTabData: boolean = false;

  @Output() onSubmitEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersChangeValue: EventEmitter<any> = new EventEmitter<any>();

  get loadFilters(): boolean {
    const hasToEnsureCompanyTaggedSegments = !!this.cfg['ensureCompanyTaggedSegments'];
    const loadFilters = !hasToEnsureCompanyTaggedSegments || (hasToEnsureCompanyTaggedSegments && this.company?.isTagged());
    return loadFilters;
  }

  constructor(
    private qcs: QuestionControlService,
    private featuresService: FeaturesService,
    private productsService: ProductsService,
    private filterService: DataTableFilterService,
    private changeDetector: ChangeDetectorRef,
    private dateService: DateService,
    private locationsService: LocationsService,
    private profileService: ProfileService,
    private segmentCategoriesService: SegmentCategoriesService,
    private filtersService: FiltersService,
    private featureFlags: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.featuresService.treatPkAsId = true;
    this.setFilters();
    if(this.slug === 'rfm_analysis_filter') { this.segmentCategoriesService.onlyRFMCategories = true; }
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
    if (this.requestInterval) { clearTimeout(this.requestInterval); }
  }

  handleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onSubmit(): void {
    this.isCollapsed = true;
    this.payLoad = this.cleanUndefinedFormValues(this.form.value);
    this.payLoad = this.transformObjectsIntoStrings(this.payLoad);
    this.onSubmitEmitter.emit(this.payLoad);
    this.handleBtnDisabling();
  }

  resetAllFilters(): void {
    if (this.defaultQuestions?.length) {
      this.questions = this.defaultQuestions.map(dQ => Object.assign({}, dQ));
      this.questions.forEach(q => {
        if (q.dataSource && q.dataSource.refreshSelectedOptsSource instanceof Function) {
          q.dataSource.refreshSelectedOptsSource([]);
        }
        this.form.get(q.key).patchValue(q.value);
        this.handleFilterChanges(q.value, q);
      });
      this.handleInitialFiltering();
    } else {
      const optionalInputs = this.questions.filter(_question => _question.required !== true);
      optionalInputs.forEach(_input => {
        this.form.get(_input.key).reset();
        this.handleFilterChanges(_input.value, _input);
      });
    }

    this.preStandardFilters = [];
    this.standardFilters = [];
    this.checkboxRowFilters = [];
    this.divideFilterInputs();
    this.sortFilterInputs();
  }

  // TODO: Abstract methods to be injected in filter configuration (from tab component)
  handleFilterChanges(value, filter: QuestionBase<any>): void {
    if (filter.dataSource && filter.dataSource instanceof FeatureTaxonomiesService) {
      this.handleFeaturesFilterByTaxonomies(value);
    }

    if (filter.dataSource && filter.dataSource instanceof FeaturesService && (filter.key !== 'supplier' && filter.key !== 'brand_pk')) {
      this.handleProductsFilterByFeaturesPks();
    }

    if (this.isDynamicTabData && filter.dataSource && filter.dataSource instanceof LocationsTaxonomyTermsService  && filter.key === 'location_taxonomy_term_ids') {
      this.handleLocationsFilterByLocationTaxonomy();
    }

    if (this.cfg.customCallbackOnFilterChanged){
      this.cfg.customCallbackOnFilterChanged(value, filter, this.questions, this.form);
    }
  }

  handleFilterSelectedValues(values: unknown) {
    this.form.reset();
    this.filtersService.assignValues(this.form, this.questions, values);
  }

  private setFilters() {
    if (this.loadFilters) {
      this.divideFilterInputs();
      this.sortFilterInputs();
      this.form = this.qcs.toFormGroup(this.questions);
      this.setFormSubscriptions();
      this.handleInitialFiltering();
      this.handleBtnDisabling();
      setTimeout(() => this.filterService.filtersLoaderStatus.next(true));
    }
  }

  private handleInitialFiltering(): void {
    this.setInitialFiltering('taxonomy_slug', 'feature_ids');
    this.setInitialFiltering('feature_ids', 'product_ids');
    this.setInitialFiltering('location_taxonomy_term_ids', 'location_ids');
  }

  private setInitialFiltering(mainFilterKey: string, targetToFilterKey: string, ): void {
    const mainFilter = this.questions.find(item => item.key === mainFilterKey);
    const hasMainFilterValue = !!(mainFilter?.value?.length || mainFilter?.selectedIds?.length);
    const targetToFilter = this.questions.find(item => item.key === targetToFilterKey);
    const initialData = this.setInitialFilteringData(targetToFilterKey);

    if (targetToFilter && hasMainFilterValue) {
      if (!initialData.serviceParameter) {
        const filterValues = mainFilter.value?.length ? mainFilter.value.map(initialData.mapMainFilterValue).join(',') : mainFilter.selectedIds.join(',');
        this.setServiceFilteringParameter(targetToFilterKey, filterValues);
      }
    } else {
      this.setServiceFilteringParameter(targetToFilterKey, null);
    }
  }

  private setInitialFilteringData(filterKey: string): { mapMainFilterValue: unknown, serviceParameter: string } {
    switch (filterKey) {
      case 'feature_ids':
        return { mapMainFilterValue: val => val.id, serviceParameter: this.featuresService.taxonomySlug };
      case 'product_ids':
        return { mapMainFilterValue: val => val.rawElement.pk, serviceParameter: this.productsService.featurePks };
      case 'location_ids':
        return { mapMainFilterValue: val => val.id, serviceParameter: this.locationsService.locationTaxonomyTermIds };
    }
  }

  private handleFeaturesFilterByTaxonomies(value): void {
    if (this.questions.find(item => item.dataSource instanceof FeaturesService)) {
      if (value) {
        this.setServiceFilteringParameter('feature_ids', value.id);
        this.questions.find(item => item.key === 'feature_ids').value = [];
        this.form.patchValue({feature_ids: []});
        this.resetFilter('product_ids');
      } else {
        this.setServiceFilteringParameter('feature_ids', null);
      }

      const featuresFilter = this.questions.find(item => item.key === 'feature_ids');
      const productsFilter = this.questions.find(item => item.key === 'product_ids');
      if (productsFilter) {
        if (featuresFilter?.value?.length) {
          const featuresValues = featuresFilter.value.map(feature => feature.rawElement.pk).join(',');
          this.setServiceFilteringParameter('product_ids', featuresValues);
        } else {
          this.setServiceFilteringParameter('product_ids', null);
        }
      }
    }
  }

  private handleProductsFilterByFeaturesPks(): void {
    let featuresValues;
    const featureFilter = this.questions.find(item => item.dataSource instanceof FeaturesService);
    const features = this.form.get(featureFilter.key).value;
    if (features?.length && !isNullOrUndefined(features[0])) {
      featuresValues = features.map(feature => feature.rawElement.pk).join(',');
      this.resetFilter('product_ids');
    }
    if (featuresValues) {
      this.setServiceFilteringParameter('product_ids', featuresValues);
    } else {
      this.setServiceFilteringParameter('product_ids', null);
    }
  }

  private handleLocationsFilterByLocationTaxonomy(): void {
    const locationCategoriesFilter = this.questions.find(item => item.key === 'location_taxonomy_term_ids');
    const locationsFilter = this.questions.find(item => item.key === 'location_ids');
    if (locationsFilter) {
      if (locationCategoriesFilter?.value?.length) {
        this.resetFilter('location_ids');
        const locationCategoriesValues = locationCategoriesFilter.value.map(locationCategory => locationCategory.id).join(',');
        this.setServiceFilteringParameter('location_ids', locationCategoriesValues);
      } else {
        this.setServiceFilteringParameter('location_ids', null);
      }
    }
  }

  private resetFilter(filterKey: string): void {
    const filter = this.questions.find(item => item.key === filterKey);
    if (filter) {
      filter.value = null;
      this.form.get(filterKey).patchValue(null);
    }
  }

  private setServiceFilteringParameter(filterKey: string, filterValues: string | null): void {
    switch (filterKey) {
      case 'feature_ids':
        this.featuresService.setTaxonomies(filterValues);
        break;
      case 'product_ids':
        this.productsService.setFeatures(filterValues);
        break;
      case 'location_ids':
        this.locationsService.setLocationTaxonomyTermIds(filterValues);
        break;
    }
  }

  private handleBtnDisabling(): void {
    if (!this.cfg) { return; }

    if (this.cfg.disableOnInit) {
      this.disableBtns = true;
      this.questions.forEach( question => { question.disabled = true; });
    }

    if (this.cfg.disableOnSubmit) { this.disableBtns = true; }

    const filterService$ = this.filterService.loaderStatus$.subscribe(
      loadersCompleted => {
        // if at least 1 xtra configs is true, means inputs are disabled
        const disableInputs = this.xtraFilterCfg() ? !loadersCompleted : (!loadersCompleted || !this.form.valid);
        this.disableBtns = !loadersCompleted || !this.form.valid;
        this.questions.forEach(question => question.disabled = disableInputs);
        this.changeDetector.detectChanges();
      }
    );
    this.subs$.push(filterService$);
    this.changeDetector.detectChanges();
  }

  private xtraFilterCfg(): boolean {
    return this.cfg.dashboardPerUnitMeasure || this.cfg.ensureButtonDisabling;
  }

  private divideFilterInputs(): void {
    const vm = this;
    this.questions.forEach(function(question){
      if (question.preStandard) {
        vm.preStandardFilters.push(question);
      } else if (question.checkboxRow) {
        vm.checkboxRowFilters.push(question);
      } else {
        vm.standardFilters.push(question);
      }
    });
  }

  private sortFilterInputs(): void {
    sortObjArrayByProperty(this.preStandardFilters, 'order');
    sortObjArrayByProperty(this.standardFilters, 'order');
    sortObjArrayByProperty(this.checkboxRowFilters, 'order');
  }

  private cleanUndefinedFormValues(formValues): Object {
    Object.keys(formValues).forEach(function (key) {
      if (formValues[key] === 'null' ||
          formValues[key] === null ||
          formValues[key] === undefined ||
          formValues[key] === '' ||
          (formValues[key] instanceof Array && formValues[key].length === 0)) {
        delete formValues[key];
      }
    });
    return formValues;
  }

  // Prepare multiselect values
  private transformObjectsIntoStrings(formValues): Object {
    Object.keys(formValues).forEach(
      key => {
        if (Array.isArray(formValues[key])) {
          const preparedValues: string[] = [];
          formValues[key].forEach(
            multiselectOption => {
              if (multiselectOption.id) {
                preparedValues.push(multiselectOption.id);
              }
            }
          );
          formValues[key] = preparedValues.join();
        }
      }
    );

    return formValues;
  }

  private setFormSubscriptions(): void {
    const initialData = {};
    this.questions.forEach(q => { initialData[q.key] = q.value; });

    const formChanges$ = this.form.valueChanges.pipe(startWith(initialData), pairwise()).subscribe(([prev, next]) => {
      const newData = Object.keys(prev).reduce((list, key) => {
        if (JSON.stringify(next[key]) !== JSON.stringify(prev[key])) {
          list[key] = next[key];
        }
        return list;
      }, {});
      this.onFiltersChangeValue.emit(newData);
    });

    if (this.cfg && this.cfg.comparableDatesFilters) {
      const compareWithValue = this.form.get('compare_with').value;
      this.questions.find(question => question.key === 'date_from_compared').hidden = !compareWithValue;
      this.questions.find(question => question.key === 'date_to_compared').hidden = !compareWithValue;

      const formDateFrom$ = this.form.get('date_from').valueChanges.subscribe((dateFrom) => {
        if (!this.form.get('compare_with').value) {
          this.form.get('date_from_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateFrom));
        }
      });

      const formDateTo$ = this.form.get('date_to').valueChanges.subscribe((dateTo) => {
        if (!this.form.get('compare_with').value) {
          this.form.get('date_to_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateTo));
        }
      });

      const formCompareWith$ = this.form.get('compare_with').valueChanges.subscribe((compareWith) => {
        this.questions.find(question => question.key === 'date_from_compared').hidden = !compareWith;
        this.questions.find(question => question.key === 'date_to_compared').hidden = !compareWith;

        const dateFromValue = this.form.get('date_from').value;
        const dateToValue = this.form.get('date_to').value;

        this.form.get('date_from_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateFromValue));
        this.form.get('date_to_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateToValue));
      });

      this.subs$.push(formDateFrom$, formDateTo$, formCompareWith$);
    }

    if (this.cfg && this.cfg.periodDatesFilters) {
      handlePeriodFilter(this.questions, this.dateService, this.form);
      const formPeriod$ = this.form.get('period').valueChanges.subscribe((period) => {
        handlePeriodFilter(this.questions, this.dateService, this.form);
      });

      this.subs$.push(formPeriod$);
    }

    const formStatus$ = this.form.statusChanges.subscribe( result => {
      this.disableBtns = result === 'INVALID';
    });

    this.handleSummaryFilterCurrency();

    this.subs$.push(formChanges$, formStatus$);
  }

  /**
   * If company_currency is checked, currency is disabled and hidden
   * If company_currency is unchecked, currency is enabled and shown
   */
  private handleSummaryFilterCurrency(): void {
    if (this.slug !== 'summary_filter') {
      return;
    }

    const { currency, company_currency } = this.form.controls;
    const currencyQuestion = this.questions.find(({ key }) => key === 'currency');

    const setCurrency = (isChecked: boolean) => {
      currencyQuestion.hidden = isChecked;
      isChecked ? currency.disable() : currency.enable();
    };

    // On init
    setCurrency(company_currency.value);

    const formCompanyCurrency$ = company_currency.valueChanges.subscribe((checked) => {
      setCurrency(checked);
    });

    this.subs$.push(formCompanyCurrency$);
  }

}
