export class FilterConfiguration {
  addExtraDayToSelectorDate?: boolean;
  brandPkFilter?: boolean;
  chartGranularityFilter?: boolean;
  chartMetricasFilter?: boolean;
  chartMetricFilter?: boolean;
  chartMetricSupplierBrandFilter?: boolean;
  chartMetricsWithOwnBrandFilter?: boolean;
  comparableDatesFilters?: boolean;
  countryFilter?: boolean;
  customCallbackOnFilterChanged?: Function;
  customInputsWidthClass?: string;
  dashboardPerUnitMeasure?: boolean;
  dashboardPerUnitMeasureQuantity?: boolean;
  dayOfWeekFilter?: boolean;
  disableOnInit?: boolean;
  disableOnSubmit?: boolean;
  disableSubmitBtn?: boolean;
  ensureButtonDisabling?: boolean;
  ensureCompanyTaggedSegments?: boolean;
  hideBrandSupplierFilters?: boolean;
  idEanProductFilter?: boolean;
  measureTypeFilter?: boolean;
  onlyComparableStoresFilter?: boolean;
  periodDatesFilters?: boolean;
  segmentCategoryIdFilter?: boolean;
  segmentIdFilter?: boolean;
  supplierBrandFilter?: boolean;
  supplierDashboard?: boolean;
  ticketsFilter?: boolean;
  viewAndTopBrandSupplierFilters?: boolean;
}
