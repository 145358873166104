<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><strong [translate]="'resources.product_taxonomies.fields.id'"></strong></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><strong [translate]="'resources.product_taxonomies.fields.name'"></strong></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.slug">
                  <td><strong [translate]="'resources.product_taxonomies.fields.slug'"></strong></td>
                  <td>{{element.slug}}</td>
                </tr>
                <tr *ngIf="element.origin as origin">
                  <td><strong [translate]="'resources.product_taxonomies.fields.origin'"></strong></td>
                  <td *ngIf="origin === 'external'" [translate]="'resources.product_taxonomies.origin.external'"></td>
                  <td *ngIf="origin === 'internal'" [translate]="'resources.product_taxonomies.origin.internal'"></td>
                </tr>
                <tr *ngIf="element.kind as kind">
                  <td><strong [translate]="'resources.product_taxonomies.fields.type'"></strong></td>
                  <td *ngIf="kind === 'brand'" [translate]="'resources.product_taxonomies.types.brand'"></td>
                  <td *ngIf="kind === 'own_brand'" [translate]="'resources.product_taxonomies.types.own_brand'"></td>
                  <td *ngIf="kind === principalCategorization" [translate]="'resources.product_taxonomies.types.nielsen'"></td>
                  <td *ngIf="kind === 'supplier'" [translate]="'resources.product_taxonomies.types.supplier'"></td>
                </tr>
                <tr *ngIf="element.level">
                  <td><strong [translate]="'resources.product_taxonomies.fields.level'"></strong></td>
                  <td>{{element.level}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
