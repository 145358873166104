import { Component, OnInit } from '@angular/core';
import { RedemptionsService } from '../redemptions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { Redemption } from '../redemption.model';

@Component({
  selector: 'app-show-redemptions',
  templateUrl: './show-redemptions.component.html',
  styleUrls: ['./show-redemptions.component.css'],
  providers: [RedemptionsService]
})
export class ShowRedemptionsComponent implements OnInit {

  loading: boolean;
  id: number = this.route.params['value'].id;
  redemptionsData: Redemption;

  constructor(
    private redemptionsService: RedemptionsService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.getRedemptionsData();
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToProfile(profileId) {
    this.router.navigate([{ outlets: { modal: null } }]).then(() => {
      this.router.navigate(['', 'data-warehouse', 'profiles', profileId, 'summary']).catch(() => {});
    }).catch(() => {})
  }

  goToLocation() {
    this.router.navigate([{ outlets: { modal: `show/locations/${this.redemptionsData.location.id}`}}]).catch(() => {});
  }

  goToReward() {
    this.router.navigate([{ outlets: { modal: `show/rewards/${this.redemptionsData.reward.id}`}}]).catch(() => {});
  }

  getFullName(): string {
    const customer = this.redemptionsData?.profile?._embedded?.customer;
    if(!customer) return '';
    const { name, surname_1 } = customer;
    return `${name} ${surname_1 || ''}`;
  }

  private getRedemptionsData() {
    this.loading = true;
    this.redemptionsService.getRedemptionsData(this.id).subscribe({
      next: (redemptionsData: Redemption) => this.redemptionsData = redemptionsData,
      error: (err: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(err);
        this.closeModal();
      },
      complete: () => this.loading = false
    })
  }
}
