import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';

@Component({
  selector: 'app-validity-loyalty-rule-block',
  templateUrl: './validity-loyalty-rule-block.component.html'
})
export class ValidityLoyaltyRuleBlockComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;

  constructor(
    private qcs: QuestionControlService,
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
