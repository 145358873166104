import { HttpResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgOption, NgSelectConfig } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ImportProductsService } from "../../import-products/import-products.service";
import { QuestionBase } from "../../models/forms/question-base";
import { areArraysEquals } from "../../services/array.service";
import { MultiselectDataSourceable } from "./multiselect";
import { MultiselectService } from "./multiselect.service";

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectComponent),
    }
  ]
})

export class MultiselectComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  isDataSourceable = false;
  userSearch$ = new Subject<string | null>();
  loading = false;
  dropdownList: any[] = [];
  selectedItems: any = [];
  propagateChange: Function;
  propagateTouched: Function;
  ctrlValid = true;
  isAllSelected = false;

  private defaultSettings = {
    singleSelection: false,
    showCheckbox: true,
    enableSearchFilter: true,
    enableCheckAll: true,
    disabled: false
  };

  private isLastPage = false;
  private nextToken = 0;
  private page = 1;
  private scrollTop: number;
  private searchTerm = '';
  private selectedIdLoadCount = 0;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() question: QuestionBase<any>;
  @Input() translatable: boolean;
  @Input() hasErrors: boolean;
  @Input() dataSource: MultiselectDataSourceable;
  @Input() selectedIds: string[];
  @Input() doDisable: boolean;
  @Input() doRefresh: boolean;
  @Input() allowBlank: boolean;
  @Input() useToken: boolean;
  @Input() filters: object;
  @Input() options: object;
  @Input() value: any;
  @Input() form: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() settings: {
    singleSelection?: boolean,
    showCheckbox?: boolean,
    enableSearchFilter?: boolean,
    enableCheckAll?: boolean,
    disabled?: boolean
  };

  @Output() public onSelect = new EventEmitter();
  @Output() public onSelectAll = new EventEmitter();
  @Output() public onDeSelectAll = new EventEmitter();
  @Output() public optionsLoadedEmitter = new EventEmitter();
  @Output() public selectedIdsLoaderEmitter = new EventEmitter();
  @Output() public selectedItemLoadedEmitter = new EventEmitter();

  @ViewChild('select') select: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private translate: TranslateService,
    private config: NgSelectConfig,
    private changeDetector: ChangeDetectorRef,
    private multiselectService: MultiselectService,
    private importProductsService: ImportProductsService
  ){
    this.config.placeholder = this.translate.instant('components.multiselect.select');
    this.config.typeToSearchText = this.translate.instant('components.multiselect.search');
    this.config.notFoundText = this.translate.instant('components.multiselect.no_data');
  }

  ngOnInit(): void {

    this.settings = {...this.defaultSettings, ...this.settings};
    if (this.settings.singleSelection) { this.settings.enableCheckAll = false; }

    if (this.dataSource) {
      this.isDataSourceable = true;
      this.initDebouncedSearchInput(500);

      if ( this.dataSource.optsSource$ ) {
        this.handleDataSourceChanges();
      } else {
        this.dropdownList = [];
        this.fetchOptions();
      }

      if ( this.dataSource.selectedOptsSource$ ) {
        this.handleDataSourceSelectedOptsChanges();
      }

    } else {
      this.dropdownList = [...this.question.options];
    }

    if (this.selectedIds?.length && !this.dataSource.optsSource$) {
      if (!this.isDataSourceable) {
        console.error('Multiselect with selectedIds must also receive a dataSource', 'SelectedIds:', this.selectedIds.join(', '));
        return;
      }
      this.handleSelectedIdsFromCfg();
    }

    if (!this.selectedIds || (this.selectedIds && this.selectedIds.length === 0)) {
      this.selectedIdsLoaderEmitter.emit();
    }

    this.updateDropdownList();
  }

  handleSingleSelectionItem(item: NgOption) {
    this.cleanDatasourceSelectedOpts();
    if (this.settings.singleSelection && this.isOptSelected(item)) {
      this.clearAll();
      this.dropdownList = [...this.dropdownList];
    }
  }

  onChange(value: any) {
    // Prevent value assignation when method is triggered by user search
    this.cleanDatasourceSelectedOpts();
    if (value instanceof Event) { return; }
    if (this.selectedItems && this.selectedItems.length > 0 && this.dropdownList && this.dropdownList.length > 0) {
      const selectedItemsIds = this.selectedItems.map(el => el.id).sort((a, b) => (a - b));
      const dropwdownListIds = this.dropdownList.map(el => el.id).sort((a, b) => (a - b));
      this.isAllSelected = areArraysEquals(selectedItemsIds, dropwdownListIds);
    } else {
      this.isAllSelected = false;
    }
    this.customWriteValue(value);
    this.dropdownList = [...this.dropdownList];
  }

  onSelectAllChange() {
    this.isAllSelected = !this.allItemsSelected();
    this.isAllSelected ? this.selectAll() : this.clearAll();
    this.dropdownList = [...this.dropdownList];
  }

  onOpen(evt) {
    setTimeout(() => {
      this.changeDetector.detectChanges();
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
      if (this.searchTerm) {
        this.handleUserSearch(this.searchTerm, this.select);
      }
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = this.scrollTop;
      }
    });
  }

  onClose() {
    const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
    this.scrollTop =  scrollContainer.scrollTop;
  }

  handleUserSearch(searchTerm: string, select?) {
    if (this.isDataSourceable) {
      this.userSearch$.next(searchTerm);
    } else {
      select.filter(searchTerm);
    }
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if (changes.hasOwnProperty('doDisable') && !changes.doDisable.firstChange) {
      this.settings.disabled = this.doDisable;
    }
    if (changes.hasOwnProperty('doRefresh') && !changes.doRefresh.firstChange) {
      this.handleSelectedIdsFromCfg();
    }
  }

  ngOnDestroy(): void {
    this.page = 1;
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue() {
    const _value = this.form.controls[this.formControlName].value;
    if (_value && _value instanceof Array && _value.length > 0) {
      if (this.settings.singleSelection) {
        this.selectedItems = _value[0];
      } else {
        this.selectedItems = _value;
      }
    } else {
      this.selectedItems = [];
    }
    this.question.hasErrors = false;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  loadMore(event: Event) {
    if (this.isDataSourceable && !this.isLastPage) {
      this.page++;
      let page = this.page;
      if (this.useToken) {
        page = this.nextToken;
      }
      this.fetchOptions(this.searchTerm, page);
    }
  }

  isOptSelected(item: object) {
    if (!this.selectedItems || this.selectedItems.length === 0) {
      return false;
    }

    if (this.selectedItems instanceof Array) {
      return this.selectedItems.map((el: any) => el.id).includes(item['id']);
    } else {
      return this.selectedItems['id'] === item['id'];
    }
  }

  allItemsSelected(): boolean {
    if (!this.selectedItems || this.selectedItems.length === 0) {
      return false;
    }

    const selectedItemsIds = this.selectedItems.map(el => el.id).sort((a, b) => (a - b));
    const dropwdownListIds = this.dropdownList.map(el => el.id).sort((a, b) => (a - b));
    return areArraysEquals(selectedItemsIds, dropwdownListIds);
  }

  private cleanDatasourceSelectedOpts() {
    if (this.dataSource?.refreshSelectedOptsSource instanceof Function) {
      this.selectedIds = [];
      this.dataSource.refreshSelectedOptsSource([]);
    }
  }

  private selectAll() {
    this.selectedItems = this.dropdownList;
    this.value = this.selectedItems;
    this.question.value = this.selectedItems;
    this.form.get(this.formControlName).patchValue(this.selectedItems, {emitEvent: true});
    this.ctrlValid = this.form.controls[this.formControlName].status === 'VALID';
    this.onSelectAll.emit(this.selectedItems);
  }

  private clearAll() {
    this.selectedItems = [];
    this.value = this.selectedItems;
    this.question.value = this.selectedItems;
    this.form.controls[this.formControlName].patchValue(this.selectedItems, {emitEvent: true});
    this.onDeSelectAll.emit();
  }

  private customWriteValue(_value: any) {
    // Components expect question values to be always array
    const value =  _value?.hasOwnProperty('id') ? [_value] : _value;
    this.value = value;
    this.question.value = value;
    this.form.controls[this.formControlName].patchValue(value, {emitEvent: true});
    this.ctrlValid = this.form.controls[this.formControlName].status === 'VALID';
    // Components expect emitted value to be a single object in case of single selection
    // and object array in case of multiple selection
    if (value && this.settings.singleSelection) {
      this.onSelect.emit(value[0]);
      return;
    } else if (value && !this.settings.singleSelection) {
      this.onSelect.emit(value);
      return;
    }

    // Undefined or null values should also be emitted as well to handle filter cleansing in linked filters
    this.onSelect.emit(value);
  }

  private initDebouncedSearchInput(delayTime: number) {
    this.userSearch$.pipe(takeUntil(this.destroy$), debounceTime(delayTime)).subscribe(
      (searchTerm) => {
        this.page = 1;
        this.dropdownList = [];
        this.fetchOptions(searchTerm);
      }
    );
  }

  private handleDataSourceChanges() {
    this.dataSource.optsSource$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.page = 1;
        this.nextToken = 0;
        this.dropdownList = [];
        this.fetchOptions();
        if (this.selectedIds.length) {
          this.handleSelectedIdsFromCfg();
        }
      },
      error: (dataChangesError) => {
        console.error('HandleDataSourceError', dataChangesError);
      }
    });
  }

  private handleDataSourceSelectedOptsChanges() {
    this.dataSource.selectedOptsSource$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedIds) => this.handleSuccessSelectedOptsChanges(selectedIds),
      error: (dataChangesError) => console.error('handleDataSourceSelectedOptsError', dataChangesError)
    });
  }

  private handleSuccessSelectedOptsChanges(selectedIds) {

    if(selectedIds?.length > 0) {
      this.form.controls[this.formControlName].reset();
      this.ctrlValid = true;
      this.selectedIds = selectedIds;
      this.handleSelectedIdsFromCfg();
    }
  }

  private handleSelectedIdsFromCfg() {
    let cleansedIds = this.selectedIds.map(selectedItem => {
      let id;
      if (selectedItem.hasOwnProperty('id') && selectedItem.hasOwnProperty('name')) {
        id = selectedItem['id'];
      } else {
        id = selectedItem;
      }
      return id;
    });

    if (this.selectedItems.length) {
      // TODO: Avoid requesting items already in the list...
      cleansedIds = cleansedIds.filter(id => this.selectedItems.map(item => item.id).indexOf(id) < 0);
    } else {
      this.selectedIdLoadCount = 0;
    }

    // TODO: Fix fetchSelectedByBatch().  Payload getting "lost" with method at supplier def. (Plans)
    this.fetchSelectedByIds(cleansedIds);
  }

  private fetchSelectedByIds(cleansedIds) {
    this.loading = true;

    // ImportProductsService logic to handle and show failed products
    const failedIds = [];
    let idsCount = cleansedIds.length;
    const importServiceActive = this.importProductsService.isActive;

    if (importServiceActive && !idsCount) {
      this.importProductsService.setFailedProducts(failedIds);
    }

    const manageImportProducts = (id?: string, index?: number) => {
      if (importServiceActive) {
        if (id) {
          failedIds[index] = id;
        }
        idsCount--;
        if (!idsCount) {
          this.importProductsService.setFailedProducts(failedIds.filter(id => id));
        }
      }
    };

    cleansedIds.map((id: any, index: number) => {
      this.dataSource.fetchSelectedById(id).pipe(takeUntil(this.destroy$)).subscribe({
        next: (responseData: unknown) => {
          if (responseData instanceof Array && responseData.length) {
            const item = responseData.find( _item => _item.value === id );
            const newSelectedItem = this.dataSource.getNameWithTemplate(item, id);
            this.selectedItems = [...this.selectedItems, newSelectedItem];
          } else {
            const newSelectedItem = this.dataSource.getNameWithTemplate(responseData, id);
            if (this.settings.singleSelection) {
              this.selectedItems = [newSelectedItem];
            } else {
              this.selectedItems = [...this.selectedItems, newSelectedItem];
            }
          }
          manageImportProducts();
          this.handleSelectedIdsLoadCount();
          this.selectedItemLoadedEmitter.emit(responseData);
        },
        error: () => {
          console.error(`Multiselect Error. Cannot autoselect, GET ID: ${id}.`);
        }
      });
    });
  }

  private fetchSelectedByBatch(cleansedIds: string[], nextTokenStartsAt?: string) {
    this.loading = true;
    const params = {ids: cleansedIds};
    if (nextTokenStartsAt) {
      params['starting_after'] = nextTokenStartsAt;
    }
    this.dataSource.fetchSelectedByBatch(params).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res: HttpResponse<any>) => {
        res['list'].forEach(el => {
          const newSelectedItem = this.dataSource.getNameWithTemplate(el, el.id);
          this.selectedItems = [...this.selectedItems, newSelectedItem];
        });
        if (res.hasOwnProperty('next_token_starts_at') && res['next_token_starts_at']) {
          this.fetchSelectedByBatch(cleansedIds, res['next_token_starts_at']);
        } else {
          this.loading = false;
          this.selectedIdsLoaderEmitter.emit();
        }
      },
      error: error => console.error(`Multiselect Error. Cannot autoselect, params: ${params}.`, error)
    });
  }

  private handleSelectedIdsLoadCount() {
    this.selectedIdLoadCount++;
    if (this.selectedIdLoadCount >= this.selectedIds.length) {
      this.loading = false;
      this.form.controls[this.formControlName].patchValue(this.selectedItems);
      this.selectedIdsLoaderEmitter.emit();
    }
  }

  private fetchOptions(searchTerm?: string, page?: number) {
    const filters = this.filters !== undefined && this.filters ? this.filters : {};
    const opts = this.options !== undefined && this.options ? this.options : null;

    this.loading = true;
    this.searchTerm = searchTerm;

    this.dataSource.fetchMultiselect(searchTerm, page, filters, null, opts).pipe(takeUntil(this.destroy$)).subscribe({
      next: data => {
        this.loading = false;
        const keyListName = this.dataSource.keyListName ? this.dataSource.keyListName : 'list';
        this.nextToken = this.useToken ? data['next_token_starts_at'] : 0;
        if (data.hasOwnProperty(keyListName)) {
          this.toggleDisableIfOpts(false);
          this.isLastPage = this.evalIfIsLastPage(data, keyListName);
          data[keyListName].forEach(element => {
            const newEl = this.dataSource.getNameWithTemplate(element);
            this.dropdownList = [...this.dropdownList, newEl];
          });
        } else {
          if ( data instanceof Object && Object.keys(data).length === 0) {
            this.toggleDisableIfOpts(true);
          } else if ( data instanceof Array && data.length > 0) {
            this.isLastPage = this.evalIfIsLastPage(data, keyListName);
            data.forEach(element => {
              const newEl = this.dataSource.getNameWithTemplate(element);
              this.dropdownList = [...this.dropdownList, newEl];
            });
          } else {
            console.warn(`Multiselect: ${keyListName} not found in ${JSON.stringify(data)}`);
          }
        }
        this.optionsLoadedEmitter.emit(this.dropdownList);
      },
      error: error => {
        console.error(`Err: ${JSON.stringify(error)}`);
      }
    });
  }

  private toggleDisableIfOpts(disable?: boolean) {
    if (this.dataSource.optsSource$) {
      this.doDisable = disable;
    }
  }

  private evalIfIsLastPage(data: object, key: string): boolean {
    if (data.hasOwnProperty('_links') && data['_links']) {
      return !data['_links'].hasOwnProperty('next');
    } else {
      return true;
    }
  }

  private updateDropdownList() {
    this.multiselectService.updateDropdownList$.pipe(takeUntil(this.destroy$), filter(data => !!data))
      .subscribe((data) => {
        if (!this.question.dataSource && this.question.key === data) {
          this.dropdownList = [...this.question.options];
        }
      }
    );
  }
}
