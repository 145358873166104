<button
  [attr.data-cy]="'button_save_filter'"
  class="btn btn-icon"
  type="button"
  (click)="openSaveFilterModal()"
  [disabled]="filterForm.invalid">
    <em class="fa-duotone fa-save font-size-14 text-info"></em>
    <span [translate]="'components.data-table-filter.buttons.save_filter'"></span>
</button>
<app-question [question]="inputs[0]"
  [form]="saveFilterForm"
  [displayInline]="true"
  (multiselectChanged)="onSelectFilter($event)"
  [filters]="{path: routerUrl()}"></app-question>
<button
  [attr.data-cy]="'button_delete_filter'"
  class="btn btn-default btn-icon"
  type="button"
  (click)="deleteFilter()"
  [disabled]="!selectedFilter">
    <em class="fa-duotone fa-trash font-size-14 text-info"></em>
</button>

<!-- Begin: Modal to save with name input -->
<a #modalToggleBtn data-toggle="modal" data-target="#filter-save-modal" data-backdrop="false" hidden></a>
<form [formGroup]="filterModalForm">
  <div class="modal modal-backdrop fade in" id="filter-save-modal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header border bottom">
          <h4 class="mrg-btm-15">
            {{'components.data-table-filter.buttons.save_filter' | translate}}
          </h4>
          <button class="modal-close" [attr.data-cy]="'button_closeFilterModal'" data-dismiss="modal">
            <i class="ti-close"></i>
          </button>
        </div>
        <div class="modal-body background-gray">
          <div class="row" *ngIf="filterModalFormInputs">
            <div class="col-md-12 ml-auto mr-auto">
              <div class="card no-mrg-btm">
                <div class="card-block pdd-15">
                  <div class="form-group">
                    <div class="form-group">
                      <label for="name" [translate]="'components.data-table-filter.fields.name'"></label>
                      <span class="text-danger">*</span>
                      <app-question
                        class="display-flex text-dark"
                        [question]="filterModalFormInputs[0]"
                        [form]="filterModalForm">
                      </app-question>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="text-right">
            <button class="btn btn-info no-mrg-right"
              [attr.data-cy]="'button_saveFilter'"
              [translate]="'components.data-table-filter.buttons.overwrite_filter'"
              [disabled]="!selectedFilter"
              (click)="overwriteFilter()">
            </button>
            <button class="btn btn-info no-mrg-right"
              [attr.data-cy]="'button_overwriteFilter'"
              [translate]="'components.data-table-filter.buttons.save_filter'"
              (click)="saveFilter()">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- End modal -->