import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { ResourcesService } from '../resources.service';

@Injectable({
  providedIn: 'root'
})
export class TriggerActionsService extends ResourcesService {

  triggerTypeId: string = null;
  optsSource = new BehaviorSubject<string[] | string>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  getTriggerActions(): Observable<object> {
    const requestOptions = {
      apiEndPoint: '/trigger_actions',
      numberPerPage: 300,
      filtering: {},
      sorting: {
        byProp: '',
        direction: ''
      }
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'trigger_actions',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (this.triggerTypeId) {
      requestOptions.filtering['trigger_type_id'] = this.triggerTypeId;
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `trigger_actions/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    const name = this.parseSlugToName(element.slug);
    return new MultiselectDataSource(element.id, name, element);
  }

  setTriggerTypeId(triggerTypeId: string): void {
    this.triggerTypeId = triggerTypeId;
    this.optsSource.next(triggerTypeId);
  }

  private parseSlugToName(slug: string): string {
    let name = slug;
    name = name.replace(/-/g, ' ');
    name = name.replace(/_/g, ' ');
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  }
}
