import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionModalsService } from '../action-modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsService } from '../../../../../../../comments/comments.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../../../../../../../shared/utils/common.utils';
import { ModalStatusService } from '../../../../../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../../../shared/services/question-control.service';
import { Subject, Subscription, finalize, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subtract-points',
  templateUrl: './subtract-points.component.html',
  providers: [ ActionModalsService ]
})
export class SubtractPointsComponent implements OnInit, OnDestroy {

  hasComments: boolean;
  id: number;
  loading: boolean;
  subs$: Subscription[] = [];
  subtractInputs: QuestionBase<any>[];
  subtractScoreForm: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    private actionModalService: ActionModalsService,
    private confirmationService: ConfirmationService,
    private commentsService: CommentsService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.subtractInputs, inputKey);
  }

  sendData() {
    const score = this.subtractScoreForm?.value?.score || null;
    const comments: string = this.hasComments ? this.subtractScoreForm?.value?.multi_comments?.[0]?.name : this.subtractScoreForm?.value?.comments;

    const payload = {score, comments}

    this.loading = true;
    this.getConfirmationAlert(payload);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.getCommentsResults().then(() => {
        this.retrieveGrantPointsInputs();
        this.setValidators();
      });
    });
  }

  private getCommentsResults(): Promise<void> {
    this.loading = true;
    return new Promise<void>((resolve, reject) => {
      this.commentsService.getComments().subscribe({
        next: (response: Comment[]) => {
          this.loading = false;
          this.hasComments = !!response?.length;
          resolve();
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          this.confirmationService.displayHttpErrorAlert(error);
          reject(error);
        }
      });
    });
  }

  private retrieveGrantPointsInputs() {
    const excludedKeys = ['allow_partial', 'destination_customer_id', 'new_customer_id_code', 'country', 'location_id', 'loyalty_score_id'];
    const commentsKey = this.hasComments ? 'comments' : 'multi_comments';
    excludedKeys.push(commentsKey);

    this.subtractInputs = this.actionModalService.getInputs().filter(input => {
      return !excludedKeys.includes(input.key);
    });

    this.subtractScoreForm = this.qcs.toFormGroup(this.subtractInputs);
  }

  private setValidators() {

    const commentsControlName = this.hasComments ? 'multi_comments' : 'comments';
    const controlNames = ['score', commentsControlName];

    controlNames.forEach(controlName => {
      const control = this.subtractScoreForm.get(controlName);

      const subscription = control.valueChanges.subscribe(() => {
        this.updateValidators(control);
      });

      this.subs$.push(subscription);
    });
  }

  private getConfirmationAlert(payload: object) {

    const titleMessage = this.translate.instant('common.messages.are_you_sure');
    const confirmationMessage = this.translate.instant('resources.customers.show.action_modals.messages.confirm_subtract_points');

    this.confirmationService.displayConfirmationAlert( titleMessage, confirmationMessage ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.sendRequest(payload);
      } else {
        this.loading = false;
      }
    }).catch(() => {});
  }

  private sendRequest(payload) {
    this.actionModalService.subtractPoints(payload, this.id).pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)) .subscribe({
      next: () => {
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.customers.warnings.subtract_score_title'),
          this.translate.instant('resources.customers.warnings.subtract_score_text')
        ).catch(() => {});
      },
      error: (errorData: HttpErrorResponse) => {
        this.loading = false;
        this.confirmationService.displayHttpErrorAlert(errorData);
      }
    });
  }

  private updateValidators(formControl: AbstractControl) {

    if (isNullOrUndefined(formControl)) {
      return;
    }

    formControl.clearValidators();

    if (!formControl.value) {
      formControl.setValidators([Validators.required]);
    } else {
      formControl.setValidators(null);
    }

    if (!formControl['__updatingValidators']) {
      formControl['__updatingValidators'] = true;
      formControl.updateValueAndValidity();
      formControl['__updatingValidators'] = false;
    }

  }

}
