import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { CountriesService } from '../../../../shared/services/countries.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { Subscription } from 'rxjs';
import { LoyaltyScoresService } from '../loyalty-scores.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { getTranslationInputs } from '../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { LoyaltyScore } from '../../../../shared/models/loyalty-scores/loyalty-scores';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-loyalty-scores',
  templateUrl: './show-loyalty-scores.component.html',
  styleUrls: ['./show-loyalty-scores.component.css'],
  providers: [LoyaltyScoresService]
})
export class ShowLoyaltyScoresComponent implements OnInit, OnDestroy {

  loyaltyScoresData: LoyaltyScore;
  apiEndPoint: string;
  mode: string;
  id: number;
  isModal: boolean;
  roleSlug: string;
  subs$: Subscription[];
  loyaltyScoreId: number;
  selectedTab: string;
  loyaltyScoresForm: UntypedFormGroup;
  loyaltyScoresInputs: QuestionBase<any>[];

  constructor(
    private route: ActivatedRoute,
    private loyaltyScoresService: LoyaltyScoresService,
    private countriesService: CountriesService,
    private profileService: ProfileService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private changeDetector: ChangeDetectorRef,
    private qcs: QuestionControlService,
    private translate: TranslateService
  ) {
    this.loyaltyScoreId = this.route.snapshot.params.id
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.roleSlug = this.profileService.getStoredUserRole();
    this.isModal = this.router.url.indexOf('(modal:show/loyalty_scores/') >= 0;
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  goToMoment() {
    window.open(`#/loyalty/triggers(modal:show/loyalty_score_moments/${this.loyaltyScoresData.embeddedLoyaltyScoreMoment.id})`);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goBack() {
    this.router.navigate(['/scores/loyalty_scores/loyalty_scores']).catch(() => {});
  }

  refreshUrl(tabId) {
    this.selectedTab = tabId;
    this.changeDetector.detectChanges();
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        if (this.router.url.indexOf(`(modal:`) >= 0) {
          this.mode = 'show';
        }
        this.id = params.id;
        this.apiEndPoint = 'loyalty_scores';
        this.getLoyaltyScore(params.id);
        this.getSelectedTab();
      }
    });
    this.subs$.push(route$);
  }

  private getLoyaltyScore(loyaltyScoreId: number) {
    const loyaltyScore$ = this.loyaltyScoresService.getLoyaltyScoresById(loyaltyScoreId).subscribe(
      loyaltyScoreData => {
        loyaltyScore$.unsubscribe();
        this.loyaltyScoresData = new LoyaltyScore(loyaltyScoreData);
        this.loyaltyScoresData.countries = this.parseCountries(this.loyaltyScoresData.countries);
        this.loyaltyScoresInputs = this.loyaltyScoresService.getInputs({});
        this.loyaltyScoresForm = this.qcs.toFormGroup(this.loyaltyScoresInputs);
        getTranslationInputs(this.loyaltyScoresData, this.qcs, this.loyaltyScoresForm, [], this.mode);
      },
      errorData => {
        loyaltyScore$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(loyaltyScore$);
  }

  private handleErrorOnRequest(errorData) {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
    this.closeModal();
  }

  private parseCountries(countriesList: string[]) {
    this.countriesService.getCountries('default');
    return countriesList.map(item => {
      let country = this.countriesService.getCountriesById(item);
      if (!country) {
        country = {id: item, name: item};
      }
      return country;
    });
  }

  private getSelectedTab() {
    if ( this.router.url.indexOf(`modal:show/loyalty_scores/${this.loyaltyScoreId}/localized-content`) >= 0 ) {
      this.selectedTab = 'localized_content';
    } else {
      this.selectedTab = 'details';
    }
    this.changeDetector.detectChanges();
  }

}
