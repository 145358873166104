import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { QuestionBase } from '../../shared/models/forms/question-base';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TextboxQuestion } from '../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { Plan } from './plan';
import { CampaignType } from './plan-campaign-new/campaign-type';
import { MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Subject } from 'rxjs';
import { CheckboxQuestion } from '../../shared/models/forms/question-checkbox';
import { FileQuestion } from '../../shared/models/forms/question-file';

@Injectable()
export class PlansService extends ResourcesService {

  applyFilters$: Subject<any> = new Subject<any>();
  currentPlan: Plan;
  date_from: string;
  date_to: string;
  datesAreRequired = false;
  filters: any;
  inputs: QuestionBase<any>[];
  selectedType: CampaignType;
  minDate: string;
  maxDate: string;
  statusSlugToFilter: {};
  optsSource = new BehaviorSubject<{dateFrom?: string, dateTo?: string}>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);
  }

  getAll() {
    const requestOptions = {apiEndPoint: '/plans', numberPerPage: 10};
    return this.getData(requestOptions);
  }

  getPlanById(id: string): Observable<any> {
    return this.getData({apiEndPoint: `plans/${id}`});
  }

  createPlan(payload: Object) {
    return this.postResource(payload, 'plans');
  }

  updatePlan(id: string, payload: Object) {
    return this.patchResource(payload, `plans/${id}`);
  }

  deletePlan(id: string) {
    return this.deleteResource({}, `plans/${id}`);
  }

  executePlan(id: string): Observable<any> {
    return this.postResource({}, `plans/${id}/execute`);
  }

  clonePlan(id: string): Observable<any> {
    return this.postResource({}, `plans/${id}/clone`);
  }

  removeSchedule(id: string): Observable<any> {
    return this.postResource({}, `plans/${id}/remove_schedule`);
  }

  getActions(id: string, filters: Object): Observable<any> {
    return this.getData({apiEndPoint: `plans/${id}/actions`, filtering: filters});
  }

  calculateForecast(id: string): Observable<any> {
    return this.postResource({}, `plans/${id}/calculate_forecast`);
  }

  calculateROI(id: string): Observable<any> {
    return this.postResource({}, `plans/${id}/calculate_roi`);
  }

  calculateAnalysis(id: string): Observable<any> {
    return this.postResource({}, `plans/${id}/calculate_pre_post`);
  }

  switchCouponsByPlan(planId: string, payload: object): Observable<any> {
    return this.postResource(payload, `plans/${planId}/switch_coupons`);
  }

  getCalculationDataByCategory(filters: object): Observable<any> {
    return this.postResource(filters, `plans/calculation_data_by_category`);
  }

  exportGrouped(filters): Observable<any> {
    return this.postResource(filters, `plans/export_grouped_calculation`);
  }

  analyzeCSV(payload: object): Observable<any> {
    return this.postResource(payload, `campaign_plans/recommended_campaign/analyze`);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'plans',
      pageNumber: page || 1,
      filtering: {},
      sorting: {}
    };

    if (searchValues) {
      requestOptions.filtering = { name: searchValues };
    }

    if (filters || this.statusSlugToFilter) {
      const mergedFilters = { ...filters, ...this.statusSlugToFilter };
      requestOptions.filtering = { ...requestOptions.filtering, ...mergedFilters };
    }

    if (this.datesAreRequired) {
      const {date_from, date_to} = this;
      if (date_from && date_to) {
        requestOptions.filtering = { ...requestOptions.filtering, ...{status: 'delivered', date_from: date_from, date_to: date_to}};
      } else {
        return of({});
      }
    }

    return this.getData(requestOptions);
  }

  fetchSelectedById(id: string): Observable<object> {
    return this.getPlanById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  getInputs(plan?: Plan): QuestionBase<any>[] {
    return [
      new TextboxQuestion({
        label: this.translate.instant('resources.plans.fields.name'),
        cssClasses: 'form-control input-md',
        key: 'name',
        type: 'text',
        required: true,
        value: plan ? plan.name : null
      }),
      new TextboxQuestion({
        label: this.translate.instant('resources.plans.fields.valid_from'),
        cssClasses: 'form-control input-md',
        key: 'available_from',
        type: 'date',
        required: true,
        value: plan ? plan.available_from : null
      }),
      new TextboxQuestion({
        label: this.translate.instant('resources.plans.fields.valid_until'),
        cssClasses: 'form-control input-md',
        key: 'available_to',
        type: 'date',
        required: true,
        value: plan ? plan.available_to : null
      }),
      new TextboxQuestion({
        label: this.translate.instant('resources.plans.fields.print_from'),
        cssClasses: 'form-control input-md',
        key: 'print_from',
        type: 'date',
        required: true,
        value: plan ? plan.print_from : null
      }),
      new TextboxQuestion({
        label: this.translate.instant('resources.plans.fields.print_to'),
        cssClasses: 'form-control input-md',
        key: 'print_to',
        type: 'date',
        required: true,
        minDate: plan ? plan.print_to : null,
        maxDate: plan ? plan.print_to : null,
        value: plan ? plan.print_to : null
      }),
      new CheckboxQuestion({
        key: 'print_disabled',
        label: this.translate.instant('resources.plans.fields.not_printable'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: !(plan?.print_to && plan.print_from)
      }),
      new CheckboxQuestion({
        key: 'created_by_csv',
        label: this.translate.instant('resources.plans.fields.csv'),
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: plan?.created_by_csv ?? false
      }),
      new FileQuestion({
        key: 'csv',
        types: ['csv'],
        label: this.translate.instant('components.table_actions.import_csv'),
        multiple: false,
        cssClasses: 'form-control input-default',
        required: true
      }),
    ];
  }

  getStaticTypes(): CampaignType[] {
    return [
      {
        name: this.translate.instant('resources.campaign_plans.types.custom.title'),
        simple_name: this.translate.instant('resources.campaign_plans.types.custom.title_simplified'),
        type: 'Plans::CustomCampaign',
        desc: this.translate.instant('resources.campaign_plans.types.custom.desc'),
        loading: false,
        enabled: true
      },
      {
        name: this.translate.instant('resources.campaign_plans.types.supplier.title'),
        simple_name: this.translate.instant('resources.campaign_plans.types.supplier.title_simplified'),
        type: 'Plans::SupplierCampaign',
        desc: this.translate.instant('resources.campaign_plans.types.supplier.desc'),
        loading: false,
        enabled: true
      },
      {
        name: this.translate.instant('resources.campaign_plans.types.up.title'),
        simple_name: this.translate.instant('resources.campaign_plans.types.up.title_simplified'),
        type: 'Plans::UpCrossCampaign',
        desc: this.translate.instant('resources.campaign_plans.types.up.desc'),
        loading: false,
        enabled: true
      },
      {
        name: this.translate.instant('resources.campaign_plans.types.recovery.title'),
        simple_name: this.translate.instant('resources.campaign_plans.types.recovery.title_simplified'),
        type: 'Plans::RecoveryCampaign',
        desc: this.translate.instant('resources.campaign_plans.types.recovery.desc'),
        loading: false,
        enabled: true
      }
    ];
  }

  setStatusSlugToFilter(statusSlug: string) {
    this.statusSlugToFilter = {status: statusSlug};
  }

  setDatesRequired() {
    this.datesAreRequired = true;
  }

  setDates(dateFrom: string, dateTo: string) {
    this.date_from = dateFrom;
    this.date_to = dateTo;
    this.optsSource.next({dateFrom, dateTo});
  }
}
