<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.points_value'"></h4>
  </div>
  <div class="card-block">

    <div class="alert alert-info" role="alert">
      <p class="mrg-btm-5">{{'resources.scores.loyalty_scores.descriptions.points_value' | translate}}</p>
      <p class="mrg-btm-5"><strong>{{'resources.scores.loyalty_scores.form.award.for_points' | translate}}:</strong></p>
      <ul>
        <li><em><b>{{'resources.scores.loyalty_scores.form.award.amount_spent' | translate}}</b></em> {{'resources.scores.loyalty_scores.form.award.amount_spent_text' | translate}}</li>
        <li><em><b>{{'resources.scores.loyalty_scores.form.award.fixed_amount' | translate}}</b></em> {{'resources.scores.loyalty_scores.form.award.fixed_amount_text' | translate}}</li>
        <li><em><b>{{'resources.scores.loyalty_scores.form.award.chunk' | translate}}</b></em> {{'resources.scores.loyalty_scores.form.award.chunk_text' | translate}}</li>
        <li><em><b>{{'resources.scores.loyalty_scores.form.award.percentage_amount' | translate}}</b></em> {{'resources.scores.loyalty_scores.form.award.percentage_amount_text' | translate}}</li>
      </ul>

      <p class="mrg-top-5 mrg-btm-5"><strong>{{'resources.scores.loyalty_scores.form.award.for_rewards' | translate}}:</strong></p>
      <p class="mrg-btm-0">{{'resources.scores.loyalty_scores.form.award.for_rewards_text' | translate}}</p>
    </div>

    <div class="row mrg-top-20">
      <div class="col-md-12 no-pdd-btm">
        <div class="form-group">
          <label class="text-bold" for="loyalty_score_moment">
            {{'resources.scores.loyalty_scores.fields.when' | translate}}
            <span class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('loyalty_score_moment')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed"
                        (multiselectChanged)="momentChanged($event)">
          </app-question>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-bold" for="award_type">
            {{'resources.scores.loyalty_scores.fields.award_type' | translate}}
            <span class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('award_type')"
                        [form]="loyaltyScoresForm"
                        (multiselectChanged)="onAwardTypeChange($event)">
          </app-question>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="awardTypeSelected !== 'reward'">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-bold" for="strategy_type">
            {{'resources.scores.loyalty_scores.fields.accumulation_type' | translate}}
            <span class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('strategy_type')"
                        [form]="loyaltyScoresForm"
                        (multiselectChanged)="strategySelectorChange($event)">
          </app-question>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="awardTypeSelected === 'reward'">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-bold" for="reward_id">
            {{'resources.scores.loyalty_scores.fields.reward' | translate}}
            <span class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('reward_id')"
                        [form]="loyaltyScoresForm">
          </app-question>
        </div>
      </div>
    </div>

    <div class="row flex align-items-center">
      <ng-container *ngIf="!awardTypeSelected || awardTypeSelected !== 'reward'">
        <div class="col-md-4" *ngIf="strategyFlags.showPoints">
          <div class="form-group">
            <label *ngIf="strategyFlags.showLabelPoints" for="points" class="text-bold">
              {{'resources.scores.loyalty_scores.fields.amount_to_spend' | translate}}
              <span class="text-danger">*</span>
            </label>
            <label *ngIf="strategyFlags.showLabelPriceMultiplicator" for="points_price_multiplicator" class="text-bold">
              {{'resources.scores.loyalty_scores.fields.points_amount' | translate}}
              <span class="text-danger">*</span>
            </label>
            <label *ngIf="hasFormKeyWithValue('strategy_type', 'percentage')" for="points_percentage" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.points_percentage'"></label>
            <app-question [question]="getInputConfig('points')" [form]="loyaltyScoresForm" [disabled]="strategyFlags.disablePoints"></app-question>
            <app-question *ngIf="hasFormKeyWithValue('strategy_type', 'percentage')" [question]="getInputConfig('points_percentage')" [form]="loyaltyScoresForm" [disabled]="isUsed"></app-question>
          </div>
        </div>
        <div class="col-md-4" *ngIf="hasFormKeyWithValue('strategy_type', 'percentage')">
          <div class="form-group">
            <label for="points_percentage" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.points_percentage'"></label>
            <app-question [question]="getInputConfig('points_percentage')" [form]="loyaltyScoresForm" [disabled]="isUsed"></app-question>
          </div>
        </div>
        <div class="col-lg-1" *ngIf="strategyFlags.symbol">
          <span class="grant-score-type flex justify-content-center align-items-center font-size-16">
            {{ strategyFlags.symbol }}
          </span>
        </div>

        <div class="col-md-4" *ngIf="strategyFlags.showPointsPriceMultiplicator">
          <div class="form-group">
            <label class="text-bold" *ngIf="!strategyFlags.showLabelPointsMultiplicator" for="points_price_multiplicator"
                    [translate]="'resources.scores.loyalty_scores.fields.points_amount'">
            </label>
            <label *ngIf="strategyFlags.showLabelPointsMultiplicator" for="points_price_multiplicator"
                    [translate]="'resources.scores.loyalty_scores.fields.points_multiplicator'">
            </label>
            <app-question [question]="getInputConfig('points_price_multiplicator')" [form]="loyaltyScoresForm"></app-question>
          </div>
        </div>
        <div class="col-md-3 paddtop2em" *ngIf="strategyFlags.showTransferablePoints">
          <div class="form-group">
            <app-question [question]="getInputConfig('transferable')" [form]="loyaltyScoresForm"></app-question>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
