<!-- <div class="modal-backdrop fade in" [id]="title" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div *ngIf="element">          
          <h2>{{'resources.scores.scores.fields.score' | translate }}: {{element.id}}</h2>
          <br>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">       
        <div class="row">
          <div class="ml-auto col-md-12" *ngIf="element">

            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.available_points'"></b><br></span>
                <span>{{element.available_points}}</span><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.original_points'"></b><br></span>
                <span>{{element.original_points}}</span><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.promotion_id'"></b><br></span>
                <span>{{element.promotion_id}}</span><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.profile_id'"></b><br></span>
              <a [routerLink]="[]">
                <span>{{element.profile_id}}</span></a><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.status'"></b><br></span>
                <span>{{'resources.scores.scores.fields.' + element.status | translate }}</span><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.session'"></b><br></span>
                <span>{{element.session | date: 'dd/MM/yyyy'}}</span><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.scoreable_id'"></b><br></span>
                <span>{{element.scoreable_id}}</span><br>
                <br>
            </div>              
            <div>
              <span><b class="text-dark font-size-18" [translate]="'resources.scores.scores.fields.scoreable_type'"></b><br></span>
                <span>{{element.scoreable_type}}</span><br>
                <br>
            </div>                         
            

            <div class="mrg-vertical-20 pull-right">
              
              <button class="btn btn-default btn-icon btn-default" (click)="closeModal()">
                <span [translate]="'components.show.buttons.accept'"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 -->
