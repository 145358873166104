import { Injectable } from '@angular/core';
import { DataTableConfiguration } from '../components/data-table/data-table-cfg';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CsvExportService {

  constructor(
    private translate: TranslateService
  ) { }

  public getCsvBlob(csvString: string): Blob {
    return new Blob(['\ufeff', csvString], { type: 'text/csv' });
  }

  public prepareAriAsStringForCsv(tableCfg: DataTableConfiguration, objArray: Object[]): string {
    let stringCsv = '';
    const headers = Object.keys(objArray[0]);

    headers.forEach(headerKey => {
      let translatedKey;
      if (tableCfg.requestData.translationResource) {
        translatedKey = this.translate.instant(tableCfg.requestData.translationResource + headerKey);
      } else {
        translatedKey = this.translate.instant('resources.' + tableCfg.requestData.apiEndPoint + '.columns.' + headerKey);
      }
      stringCsv += translatedKey + ';';
    });
    stringCsv += '\n';

    objArray.forEach(csvRow => {
      const item = this.mapExportItem(tableCfg, csvRow);
      headers.forEach(headerKey => {
        stringCsv += '"' + item[headerKey] + '";';
      });
      stringCsv += '\n';
    });

    return stringCsv;
  }

  private mapExportItem(tableCfg: DataTableConfiguration, item): Object {
    const mappedItem = {};
    tableCfg.tableMapping.map(itemMapping => {
        mappedItem[itemMapping['prop']] = this.getExportValue(item, itemMapping);
      }
    );
    return mappedItem;
  }

  private getExportValue(item, mapping) {
    const property = mapping['prop'];
    let parsedValue = item[property];

    if (mapping['type'] === 'image') {parsedValue = item[property]?.src ? item[property].src : ''};
    if (mapping.hasOwnProperty('link')) {parsedValue = item[property].parsedValue};
    if (mapping.hasOwnProperty('icon')) {parsedValue = item[property].parsedValue};
    if (mapping.hasOwnProperty('badge')) {parsedValue = item[property].badge};

    if (parsedValue?.hasOwnProperty('innerHTML')) {
      let htmlContent = parsedValue['innerHTML'];
      htmlContent = htmlContent.replace(/<strong>/g, '')
      htmlContent = htmlContent.replace(/<\/strong>/g, '')
      htmlContent = htmlContent.replace(/<br>/g, ', ')
      parsedValue = htmlContent;
    }

    if (typeof parsedValue === 'object' && parsedValue !== null && parsedValue.hasOwnProperty('parsedValue')) {
      parsedValue = parsedValue.parsedValue;
    }

    if (typeof parsedValue === 'object' && parsedValue !== null && parsedValue.hasOwnProperty('icons')) {
      parsedValue = parsedValue.icons.map(el => el.alt).join(', ');
    }

    return parsedValue ?? '';
  }
}
