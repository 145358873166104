import { QuestionBase } from "../models/forms/question-base";
import { DateService } from "../services/date.service";
import { isNullOrUndefined } from "./common.utils";
import { FormGroup } from "@angular/forms";

export function handlePeriodFilter(filters: QuestionBase<any>[], dateService: DateService, form?: FormGroup): void {
  const periodFilter = filters.find(item => item.key === 'period');
  const dateFromFilter = filters.find(item => item.key === 'date_from');
  const dateToFilter = filters.find(item => item.key === 'date_to');
  
  if (periodFilter && dateFromFilter && dateToFilter) {
    const periodValue = isNullOrUndefined(form) ? periodFilter.value : form.get('period').value;
    const hasPeriodFilterValue = !!periodValue?.length;
    const periodId = hasPeriodFilterValue ? periodValue[0].id : null;
    const isCustomPeriodOption = !!(hasPeriodFilterValue && periodId === 'custom');
    dateFromFilter.hidden = !isCustomPeriodOption;
    dateToFilter.hidden = !isCustomPeriodOption;
    if (hasPeriodFilterValue) {
      if (periodId === 'current_month') {
        const startPreviousMonth = dateService.momentStartOfTypeAndFormat('month', 'YYYY-MM-DD');
        const endPreviousMonth = dateService.momentEndOfTypeAndFormat('month', 'YYYY-MM-DD');
        setPeriodDates(dateFromFilter, dateToFilter, form, startPreviousMonth, endPreviousMonth);
      } else if (periodId === 'last_month') {
        const startCurrentMonth = dateService.momentStartOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
        const endCurrentMonth = dateService.momentEndOfPreviousPeriodTypeAndFormat(1, 'months', 'month', 'YYYY-MM-DD');
        setPeriodDates(dateFromFilter, dateToFilter, form, startCurrentMonth, endCurrentMonth);
      }
    }
  }
}

function setPeriodDates(dateFromFilter: QuestionBase<any>, dateToFilter: QuestionBase<any>, form: FormGroup, startMonth: string, endMonth: string): void {
  if (isNullOrUndefined(form)) {
    dateFromFilter.value = startMonth;
    dateToFilter.value = endMonth;
  } else {
    form.get('date_from').patchValue(startMonth);
    form.get('date_to').patchValue(endMonth);
  }
}