<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="mrg-left-15">{{ 'resources.reports.form.fields.new_report' | translate }}</h1>
          </div>
        </div>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>
      <div class="modal-body height-100 background-gray">
        <div class="container-fluid clearfix">
          <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
              <div class="card">
                <app-loader noWrapper *ngIf="loading" />
                <div class="card-heading border bottom">
                  <h4 class="card-title">{{ 'resources.reports.form.fields.details' | translate }}</h4>
                </div>
                <div class="card-block">
                  <div class="mrg-top-20">
                    <form class="form-horizontal">
                      <div class="row mrg-top-20" *ngIf="inputs">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label for="name" class="col-md-3 mrg-top-10 text-semibold control-label"
                              [translate]="'resources.reports.form.fields.name'"></label>
                            <div class="col-md-8">
                              <app-question [question]="getInputConfig('name')" [form]="reportForm"></app-question>
                            </div>
                            <ng-container *ngIf="updateMode">
                              <label for="detail" class="col-md-3 mrg-top-10 text-semibold control-label"
                                [translate]="'resources.reports.form.fields.detail'"></label>
                              <div class="col-md-8">
                                <app-question [question]="getInputConfig('detail')" [form]="reportForm"
                                  (multiselectChanged)="getDetailSelection($event)"></app-question>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="reportData">
                <div class="pdd-horizon-15 pdd-vertical-15">
                  <app-loader noWrapper *ngIf="loading" />
                  <!-- DATA TABLE -->
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card-block no-pdd">
                        <div class="col-xs-3 pull-right mrg-vertical-5">
                          <button class="btn btn-default btn-sm" type="button" data-toggle="dropdown">
                            <span class="font-size-10"
                              [translate]="'components.data-table.fields.batch_actions'"></span>
                            <i class="ti-angle-down font-size-9"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-right">
                            <li>
                              <a class="display-flex pointer dropdown-item font-size-10" (click)="refreshCache()">
                                <i class="font-size-12 mrg-right-5" class="ei-refresh"></i>&nbsp;
                                {{ 'resources.reports.buttons.refresh_cache' | translate }}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="table-responsive" >
                          <table *ngIf="reportData.report_details.length === 0" id="dt-opt" class="table table-lg table-hover dataTable no-footer mrg-top-20" role="grid" aria-describedby="dt-opt_info" aria-hidden="true">    
                            <tbody>
                              <tr class="odd">
                                <td style="vertical-align: top;" colspan="7" class="dataTables_empty text-semibold text-center" [translate]="'components.data-table.messages.no_elements_found'"></td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered table-sm table-overflow font-size-13" aria-hidden="true" *ngIf="reportData.report_details.length > 0">
                            <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th>
                                  <p class="width-long">{{'resources.reports.columns.type' | translate}}</p>
                                </th>
                                <th>
                                  <p>
                                    {{'resources.reports.columns.name' | translate}}
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let detail of reportData.report_details" drag-drop="reportData"
                                [ngClass]="{ 'dotted-over': detail.id === idOver}">
                                <td>
                                  <div class="btn-group move">
                                    <a class="btn btn-calm" draggable="true" (dragstart)="dragStart(detail.id)"
                                      (dragover)="dragOver(detail.id)"
                                      (dragleave)="dragLeave(detail.id)" (dragend)="dragEnd()"
                                      [id]="detail.id">
                                      <i class="ti-arrows-vertical info vertical-align move mr-0 font-size-16"></i>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div class="btn-group">
                                    <a *ngIf="detail.type === 'ReportSegment'" class="btn btn-default"
                                      data-toggle="tooltip" data-placement="bottom"
                                      [routerLink]="['', { outlets: { modal: ['reports', id, 'report_segment', detail.id, 'edit'] }}]">
                                      <i class="fa fa-pencil mr-0 font-size-16 text-dark"></i>
                                    </a>
                                    <a *ngIf="detail.type === 'ReportIncremental'" class="btn btn-default"
                                      data-toggle="tooltip" data-placement="bottom"
                                      [routerLink]="['', { outlets: { modal: ['reports', id, 'report_incremental', detail.id, 'edit'] }}]">
                                      <i class="fa fa-pencil mr-0 font-size-16 text-dark"></i>
                                    </a>
                                    <a *ngIf="detail.type === 'ReportRoiByStore'" class="btn btn-default"
                                      data-toggle="tooltip" data-placement="bottom"
                                      [routerLink]="['', { outlets: { modal: ['reports', id, 'report_roi_by_store', detail.id, 'edit'] }}]">
                                      <i class="fa fa-pencil mr-0 font-size-16 text-dark"></i>
                                    </a>
                                    <a *ngIf="detail.type === 'ReportCouponsAppAnalysis'" class="btn btn-default"
                                      data-toggle="tooltip" data-placement="bottom"
                                      [routerLink]="['', { outlets: { modal: ['reports', id, 'report_coupons_in_app_analysis', detail.id, 'edit'] }}]">
                                      <i class="fa fa-pencil mr-0 font-size-16 text-dark"></i>
                                    </a>
                                    <a class="btn btn-default"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      (click)="deleteReport(detail)">
                                      <i class="fa fa-trash mr-0 font-size-16 text-dark"></i>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div class="mrg-top-15">
                                    <div *ngIf="detail.type === 'ReportSegment'">
                                      <i class="fa fa-line-chart"></i>
                                      <span class="text-dark">
                                        {{'resources.reports.form.fields.segment_evo' | translate}}
                                      </span>
                                    </div>
                                    <div *ngIf="detail.type === 'ReportIncremental'">
                                      <i class="fa fa-line-chart"></i>
                                      <span class="text-dark">
                                        {{'resources.reports.form.fields.incremental' | translate}}
                                      </span>
                                    </div>
                                    <div *ngIf="detail.type === 'ReportRoiByStore'">
                                      <i class="fa fa-line-chart"></i>
                                      <span class="text-dark">
                                        {{'resources.reports.form.fields.roi_by_store' | translate}}
                                      </span>
                                    </div>
                                    <div *ngIf="detail.type === 'ReportCouponsAppAnalysis'">
                                      <i class="fa fa-line-chart"></i>
                                      <span class="text-dark">
                                        {{'resources.reports.form.fields.coupons_in_app_analysis' | translate}}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="mrg-top-15">
                                    <span class="text-dark">
                                      {{detail.name}}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <button class="btn btn-info" (click)="updateMode ? sendData() : createNewReport()" [translate]="'resources.reports.buttons.send'"></button>
          <button class="btn btn-info" (click)="backTo()" [translate]="'resources.reports.buttons.back_to_index'"></button>
          <ng-container *ngIf="updateMode">
            <button class="btn btn-info" (click)="showReport()" [translate]="'resources.reports.buttons.show_report'"></button>
            <button class=" btn btn-info mrg-right-12" (click)="generateReport()" [translate]="'resources.reports.buttons.generate_report'"></button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
