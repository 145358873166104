import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { FeatureTaxonomiesService } from './feature-taxonomies.service';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { FileQuestion } from '../../../shared/models/forms/question-file';
import { Feature } from '../../../shared/models/products/feature';

@Injectable()
export class FeaturesService extends ResourcesService implements MultiselectDataSourceable {

  inputs: QuestionBase<unknown>[];
  mainFeatures = false;
  treatPkAsId =  false;
  taxonomySlug: string;
  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();
  selectedOptsSource = new BehaviorSubject<string[]>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient,
    private productsFeatureTaxonomiesService: FeatureTaxonomiesService) {
    super(http);
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/features',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  getFeatureById(id: number) {
    return this.getData({ apiEndPoint: `features/${id}` });
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object, useToken = false, opts?: object ) {

    if(opts && opts.hasOwnProperty('preventInitialRequest') && opts['preventInitialRequest']){
      return of({});
    }

    let requestOptions = {
      apiEndPoint: 'features',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    requestOptions.filtering = { search_text: searchValues, filter_logic: 'and' };

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if ( this.mainFeatures ) { requestOptions.filtering['taxonomy_slug'] = 'main'; }
    if ( this.taxonomySlug ) { requestOptions.filtering['taxonomy_slug'] = this.taxonomySlug; }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: string): Observable<object> {
    id = id.toString();
    const urlParams = id.indexOf('|') >= 0 ? `features?pk=${id}` : `features?id=${id}`;
    return this.getData({ apiEndPoint: urlParams });
  }

  setTaxonomies(taxonomySlug: string) {
    this.taxonomySlug = taxonomySlug;
    this.optsSource.next( taxonomySlug );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    const id = this.treatPkAsId ? element.pk : element.id;
    return new MultiselectDataSource(id, `(${element.taxonomy.name}, ${element.external_id}) ${element.name}`, element);
  }

  getInputs(formValues: Feature, isEditMode: boolean): QuestionBase<unknown>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'external_id',
        type: 'number',
        step: 1,
        cssClasses: 'form-control input-default',
        disabled: isEditMode,
        required: true,
        value: formValues?.external_id ?? null
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.name ?? null
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
        disabled: isEditMode,
        required: true,
        value: formValues?.slug ?? null
      }),
      new MultiSelectQuestion({
        key: 'taxonomy_slug',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true},
        dataSource: this.productsFeatureTaxonomiesService,
        disabled: isEditMode,
        required: true,
        selectedIds: formValues?.taxonomy_slug ? [formValues.taxonomy_slug] : []
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  getImportInputs(): QuestionBase<unknown>[] {
    return [
      new FileQuestion({
        key: 'csv',
        types: ['csv'],
        multiple: false,
        cssClasses: 'form-control input-default',
        getValue: (value) => value,
        required: true
      })
    ];
  }

  create(data) {
    return this.postResource(data, 'features');
  }

  updateById(data, id) {
    return this.patchResource(data, `features/${id}`);
  }

  categorizeProductsByCsv(csv_text: string, id:number) {
    return this.postResource({csv_products_id: csv_text}, `features/${id}/csv_products`);
  }

  importCsv(filePath: string) {
    return this.postResource({file_path: filePath}, 'features/import_csv');
  }

  refreshSelectedOptsSource(selectedIds: string[]) {
    this.selectedOptsSource.next(selectedIds);
  }
}
